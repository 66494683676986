import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import {Customer } from "../../types/Customer";
import {Service} from "../../types/Service";
import {useTranslation} from "react-i18next";
import {DataGrid, GridColDef, GridPaginationModel, GridSortModel} from "@mui/x-data-grid";
import {StatusIcon} from "../Shared/StatusIcon";
import Box from "@mui/material/Box";
import {ServiceType} from "../../types/enums/ServiceType";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import { boxStyle } from '../Shared/Tables/listStyles';

interface CustomersTableProps {
    customers: Customer[];
    loading: boolean;
    totalElements?: number,
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const CustomersTable = (({customers, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange}: CustomersTableProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setRows(customers.map(generateRows));
    },[customers]);

    const columns: GridColDef[] = [
        {
            field: 'status', editable: false, headerName: '', width: 65, sortable: false, disableColumnMenu: true, renderCell: (params) => {
                return <StatusIcon {...params.row} />;
            }
        },
        {field: 'id', headerName: "id", minWidth: 50, maxWidth:75, flex: 1, disableColumnMenu: true},
        {field: 'name', headerName: t("Kund"), minWidth: 50, flex: 1, disableColumnMenu: true},
        {field: ServiceType.MEASURING, headerName: t("Mätning"), sortable: false, minWidth: 50, flex: 1, disableColumnMenu: true},
        {field: ServiceType.DEMO_AUTO_INVOICING, headerName: t("Demo Auto Fakt"), sortable: false, minWidth: 50, flex: 1, disableColumnMenu: true},
        {field: ServiceType.COMPANY_AUTO_INVOICING, headerName: t("Företag Auto Fakt"), sortable: false, minWidth: 50, flex: 1, disableColumnMenu: true},
        {field: ServiceType.SOLE_TRADER_AUTO_INVOICING, headerName: t("Enskild Auto Fakt"), sortable: false, minWidth: 50, flex: 1, disableColumnMenu: true},
    ];
    const generateRows = (customer: Customer): any[] => {
        const rows : any[] = [{id: customer.id, name: customer.name, status: customer.active}];

        if(customer.services !== undefined){
            customer.services && customer.services.map((service: Service) => (
                rows.push({[service.type]: service.active ? service.startDate : "-"}))
            )
        }

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const [rows, setRows] = useState(() => customers.map(generateRows));

    const handleRowClick = (params : any) => {
        navigate(`/customers/${params.row.id}`);
    };


    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    paginationMode='server'
                    rowCount={totalElements ?? 0}
                    paginationModel={paginationModel}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['asc', 'desc']}
                    onPaginationModelChange={onPaginationModelChange}
                    pageSizeOptions={[10, 25, 50, 100]}
                    hideFooterSelectedRowCount={true}
                    onRowClick={handleRowClick}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
        </Box>
    );
});
export default CustomersTable;
