import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Chip, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import minMax from 'dayjs/plugin/minMax';
import Autocomplete from "@mui/material/Autocomplete";
import { OutlinedTextFieldProps, Typography } from "@mui/material";
import { isVehicleBillable, preRegistrationExists } from "../../../api/PreRegistrationAPI";
import { useAsync } from "react-use";
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import { ExistsInfo } from "../../../types/ExistsInfo";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";
import { ExemptStatsComponent } from "../../Shared/ExemptStatsComponent";
import VisitHistoryModal from "./VisitHistoryModal";
import { roundToMinutes } from "../../../helpers/DateFormatting";

const AddPreRegistrationModal = ({open, handleClose, handleConfirmChanges}) => {
    dayjs.extend(minMax);
    const {currentCustomer} = useCurrentCustomer();
    const {t, i18n} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden);
    const [showVisits, setShowVisits] = useState(false);

    const [regNumberHelpText, setRegNumberHelpText] = useState("");
    const [facilityValue, setFacilityValue] = useState<{id:number, name:string} | null>(currentCustomer.facilities.length > 1 ? null : currentCustomer.facilities[0]);
    const [facilityInputValue, setFacilityInputValue] = useState("");

    const getMinFromTime = () => roundToMinutes(dayjs(), 15).add(15, 'minutes');
    const [fromDate, setFromDate] = useState(getMinFromTime());
    const getMaxUntilTime = () => fromDate.endOf('day');
    const startUntilTime = dayjs.min(getMinFromTime().add(1, 'hour'), getMaxUntilTime());
    const [toDate, setToDate] = useState(startUntilTime);


    const [infoData, setInfoData] = useState({
        [t("Startdatum")]: getMinFromTime().format('YYYY-MM-DD HH:mm'),
        [t("Slutdatum")]: startUntilTime.format('YYYY-MM-DD HH:mm')
    });
    const [billable, setBillable] = useState(false);

    const [formData, setFormData] = useState({
        regnumberClear: '',
        comment: '',
        facility: currentCustomer.facilities.length > 1 ? '' : `/facilities/${currentCustomer.facilities[0].id}`,
        validFrom: getMinFromTime().format('YYYY-MM-DD HH:mm:ss'),
        validUntil: startUntilTime.format('YYYY-MM-DD HH:mm:ss')
    });


    useEffect(() => {  
        if(open) {
            clearFormData();
        }
    }, [open]);

    const [errors, setErrors] = useState({
        regnumberClear: {value: false, message: ''},
        facility: {value: false, message: ''},
        validFrom: {value: false, message: ''},
        validUntil: {value: false, message: ''}
    });

    const validRegnumber = (value:string) => {
        return /^[A-Z]{3}[0-9]{2}[A-Z0-9]{1}$/.test(value.replaceAll(" ", "").trim().toUpperCase());
    }

    const validateForm = () => {
        const regNumberValid = validRegnumber(formData.regnumberClear);
        const validFromValid = formData.validFrom && dayjs(formData.validFrom, "YYYY-MM-DD HH:mm:ss", true).isValid() && !dayjs(formData.validFrom).isBefore(getMinFromTime());
        const validUntilValid = (formData.validUntil !== 'invalid' || (formData.validUntil && dayjs(formData.validUntil, 'YYYY-MM-DD HH:mm:ss', true).isValid())) && !dayjs(formData.validUntil).isAfter(getMaxUntilTime());
        const facilityValid = formData.facility !== null && formData.facility.length > 0

        setErrors({
            regnumberClear: {value: !regNumberValid, message: regNumberValid ? '' : 'Ogiltigt_Regnr'},
            facility: {value: !facilityValid, message: facilityValid? '' : "Anläggning_krävs"},
            validFrom: {value: !validFromValid, message: validFromValid ? '' : 'Ogiltigt_datum'},
            validUntil: {value: !validUntilValid, message: validUntilValid ? '' : "Ogiltigt_datum"}
        });

        return regNumberValid && validFromValid && validUntilValid && facilityValid;
    };

    const handleFromDateChange = (newValue) => {
        setFromDate(newValue);
        setInfoData({...infoData, [t("Startdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm') : ''});
        setFormData({...formData, validFrom: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm:ss') : 'invalid') : null});
        const valid = newValue !== null && newValue.isValid() && !newValue.isBefore(getMinFromTime());
        setErrors({...errors,
            validFrom: {value: !valid, message: valid? '' : t("Ogiltigt_datum")}
        });
    }

    const handleToDateChange = (newValue) => {
        setToDate(newValue);
        setInfoData({...infoData, [t("Slutdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm') : ''});
        setFormData({...formData, validUntil: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm:ss') : 'invalid') : null})
        const valid = newValue !== null && newValue.isValid() && !newValue.isBefore(fromDate) && !newValue.isAfter(getMaxUntilTime());
        setErrors({...errors,
            validUntil: {value: !valid, message: valid? '' : t("Ogiltigt_datum") }
        });
    }

    const handleFacilitySelectChange = (newValue:{id:number, name:string} | null) => {
        setFacilityValue(newValue);
        if(newValue){
            setInfoData({...infoData, [t("Anläggning")]: newValue.name})
        }
        setFormData({...formData, facility: newValue ? `/facilities/${newValue.id}` : ''});
        setErrors({...errors,
            facility: {value: newValue === null, message: newValue === null ? "Anläggning_krävs" : ''}
        })
    }

    const handleInputChange = (e) => {
        if(e.target.id === 'regnumberClear') {
            let regNr = e.target.value.toUpperCase().replace(/\s/g, '');
            setFormData({...formData, [e.target.id]: regNr});
            setInfoData({...infoData, [e.target.name]: regNr});
            if(regNr.length >= 6) {
                let valid = validRegnumber(regNr);
                setErrors({...errors,
                    regnumberClear: {value: !valid, message: valid? '' : 'Ogiltigt_Regnr'}
                })
            }
            else {
                setErrors({...errors,
                    regnumberClear: {value: false, message: ''}
                })
            }
        }
    };

    useEffect(() => {
        checkBillable();
    }, [formData.regnumberClear]);

    const checkBillable = async () => {
        let billable = false;
        if(formData.regnumberClear.replaceAll(" ", "").length >= 6) {
            if(validRegnumber(formData.regnumberClear)) {
                let {data, errors} = await isVehicleBillable(formData.regnumberClear);
                if(!errors || errors.length === 0) {
                    let billableInfoText:string = formData.regnumberClear.toUpperCase()
                    + t("Fordonstyp_Ägartyp", {vehicleType: data.vehicleType, ownerType: data.ownerType});
                    if(data.usageType === "RENTAL"){
                        billableInfoText += t("Användning_Hyrbil")
                    }
                    if(!data.billable) {
                        billableInfoText += t("Ej_Fakturerbar");
                    }
                    billable = !!data.billable;
                    setRegNumberHelpText(billableInfoText);
                }
            }
        }
        else {
            setRegNumberHelpText("");
        }
        setBillable(billable);
    };

    const checkExists = useAsync(async () => {
        let exists: ExistsInfo = {existsInAdditionalList:false, existsInExemptList: false, existsInMarkedList: false, existsInPreRegistrationList: false};
        if(validRegnumber(formData.regnumberClear)) {
            if(facilityValue && fromDate && fromDate.isValid() && toDate && toDate.isValid()) {
                let {data, errors} = await preRegistrationExists(
                    formData.regnumberClear, 
                    [facilityValue.id], 
                    encodeURIComponent(formData.validFrom), 
                    encodeURIComponent(formData.validUntil)
                );
               if(!errors || errors.length === 0) {
                    exists = data;
                }
            }
            else {
                exists = {existsInAdditionalList:false, existsInExemptList: false, existsInMarkedList: false, existsInPreRegistrationList: false};
            }
            let errormessage:string = '';
            if(exists.existsInExemptList) {
                errormessage = "Förregistrering_Finns_Redan_i_Gratislistan";
            }
            else if(exists.existsInPreRegistrationList) {
                errormessage = "Förregistrering_Finns_Redan_Förregistrering"
            }
            setErrors({...errors,
                regnumberClear: {value: exists.existsInExemptList || exists.existsInPreRegistrationList, message: errormessage}
            });
        }
        return exists.existsInExemptList || exists.existsInPreRegistrationList;
    },[facilityValue, formData.regnumberClear, fromDate, toDate]);

    const handleSubmit = () => {
        if (validateForm() && !checkExists.value && billable) {
            toggleHidden();
            confirmationDialog.handleOpen();    
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        clearFormData();
        toggleHidden();
    };

    const clearFormData = () => {
        setFormData({
            regnumberClear: '',
            comment: '',
            facility: currentCustomer.facilities.length > 1 ? '' : `facilities/${currentCustomer.facilities[0].id}`,
            validFrom: getMinFromTime().format('YYYY-MM-DD HH:mm:ss'),
            validUntil: startUntilTime.format('YYYY-MM-DD HH:mm:ss')
        });

        setInfoData({
            [t("Startdatum")]: getMinFromTime().format('YYYY-MM-DD HH:mm'),
            [t("Slutdatum")]: startUntilTime.format('YYYY-MM-DD HH:mm')
        });
        setFacilityValue(currentCustomer.facilities.length > 1 ? null : currentCustomer.facilities[0]);
        setFacilityInputValue("");
        setFromDate(getMinFromTime());
        setToDate(startUntilTime);
        setRegNumberHelpText("");

        setErrors({
            regnumberClear: {value: false, message: ''},
            facility: {value: false, message: ''},
            validFrom: {value: false, message: ''},
            validUntil: {value: false, message: ''}
        });
    };

    const checkDisabled = () => {
        return checkExists.value || !billable || !facilityValue || errors.validFrom.value || errors.validUntil.value;
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle sx={{xs:12}} variant="h5">{t("Ny_Föranmälan")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <TextField
                                id="regnumberClear"
                                name={t("Regnr")}
                                label={t("Regnr")}
                                size="small"
                                required
                                value={formData.regnumberClear}
                                onChange={handleInputChange}
                                error={errors.regnumberClear.value}
                                helperText={errors.regnumberClear.value ? t(errors.regnumberClear.message) : t(regNumberHelpText)}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {currentCustomer.facilities.length > 1 &&
                            <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                size="small"
                                id="facility-select"
                                options={currentCustomer.facilities}
                                getOptionLabel={(option) => option.name}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                    ))
                                }
                                value={facilityValue}
                                onChange={(e, newValue) => handleFacilitySelectChange(newValue)}
                                inputValue={facilityInputValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onInputChange={(e, newInputValue) => setFacilityInputValue(newInputValue)}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params as unknown as OutlinedTextFieldProps} 
                                    variant="outlined"
                                    error={errors.facility.value}
                                    label={t("Välj_Anläggning")}
                                    helperText={errors.facility.value ? t(errors.facility.message): ''}
                                    />
                                )}
                                sx={{
                                    background: "#FFF",
                                    borderRadius: "4px",
                                }}
                            />
                            }
                            {currentCustomer.facilities.length === 1 &&
                            <Typography variant="subtitle1">{currentCustomer.facilities[0].name}</Typography>
                            }
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <Grid item xs={6}>
                                <DateTimePicker
                                    label={t("Från")}
                                    onChange={(handleFromDateChange)}
                                    minDateTime={getMinFromTime()}
                                    value={fromDate}
                                    timeSteps={{minutes: 15}}
                                    views={['day','hours', 'minutes']}
                                    format="YYYY-MM-DD HH:mm"
                                    ampm={false}
                                    slotProps={{
                                        textField: {
                                            helperText: errors.validFrom.value ? t(errors.validFrom.message) : ''
                                        },
                                        field: {
                                            clearable: false
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimePicker
                                    label={t("Till")}
                                    value={toDate}
                                    views={['day','hours', 'minutes']}
                                    timeSteps={{minutes: 15}}
                                    format="YYYY-MM-DD HH:mm"
                                    ampm={false}
                                    onChange={handleToDateChange}
                                    minDateTime={fromDate}
                                    maxDateTime={getMaxUntilTime()}
                                    slotProps={{
                                        textField: {
                                            helperText: errors.validUntil.value ? t(errors.validUntil.message) : ''
                                        },
                                        field: {
                                            clearable: false
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <Typography>{t("Ny_Föranmälan_Förklaring")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {validRegnumber(formData.regnumberClear) && (
                                <ExemptStatsComponent
                                    regNumber={formData.regnumberClear}
                                    customerId={currentCustomer.id}
                                />
                            )}
                        </Grid>
                        {validRegnumber(formData.regnumberClear) &&
                        <Grid item xs="auto">
                            <Button variant="outlined" color="inherit" onClick={() => setShowVisits(true)}>{t("Visa_besök")}</Button>
                        </Grid>
                        }
                   </Grid>
                   <VisitHistoryModal
                    open={showVisits}
                    regNumber={formData.regnumberClear}
                    handleClose={() => setShowVisits(false)}
                    periodDays={90}
                   />
                   </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" type="submit" disabled={checkDisabled()}>{t("Lägg till")}</Button>
                    <Button variant="outlined" color="error" onClick={()=>{clearFormData(); handleClose();}}>{t("Avbryt")}</Button>
                </DialogActions>
                </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara_Föranmälan")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default AddPreRegistrationModal;