import { Navigate } from "react-router-dom";
import MarkedVehicles from "../components/MarkedVehicles/MarkedVehicles";
import { useCurrentCustomer } from "../contexts/CurrentCustomerContext";
import { AuthenticatedPage } from "./AuthenticatedPage";
import { CurrentCustomerProducts } from "../types/CurrentCustomerProducts ";

export function MarkedVehiclesPage() {
    const {currentCustomer} = useCurrentCustomer();
    return (
        <AuthenticatedPage>
        {currentCustomer && currentCustomer.id > 0 && currentCustomer.productNames && currentCustomer.productNames.length > 0 && currentCustomer.productNames.includes(CurrentCustomerProducts.MARKED_SERVICES) ?
            <MarkedVehicles />
            : 
            <Navigate to='/'/>
        }
        </AuthenticatedPage>
      )
}