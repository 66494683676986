import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {EditComponent} from "../Edit/EditComponent";
import {CloseComponent} from "./CloseComponent";
import {ExemptComponent} from "../Edit/ExemptComponent";
import {ErrorResponse} from "../../../api/apiUtils";
import {fetchExemptStats} from "../../../api/BillableVisitsAPI";
import {ExemptStats} from "../../../types/ExemptStats";
import {RevisitComponent} from "../Edit/RevisitComponent";
import {ExemptReasonComponent} from "../Edit/ExemptReasonComponent";
import {FreeOrgComponent} from "../Edit/FreeOrgComponent";
import {ForeignComponent} from "../Edit/ForeignComponent";
import {BillableVisitType} from "../../../types/enums/BillableVisitType";
import {PreRegistredComponent} from "../Edit/PreRegistredComponent";


const mobilePhoneStyle = {
    padding: '10px',
    backgroundColor: '#dedede',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    marginTop: '20px',
    overflow: "auto",
    '&::-webkit-scrollbar': {display: 'none'},
    minHeight: '300px',
    width: '100%',
};


export function VisitComponent({onHistoryClick, dataBillableVisit, hidden, close, back}) {
    const [showExceptionReason, setShow] = React.useState(false);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    let [exemptStats, setExemptStats] = useState<ExemptStats>();
    const [visitDisplayData, setVisitDisplayData] = useState<ExemptStats>(dataBillableVisit);

    useEffect(() => {
        if (!hidden) {
            fetchInitialData();
        }
    }, [hidden]);

    const fetchInitialData = async () => {
        const {
            data,
            errors
        } = await fetchExemptStats(dataBillableVisit.vehicleData.regNumber, dataBillableVisit.customerId);
        declareTotalVisits(data)
        setErrors(errors);
        setVisitDisplayData(dataBillableVisit)
    };

    const declareTotalVisits = (data) => {
        //TODO: Is this needed now that we use ExemptStatsComponent ?
        let tempCount = 0;
        Object.keys(BillableVisitType).map(key => {
            if (data.stats[key] && key !== BillableVisitType.EXEMPT) {
                tempCount = tempCount + data.stats[key].total;
            }
        })
        data.totalVisits = tempCount;
        setExemptStats(data);
    };


    const handleClose = (item) => {
        setShow(false);
        if (item) {
            close(item)
        } else {
            close()
        }
    }

    const handleBack = async (item) => {
        setShow(false);
        if (item) {
            const {data, errors} = await fetchExemptStats(item.vehicleData.regNumber, item.customerId);
            declareTotalVisits(data);
            setVisitDisplayData({...item})
            back(item)
        }
    }

    function showVisitBasedOnCategory() {
        switch (dataBillableVisit.type) {
            case BillableVisitType.INVOICE:
                return <EditComponent stats={exemptStats} data={visitDisplayData} exempt={() => {
                    setShow(prev => !prev)
                }} close={handleClose}></EditComponent>
            case BillableVisitType.EXEMPT:
                return <ExemptComponent visitData={visitDisplayData} exempt={() => {
                    setShow(prev => !prev)
                }} stats={exemptStats} close={handleClose} back={handleBack}></ExemptComponent>
            case BillableVisitType.REVISIT:
                return <RevisitComponent onClick={onHistoryClick} visitData={visitDisplayData} close={handleClose}/>
            case BillableVisitType.FREE_ORGANIZATION:
            case BillableVisitType.FREE_VEHICLE:
                return <FreeOrgComponent visitData={visitDisplayData} exempt={() => {
                    setShow(prev => !prev)
                }} stats={exemptStats} close={handleClose} back={handleBack}></FreeOrgComponent>
            case BillableVisitType.FOREIGN:
                return <ForeignComponent visitData={visitDisplayData} exempt={() => {
                    setShow(prev => !prev)
                }} stats={exemptStats} close={handleClose} back={handleBack}></ForeignComponent>
            case BillableVisitType.PRE_REGISTERED:
                return <PreRegistredComponent visitData={visitDisplayData} exempt={() => {
                    setShow(prev => !prev)
                }}  close={handleClose}></PreRegistredComponent>

        }
    }

    return (
        <>

            {!hidden &&
                <Box sx={mobilePhoneStyle}>
                    <CloseComponent title={dataBillableVisit.type} subtitle={dataBillableVisit.preRegistration !== undefined ?dataBillableVisit.preRegistration.status: ''} hidden={hidden} close={handleClose}/>
                    {!showExceptionReason && exemptStats &&
                        showVisitBasedOnCategory()
                    }

                    {showExceptionReason && exemptStats &&
                        <ExemptReasonComponent visitData={visitDisplayData} stats={exemptStats} close={close}
                                               back={handleBack}></ExemptReasonComponent>
                    }

                </Box>
            }
        </>

    )
}