import {AuthenticatedPage} from "./AuthenticatedPage";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


export function HelpView() {

    const {t} = useTranslation();

    return (
        <AuthenticatedPage>
            <Typography variant='h5'>{t("Hjälp")}</Typography>
        </AuthenticatedPage>
    )
}