export enum PreRegistrationStatusType {
    REGISTERED = "REGISTERED",
	APPROVED = "APPROVED",
	REJECTED = "REJECTED",
	TO_REVIEW = "TO_REVIEW",
	EXPIRED = "EXPIRED",
	VISITED_WITHOUT_REVIEW = "VISITED_WITHOUT_REVIEW",
	APPROVED_AFTER_REVIEW = "APPROVED_AFTER_REVIEW",
	INVOICE_AFTER_REVIEW = "INVOICE_AFTER_REVIEW",
	CANCELLED = "CANCELLED",
	UNKNOWN = "UNKNOWN"
}

export const canBeHandledAfterVisit = [
	PreRegistrationStatusType.INVOICE_AFTER_REVIEW,
	PreRegistrationStatusType.APPROVED
]

export const canBeHandledBeforeVisit = [
	PreRegistrationStatusType.APPROVED,
]

export const inactiveStatuses = [
	PreRegistrationStatusType.CANCELLED,
	PreRegistrationStatusType.EXPIRED,
	PreRegistrationStatusType.REJECTED
]