import {Facility, toFacility} from "../../types/Facility";
import {DataGrid,  GridColDef, svSE} from "@mui/x-data-grid";
import {StatusIcon} from "../Shared/StatusIcon";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import useTheme from "@mui/system/useTheme";
import {useTranslation} from "react-i18next";
import {Service, toService} from "../../types/Service";
import {ServiceType} from "../../types/enums/ServiceType";
import {ThemeProvider, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from "@mui/material/FormControl";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormatStringYYYYmmdd} from "../../helpers/DateFormatting";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import { useCurrentUser } from "../../contexts/CurrentUserContext";

interface FacilitiesTableProps {
    facilities: Facility[];
    onNameChange: (e:any, d:any) => void;
    onDateChange:(s:any,b:any) => void;
    loading:boolean;
}

let addData:{rows:any[],dateToUpdate:string, serviceType:string, statusText: string} ={
    rows:[],
    dateToUpdate:"",
    serviceType: "",
    statusText:"startas",
}

let selectedRowsCurrent: any[];

const FacilitiesTable = ({facilities,onNameChange,onDateChange,loading}:FacilitiesTableProps) => {
    const theme = useTheme();
    const [statusDate, setStatusDate] = React.useState("startas");
    const { t } = useTranslation();
    const [checkboxSelection, setCheckboxSelection] = React.useState(false);
    const [currentEdit, setCurrentEdit] = React.useState("");
    const [bulkEdit, setBulkEdit] = React.useState(false);
    const [buttonActive, setButtonActive] = React.useState(false);
    const [currentEditService, setCurrentEditService] = React.useState("");
    const [bulkEditDate, setBulkEditDate] = React.useState<Date | null>(null);
    const {currentUser} = useCurrentUser();

    const columns: GridColDef[] = [
        {
            field: 'status', editable: false,disableColumnMenu: true, headerName: '',width: 60, sortable: false, renderCell: (params) => {
                return <StatusIcon {...params.row} />;
            }
        },
        {field: 'name', editable: true, headerName: t("Anläggning"),sortable: false, disableColumnMenu: true, minWidth: 30, flex: 1},
        {field: ServiceType.MEASURING, editable: false, headerName: t("Mätning"), minWidth: 50, flex: 1,sortable: false, disableColumnMenu: true, type:"date",
            valueGetter: (params) =>  params.row.MEASURING.startDate,
            valueFormatter: ({ value }) => (FormatStringYYYYmmdd(value)),
            renderCell: (params: any) => informationToolTip(params.row.MEASURING),
            renderHeader: (params: any) => headerEditView(params),
        },
        {field: ServiceType.DEMO_AUTO_INVOICING, width: 30, editable: false, headerName: t("Demo Auto Fakt"), flex: 1,sortable: false, disableColumnMenu: true, type:"date",
            valueGetter: (params) => params.row.DEMO_AUTO_INVOICING.startDate,
            valueFormatter: ({ value }) => (FormatStringYYYYmmdd(value)),
            cellClassName:  (params) => setColorStatusService( params.row.DEMO_AUTO_INVOICING.status),
            renderCell: (params: any) => informationToolTip(params.row.DEMO_AUTO_INVOICING),
            renderHeader: (params: any) => headerEditView(params),
        },
        {field: ServiceType.COMPANY_AUTO_INVOICING, editable: false, headerName: t("Företag Auto Fakt"), minWidth: 50, flex: 1,sortable: false, disableColumnMenu: true, type:"date",
            valueGetter: (params) => params.row.COMPANY_AUTO_INVOICING.startDate,
            valueFormatter: ({ value }) => (FormatStringYYYYmmdd(value)),
            cellClassName:  (params) => setColorStatusService( params.row.COMPANY_AUTO_INVOICING.status),
            renderCell: (params: any) => informationToolTip(params.row.COMPANY_AUTO_INVOICING),
            renderHeader: (params: any) => headerEditView(params),
        },
        {field: ServiceType.SOLE_TRADER_AUTO_INVOICING, editable: false, headerName: t("Enskild Auto Fakt"), flex: 1,sortable: false, disableColumnMenu: true, type:"date",
            valueGetter: (params) => params.row.SOLE_TRADER_AUTO_INVOICING.startDate,
            valueFormatter: ({ value }) => (FormatStringYYYYmmdd(value)),
            cellClassName:  (params) => setColorStatusService( params.row.SOLE_TRADER_AUTO_INVOICING.status),
            renderCell: (params: any) => informationToolTip(params.row.SOLE_TRADER_AUTO_INVOICING),
            renderHeader: (params: any) => headerEditView(params),
        },
    ];

    const informationToolTip = (input:any) =>{
        return (
            <>
                {FormatStringYYYYmmdd(input.startDate)}
                <> </>
                {Date.parse(input.endDate) !== Date.parse(new Date(0).toString()) ?
                    <Tooltip placement="right" title= {t("Slutdatum") + ": " + FormatStringYYYYmmdd(input.endDate)}>
                        <IconButton aria-label="Example">
                            <InfoOutlinedIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip> : ''
                }

            </>
        )
    }

    const headerEditView = (input:any) =>{
        return (
            <div style={{position:"absolute",width:"100%"}}>
                <div>
                    <strong>{t(input.colDef.headerName)}</strong>
                </div>
                {currentUser.isAdmin && 
                <IconButton sx={{backgroundColor:'lightgrey',"&:hover": {backgroundColor: "lightgrey"},position: 'absolute',top:'10px',right:'0px'}} onClick={() => setBulk(input)}>
                    <EditIcon/>
                </IconButton>
                }
            </div>
        )
    }


    function setColorStatusService(value: any) {
        if (value === true) {
            return 'MuiDataGrid-green'
        }
        if (value === "neutral") {
            return 'MuiDataGrid-blue'
        }
        if (value === false) {
            return 'MuiDataGrid-red'
        } else {
            return 'MuiDataGrid'
        }
    }

    function setBulk(value: any) {
        if (currentEditService !== value.colDef.headerName) {
            setBulkEdit(true);
            setCheckboxSelection(true)
        } else {
            setBulkEdit(!bulkEdit);
            setCheckboxSelection(!bulkEdit);
        }
        setCurrentEditService(value.colDef.headerName)
        setCurrentEdit(value.colDef.field);
    }

    const generateRows = (facility: Facility): any[] => {
        setStatus(facility);
        const rows : any[] = [{id:facility.id,name: facility.name, status: facility.active}];

        if(facility.services !== undefined){
            facility.services && facility.services.map(toService).map((service: Service) => (
                rows.push({[service.type]: {startDate:service.startDate ? new Date(service.startDate):new Date(0), endDate:new Date(service.endDate), serviceId: service.id,status:service.active}})
                )
            )
        }
        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    function setStatus  (facility: Facility) {
        let currentDate = Date.parse(new Date().toDateString());

        for (const arrayItem of facility.services) {
            let startDateTemp = Date.parse(arrayItem.startDate);
            let endDateTemp = Date.parse(arrayItem.endDate);
            if(arrayItem.type === ServiceType.MEASURING){
                if((currentDate >= startDateTemp) && ((currentDate < endDateTemp) || (!isNaN(startDateTemp) && isNaN(endDateTemp)))){
                    facility.active = true;
                }
                else if((currentDate > startDateTemp) && (currentDate > endDateTemp)){
                    facility.active = false;
                }
                else if((currentDate < startDateTemp)){
                    facility.active = "neutral";
                }
            }else{
                if((currentDate >= startDateTemp) && ((currentDate < endDateTemp) || (!isNaN(startDateTemp) && isNaN(endDateTemp)))){
                    arrayItem.active = true;
                }
                else if((currentDate > startDateTemp) && (currentDate > endDateTemp)){
                    arrayItem.active = false;
                }
                else if((currentDate < startDateTemp)){
                    arrayItem.active = "neutral";
                }
                else if((isNaN(startDateTemp) && isNaN(endDateTemp))){
                    arrayItem.active = "nothing";
                }
            }
        }
    }

    const setSelectedRowsData = (item:any, selectedRowsSorted:any) =>{
        if(item[currentEdit]){
            selectedRowsSorted.push({
                facilityId: item.id,
                serviceId: item[currentEdit].serviceId,
                facilityName: item.name,
                startDate: item[currentEdit].startDate != null ? item[currentEdit].startDate.toISOString().substring(0, 10): '',
                endDate: item[currentEdit].endDate != null ? item[currentEdit].endDate.toISOString().substring(0, 10): '',

            })
        }
    }

    const formData = () =>{
        const selectedRowsSorted: any[] = []
        selectedRowsCurrent.map(item => setSelectedRowsData(item,selectedRowsSorted))
        addData.rows=selectedRowsSorted;
        addData.dateToUpdate = bulkEditDate != null ? new Intl.DateTimeFormat('sv-SE', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(bulkEditDate):'';
        addData.serviceType=currentEdit;
        addData.statusText=statusDate;
        onDateChange(addData,true);
    }

    const onCellEditStart = (event: any) =>
    {
        setCurrentEdit(event.field)
        setCheckboxSelection(false);
        setBulkEdit(false);
        addData.rows=[];
    }


    const handleProcessRowUpdate = (newRow:any, oldRow:any) => {

        if(currentEdit === "name"){
            setCheckboxSelection(false);
            addData.rows=[];
            onNameChange(newRow,oldRow);
        }else{
            addData.rows.push({
                facilityId: newRow.id,
                serviceId: oldRow[currentEdit].serviceId,
                facilityName: newRow.name,
                startDate: oldRow[currentEdit].startDate != null ? oldRow[currentEdit].startDate.toISOString().substring(0, 10): '',
                endDate: oldRow[currentEdit].endDate != null ? oldRow[currentEdit].endDate.toISOString().substring(0, 10): '',

            })
            addData.dateToUpdate = newRow[currentEdit].toLocaleString('sv-SE', {year: 'numeric', month: '2-digit', day: '2-digit'}).substring(0, 10);
            addData.serviceType = currentEdit;
            onDateChange(addData,false);
        }
        return oldRow;
    }

    const onRowSelectionModelChange = (event: any) =>
    {

        const selectedIDs = new Set(event);
        const filterAffectedRows = rows.filter((row) => selectedIDs.has(row.id.toString())).map(i => i);
        const selectedRowsSorted: any[] = []

        filterAffectedRows.map(item => setSelectedRowsData(item,selectedRowsSorted))

        if(JSON.stringify(selectedRowsCurrent) !== JSON.stringify(filterAffectedRows) && selectedRowsCurrent){
            addData.rows = selectedRowsSorted
        }

        selectedRowsCurrent= [];
        filterAffectedRows.map((item: any) => (selectedRowsCurrent.push(item)))
        checkButtonActive(selectedRowsSorted);
        }

    const checkButtonActive = (input):boolean => {
        if(input && input.length >0){
            setButtonActive(true);
            return true;
        }

        setButtonActive(false)
        return false
    }

    const handleRadioChange = (event) => {
        const output = event.target.value;
        setStatusDate(output);
    }

    const rows: any[] = facilities.map(toFacility).map(generateRows);

    return (
        <>
            <Box sx={{
                maxWidth: {xs:'100%', sm: '95%'},
                borderRadius: theme.spacing(1),
                marginBottom: theme.spacing(2),
                boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
                overflow: 'auto',
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: theme.palette.primary.main,
                },
                    '& .MuiDataGrid-rowSeparator': {
                        display: 'none',
                    },
                '& .MuiDataGrid-iconSeparator': {
                    display:"none"
                },
                "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                    display: "none"
                },
                "& .MuiDataGrid-red": {
                    borderImage: 'linear-gradient(20deg, red, rgb(225, 225, 225) 50%) 1',
                },
                "& .MuiDataGrid-green": {
                    borderImage: 'linear-gradient(20deg, green, rgb(225, 225, 225) 50%) 1',
                    borderTopWidth: '20%'
                },
                "& .MuiDataGrid-blue": {
                    borderImage: 'linear-gradient(20deg, rgb(41, 182, 246), rgb(225, 225, 225) 50%) 1',
                    borderTopWidth: '20%'
                },
                "& .MuiDataGrid-nothing": {
                    borderImage: 'linear-gradient(20deg, rgb(41, 182, 246), white 0%) 1',
                    borderTopWidth: '20%'
                },
            }}>
                <Paper>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            sx={{width: '100%', '--DataGrid-overlayHeight': '300px'}}
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            getRowId={(row: any) => row.id}
                            checkboxSelection={checkboxSelection}
                            onCellEditStart={onCellEditStart}
                            processRowUpdate={handleProcessRowUpdate}
                            onRowSelectionModelChange={onRowSelectionModelChange}
                            localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 10}},
                            }}
                            pageSizeOptions={[10]}
                            hideFooterSelectedRowCount={true}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            autoHeight
                        />
                    </ThemeProvider>
                </Paper>
            </Box>
            <Box>
                {bulkEdit ?
                    <>
                        <Box sx={{width: '90%'}}>
                            <Box>
                                <h4>{t("Lägg till/ändra datum för")} {t(currentEditService)}</h4>
                            </Box>
                            <Box>
                                <DemoContainer components={['H4', 'DatePicker']}>
                                    <h4>{t("Nytt datum för valda anläggningar")}</h4>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="se">
                                        <DatePicker value={bulkEditDate}
                                                    format='YYYY-MM-DD'
                                                    onChange={(newValue) => setBulkEditDate(newValue)}/>
                                    </LocalizationProvider>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="active"
                                        id="active"
                                        onChange={handleRadioChange}
                                        value={statusDate}
                                    >
                                     <FormControl  sx={{ marginLeft: "5rem"}} >
                                         <FormControlLabel value="startas" control={<Radio/>} label={t("Starta") + " - " + t(currentEditService)}/>
                                         <FormControlLabel value="avslutas" id="inactive" control={<Radio/>} label={t("Avsluta") + " - " + t(currentEditService)}/>
                                     </FormControl>
                                    </RadioGroup>
                                </DemoContainer>
                                <DialogActions  sx={{m: 2}}>
                                    <Button disabled={(!buttonActive || bulkEditDate===null)} color="inherit" variant="outlined" type="submit" onClick={formData}>{t("Spara")}</Button>
                                    <Button variant="outlined" color="error" onClick={() =>{setBulkEdit(false); (setCheckboxSelection(false))}}>{t("Avbryt")}</Button>
                                </DialogActions>
                            </Box>
                        </Box>
                    </>
                    :
                    ''
                }
            </Box>
        </>
    );
};
export default FacilitiesTable;