import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Stack, TextField, useTheme} from "@mui/material";
import {toBillableVisitID} from "../../../types/BillableVisit";
import Grid from "@mui/material/Grid";
import CommentIcon from "@mui/icons-material/Comment";
import {updateVisitComment} from "../../../api/BillableVisitsAPI";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";

export function FreeOrgComponent({visitData, stats, exempt, close, back}) {
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        comment: '',
    });
    const [disabled, setDisabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [visitId, setVisitId] = React.useState("");

    useEffect(() => {
        if (visitData) {
            let id = toBillableVisitID(visitData);
            setVisitId(id)
            setFormData({...formData, ["comment"]: visitData.comment});
        }
    }, [visitData]);

    useEffect(() => {
        if (compareData("comment")) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [formData]);

    const compareData = (field: string) => {
        return !!(formData[field] && formData[field] !== visitData[field]) || !!(!formData[field] && visitData[field])
    }

    const saveVisitComment = async () => {
        if (visitId && formData.comment !== undefined) {
            setLoading(true)
            try {
                const result = await updateVisitComment(visitId, formData.comment);
                setLoading(false);
                if (!isEmptyArray(result.errors)) {
                    throw new Error("Kunde inte uppdatera");
                }
                visitData = {...result.data}
                return true;
            } catch (error: any) {
                return null;
            }
        }

        return true;
    }

    const saveVisit = async () => {
        if (await saveVisitComment()) {
            close(visitData);
        }
    }

    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.id]: e.target.value});
    };

    return (
        <>
            <Box>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack alignItems="center" direction="row" gap={2}>
                                <CommentIcon/>
                                <TextField
                                    id="comment"
                                    fullWidth
                                    label={t("Kommentar")}
                                    value={formData.comment}
                                    onChange={handleInputChange}
                                    InputLabelProps={{shrink: true, spellCheck: 'false'}}
                                    multiline
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <DialogActions style={{justifyContent: 'center', position: 'absolute', bottom: 20}}>
                        <Button disabled={disabled} variant="contained" color="inherit" onClick={saveVisit} size="large"
                                type="submit">{t("Spara")}</Button>
                        <Button size="large" onClick={() => close()} variant="outlined"
                                color="error">{t("Avbryt")}</Button>
                    </DialogActions>
                </Box>
            </Box>
        </>
    )
}