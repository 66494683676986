import React, {useState} from "react";
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import {createFacility} from "../../../api/FacilitiesAPI";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";
import {ErrorResponse} from "../../../api/apiUtils";

let outputData = {
    id: "",
    created: "",
    updated: "",
    name: "",
    latitude: "",
    longitude: "",
    customer: "",
    active: false,
    services: []
}

const AddFacilityModal = ({customer, onSuccess}) => {
    const [open, setOpen] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const {t} = useTranslation();
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const confirmationDialog = useConfirmationDialog();
    const [infoData, setInfoData] = useState({});

    const [formData, setFormData] = useState({
        Namn: '',
        Latitude: '',
        Longitude: '',
    });

    const handleHidden = () => setHidden(!hidden)
    const [errorsValidation, setErrorsValidation] = useState({
        Namn: false,
        Latitude: false,
        Longitude: false,
    });

    const handleOpen = () => {
        setHidden(false);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        clearValues();
    }

    const handleConfirmChanges = () => {
        outputData.name = formData.Namn;
        outputData.longitude = formData.Longitude;
        outputData.latitude = formData.Latitude;
        outputData.customer = customer._links.self.href;

        createNewFacility(outputData);
    };

    const createNewFacility = async (outputData: any) => {
        const {data, errors} = await createFacility(outputData);
        if (isEmptyArray(errors)) {
            clearValues();
            setOpen(false)
            onSuccess({});
            confirmationDialog.handleClose(handleHidden);
        } else {
            setErrors(errors)
        }
    }

    const clearValues = () => {
        setFormData({
            Namn: '',
            Latitude: '',
            Longitude: '',
        });

        setErrorsValidation({
            Namn: false,
            Latitude: false,
            Longitude: false,
        });
    };

    const handleBack = () => {
        setHidden(false);
    };

    const handleChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
    };

    const validateForm = () => {
        const nameValid = formData.Namn.trim() !== '';
        const latituteValid = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/.test(formData.Latitude);
        const longitudeValid = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/.test(formData.Longitude);

        setErrorsValidation({
            Namn: !nameValid,
            Latitude: !latituteValid,
            Longitude: !longitudeValid,
        });

        return nameValid && latituteValid && longitudeValid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleHidden();
            confirmationDialog.handleOpen();
        }
    };

    return (
        <>
            <Button variant="outlined" color="inherit" onClick={handleOpen}>{t("Lägg till ny anläggning")}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                hidden={hidden}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <Grid item xs={12}>
                    <DialogTitle variant="h4">{t("Ny anläggning")} ({customer.name})</DialogTitle>
                </Grid>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={t("Namn")}
                                size="small"
                                name="Namn"
                                error={errorsValidation.Namn}
                                helperText={errorsValidation.Namn ? t('Namn krävs') : ''}
                                onChange={handleChange}
                                value={formData.Namn}
                                focused
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("Latitude")}
                                size="small"
                                name="Latitude"
                                error={errorsValidation.Latitude}
                                helperText={errorsValidation.Latitude ? t('Latitud krävs') : ''}
                                onChange={handleChange}
                                value={formData.Latitude}
                                focused
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("Longitude")}
                                name="Longitude"
                                error={errorsValidation.Longitude}
                                helperText={errorsValidation.Longitude ? t('Longitud krävs') : ''}
                                size="small"
                                onChange={handleChange}
                                value={formData.Longitude}
                                focused
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button sx={{}} type="submit" color="inherit" variant="outlined">{t("Lägg till")}</Button>
                    <Button sx={{marginLeft: "1rem"}} variant="outlined" color="error"
                            onClick={handleClose}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(handleBack)}
                handleAgree={handleConfirmChanges}
                title="Lägg till anläggning"
                subtitle="Följande uppgifter kommer att läggas till:"
                content={infoData}
                errors={errors}
                list={[]}
                active={false}
            />
        </>
    );
};
export default AddFacilityModal;