import React, {useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {Autocomplete, Chip, OutlinedTextFieldProps, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { useAsync } from "react-use";
import { DatePicker, DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv'
import 'dayjs/locale/en';
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";
import { AdditionalVehicle } from "../../../types/AdditionalVehicle";

const UpdateAdditionalVehicleModal = ({open, handleClose, handleConfirmChanges, data}:
    {
        open: boolean,
        handleClose:any,
        handleConfirmChanges:any,
        data: AdditionalVehicle
    }
) => {
    const {currentCustomer} = useCurrentCustomer();
    const {t, i18n} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [infoData, setInfoData] = useState({
        [t("Slutdatum")]: t("Tillsvidare")
    });
    const [commentCharCount, setCommentCharCount] = useState(0);
    const maxCommentChars = 250;
    const [dirtyForm, setDirtyForm] = useState(false);
    const [facilityValues, setFacilityValues] = useState<{id:number, name:string}[]>([]);
    const [facilityInputValue, setFacilityInputValue] = useState("");

    const tomorrow = dayjs().add(1, 'day');
    const [fromDate, setFromDate] = useState(tomorrow);
    const [toDate, setToDate] = useState<dayjs.Dayjs | null>(null);
    const [toDateError, setToDateError] = useState<DateValidationError|null>(null);

    const [formData, setFormData] = useState<{
        regnumberClear:string,
        comment:string,
        facilities: string[],
        validFrom:any,
        validUntil:any
    }>({
        regnumberClear: '',
        comment: '',
        facilities: [],
        validFrom: null,
        validUntil: null
    });

    const [errors, setErrors] = useState({
        comment: {value: false, message: ''},
        facilities: {value: false, message: ''},
        validUntil: {value: false, message: ''}
    });

    const {} = useAsync(async () => {
            setStartData();
    }, [data]);

    const setStartData = () => {
        if(data) {
            formData.regnumberClear = data.regnumberClear ?? '';
            formData.comment = data.comment ?? '';
            formData.facilities = currentCustomer.facilities.length === 1 ? [`/facilities/${currentCustomer.facilities[0].id}`] : data.facilityNames.map(f => `/facilities/${f.id}`);
            formData.validFrom = data.validFrom ?? null;
            formData.validUntil = data.validUntil ?? null;

            setFacilityValues(data.facilityNames);
            setCommentCharCount(formData.comment.length);
            setFromDate(dayjs(formData.validFrom));
            let currentToDate = formData.validUntil? dayjs(formData.validUntil) : null;
            if(currentToDate !== null && currentToDate.isBefore(tomorrow)){
                setFormData({...formData, validUntil: tomorrow.format('YYYY-MM-DD')});
            }
            setToDate(formData.validUntil ? dayjs(formData.validUntil) : null);
        }
    }

    const getMinToDate = () => {
        return fromDate.isBefore(tomorrow)? tomorrow : fromDate;
    };

    const validateForm = () => {
        const commentValid = formData.comment.length <= maxCommentChars;
        const validUntilValid = formData.validUntil !== 'invalid' || (formData.validUntil && dayjs(formData.validUntil, 'YYYY-MM-DD', true).isValid() && !dayjs(formData.validUntil).isBefore(getMinToDate()));
        const facilitiesValid = formData.facilities.length > 0;

        setErrors({
            comment: {value: !commentValid, message: commentValid ? '' : 'För lång'},
            facilities: {value: !facilitiesValid, message: facilitiesValid? '' : "Anläggning_krävs"},
            validUntil: {value: !validUntilValid, message: validUntilValid ? '' : "Ogiltigt_datum"}
        });

        return commentValid && validUntilValid && facilitiesValid;
    };

    const handleToDateChange = (newValue) => {
        setDirtyForm(true);
        setToDate(newValue);
        setInfoData({...infoData, [t("Slutdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD') : t("Tillsvidare")});
        setFormData({...formData, validUntil: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD') : 'invalid') : null});
    }

    const handleFacilitySelectChange = (newValue:{id:number, name:string}[]) => {
        setDirtyForm(true);
        setFacilityValues(newValue);
        if(newValue){
            setInfoData({...infoData, [t("Anläggning")]: newValue.map(v => v.name).toString()})
        }
        setFormData({...formData, facilities: newValue ? newValue.map(v => `/facilities/${v.id}`) : []});
        setErrors({...errors,
            facilities: {value: newValue.length < 1, message: newValue.length < 1 ? "Anläggning_krävs" : ''}
        })
    }

    const handleInputChange = (e) => {
        setDirtyForm(true);
        setFormData({...formData, [e.target.id]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
        if(e.target.id === 'comment') {
            setErrors({...errors,
                comment: {value: (e.target.value.length > maxCommentChars), message: 'Kommentar får inte vara för lång'}
            });
            setCommentCharCount(e.target.value.length);
        }
    };

    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        toggleHidden();
        setDirtyForm(false);
    }

    const clearFormData = () => {
        setDirtyForm(false);
        setFormData({
        regnumberClear: '',
        comment: '',
        facilities: [],
        validFrom: null,
        validUntil: null 
        });
        setInfoData({})
        setCommentCharCount(0);
        setFromDate(tomorrow);
        setToDate(null);

        setErrors({
            comment: {value: false, message: ''},
            facilities: {value: false, message: ''},
            validUntil: {value: false, message: ''}
        });
    };

    const toDateErrorString = useMemo(() => {
        switch(toDateError) {
            case 'invalidDate':
            case 'maxDate':
            case 'minDate': {
                setErrors({...errors, validUntil: {value: true, message: "Ogiltigt_datum"}});
                return t("Ogiltigt_datum");
            }
            default: {
                setErrors({...errors, validUntil: {value: false, message: ''}});
                return '';
            }
        }
    }, [toDateError]);

    
    const checkDisabled = () => {
        return !dirtyForm || toDateError != null || errors.facilities.value || errors.comment.value;
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose()}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle variant="h5">{t("Redigera_Extra_Fordon")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t("Fordon")}: {data.regnumberClear}</Typography>
                            </Grid>
                        <Grid item xs={12}>
                            {currentCustomer.facilities.length > 1 &&
                            <Autocomplete
                                selectOnFocus
                                multiple
                                clearOnBlur
                                size="small"
                                id="facility-select"
                                limitTags={2}
                                options={currentCustomer.facilities}
                                getOptionLabel={(option) => option.name}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                    ))
                                }
                                value={facilityValues}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, newValue) => handleFacilitySelectChange(newValue)}
                                inputValue={facilityInputValue}
                                onInputChange={(e, newInputValue) => setFacilityInputValue(newInputValue)}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params as unknown as OutlinedTextFieldProps} 
                                    variant="outlined"
                                    error={errors.facilities.value}
                                    label={t("Välj_Anläggning")}
                                    helperText={errors.facilities.value ? t(errors.facilities.message): ''}
                                    />
                                )}
                                sx={{
                                    background: "#FFF",
                                    borderRadius: "4px",
                                }}
                            />
                            }
                            {currentCustomer.facilities.length === 1 &&
                            <Typography variant="subtitle1">{currentCustomer.facilities[0].name}</Typography>
                            }
                         </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comment"
                                name={t("Kommentar")}
                                label={t("Kommentar")}
                                size="small"
                                value={formData.comment}
                                onChange={handleInputChange}
                                fullWidth
                                error={errors.comment.value}
                                helperText={commentCharCount + '/' + maxCommentChars + ' ' + (errors.comment.value ? t(errors.comment.message) : '')}
                                autoComplete="off"
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <Grid item xs={6}>
                                <DatePicker
                                    name={t("Startdatum")}
                                    label={t("Startdatum")}
                                    readOnly
                                    value={fromDate}
                                    format="YYYY-MM-DD"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    name={t("Slutdatum")}
                                    label={t("Slutdatum")}
                                    value={toDate}
                                    format="YYYY-MM-DD"
                                    onChange={handleToDateChange}
                                    onError={(error) => setToDateError(error)}
                                    minDate={getMinToDate()}
                                    slotProps={{
                                        textField: {
                                            helperText: toDateError? toDateErrorString : t("Lämna_tom_för_tillsvidare")
                                        },
                                        field: {
                                            clearable: true
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <Typography>{t("Uppdatering_Extra_Fordon_Förklaring")}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button disabled={checkDisabled()} color="inherit" variant="outlined" type="submit">{t("Uppdatera")}</Button>
                    <Button variant="outlined" color="error" onClick={() => {clearFormData(); handleClose()}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara Extra Fordon")}
                subtitle={t("Följande uppgifter kommer att ändras")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default UpdateAdditionalVehicleModal;