import React, {useEffect, useState} from "react";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GroupIcon from "@mui/icons-material/Group";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import InfoIcon from "@mui/icons-material/Info";
import ReceiptIcon from '@mui/icons-material/Receipt';
import HistoryIcon from '@mui/icons-material/History';
import WarehouseIcon from "@mui/icons-material/Warehouse";
import BusinessIcon from "@mui/icons-material/Business";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CommuteIcon from "@mui/icons-material/Commute";
import {useTranslation} from "react-i18next";
import { CheckCircle, EmojiTransportation, ExpandLess, ExpandMore, MinorCrash, NoCrash, Payment, Summarize } from "@mui/icons-material";
import { Box, Collapse, Tooltip, Typography } from "@mui/material";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import CustomDrawerListItem from "../Shared/CustomDrawerListItem";
import { CurrentCustomerProducts } from "../../types/CurrentCustomerProducts ";
import { customerAdminRole, nonAdminRoles, nonViewerRoles, viewerRole } from "../../types/CurrentUserRoles";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const CustomDrawerRoot = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const DrawerHeader = styled("div")(({theme, hidden}) => ({
    display: hidden ? "none" : "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const shouldShowRealtimeview = () => {
    return true;
};

interface CustomDrawerProps {
    open: boolean;
    hidden: boolean;
    handleDrawerClose: () => void;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({open, hidden, handleDrawerClose}) => {
    const {t} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const {currentUser} = useCurrentUser();
    const [listOpen, setListOpen] = useState<boolean>(() => {
        const savedListOpen = localStorage.getItem('listOpen');
        return savedListOpen === 'true';
    });

    const hasMarkedItemService = () => {
        return currentCustomer.productNames? currentCustomer.productNames.includes(CurrentCustomerProducts.MARKED_SERVICES) : false;
    };
    
    const isCustomerAdmin = () => {
        return currentUser && currentUser.isAuthorized && currentUser.roles.some(r => r.id === customerAdminRole.id);
    }
    
    const hasReportReaderGroup = () => {
        return currentUser && currentUser.isAuthorized && currentUser.groups.some(g => g.name.toUpperCase().includes('READER'));
    }

    const hasNonViewerRole = () => {
        return currentUser && currentUser.isAuthorized && nonViewerRoles.some(r => currentUser.roles.some(cur => cur.id === r.id));
    }

    useEffect(() => {
        localStorage.setItem('listOpen', listOpen.toString());
    }, [listOpen]);

    const adminUserDrawerItems = [
        {
            text: "Kunder",
            icon: <BusinessIcon/>,
            url: "/"
        },
    ];

    const hiddenCustomerListItems = [
        {
            text: "Avgifter",
            icon: <Payment/>,
            url: `/fees/${currentCustomer.id}`
        }
    ];

    const nonViewRoleCustomerListItems = [
        {
            text: "Extra_Fordon",
            icon: <MinorCrash/>,
            url: `/additionalvehicle/${currentCustomer.id}`
        },
    ];

    const customerAdminListItems = [
        {
            text: "Gratis_Org",
            icon: <CheckCircle/>,
            url: `/freeorg/${currentCustomer.id}`
        },
        {
            text: "Gratis_Fordon",
            icon: <NoCrash/>,
            url: `/freevehicle/${currentCustomer.id}`
        },
    ];

    const markedListItems = [
        {
            text: "Markerade_Org",
            icon: <InfoIcon/>,
            url: `/flaggedorg/${currentCustomer.id}`
        },
        {
            text: "Markerade_Fordon",
            icon: <CarCrashIcon/>,
            url: `/flaggedvehicle/${currentCustomer.id}`
        },
    ];

    const customerDrawerItems = [
        {
            text: "Fakturering",
            icon: <ReceiptIcon/>,
            url: "/billing"
        },
        {
            text: "Fordon_Historik",
            icon: <LocalShippingIcon/>,
            url: `/vehiclehistory/${currentCustomer.id}`
        },
        {
            text: "Besökshistorik",
            icon: <CommuteIcon/>,
            url: `/arrivalhistory/${currentCustomer.id}`
        },
        {
            text: "Föranmälan",
            icon: <AppRegistrationIcon/>,
            url: `/preregistration/${currentCustomer.id}`
        },
    ];

    const customerAdminDrawerItems = [
        {
            text: "Undantagshistorik",
            icon: <HistoryIcon/>,
            url: "/exemptionhistory"
        },
        {
            text: "Användare",
            icon: <GroupIcon/>,
            url: `/users/${currentCustomer.id}`
        },
    ];
    
    const reportDrawerItem = [
        {
            text: "Rapporter",
            icon: <ContentPasteIcon/>,
            url: "/reports"
        },
    ];

    const realtimeItem = [
        {
            text: "Realtids Vy",
            icon: <EmojiTransportation/>,
            url: "/realtimeview"
        }        
    ];

    return (
        <CustomDrawerRoot variant="permanent" open={open} hidden={hidden}>
            <DrawerHeader>
            {currentUser && !currentUser.isAdmin && 
            <Typography variant="h6" align="center"> {currentCustomer.name}</Typography>
            }
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon/>
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
            {currentUser.isAdmin && adminUserDrawerItems.map((item) => (
                <CustomDrawerListItem key={item.text} open={open} item={item}/>
            ))}
            <Divider/>
            {currentCustomer && currentCustomer.id >= 0 && currentUser.isAdmin &&
            <CustomDrawerListItem open={open} item={
                {text: currentCustomer.name, icon: <WarehouseIcon/>, url: `/customers/${currentCustomer.id}`}
            }/>
            }
            {currentCustomer && currentCustomer.id >= 0 && currentUser.isAdmin && hiddenCustomerListItems.map((item) => (
                <CustomDrawerListItem key={item.text} open={open} item={item}/>
            ))}
            {currentCustomer && currentCustomer.id >= 0 && customerDrawerItems.map((item) => (
                <CustomDrawerListItem key={item.text} open={open} item={item}/>
            ))}
            {currentCustomer && currentCustomer.id >= 0 && (currentUser.isAdmin || isCustomerAdmin()) && customerAdminDrawerItems.map((item) =>
                <CustomDrawerListItem key={item.text} open={open} item={item}/>
            )}
            {currentCustomer && currentCustomer.id >= 0 && (currentUser.isAdmin || isCustomerAdmin() || hasReportReaderGroup()) && reportDrawerItem.map((item) =>
                <CustomDrawerListItem key={item.text} open={open} item={item}/>
            )}
            </List>
            {currentCustomer && currentCustomer.id >= 0  && hasNonViewerRole() &&
            <List>
                <ListItem key={t("Listor")} disablePadding sx={{display: "block"}}>
                    <ListItemButton onClick={() => setListOpen(!listOpen)}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                        }}>
                        <Tooltip title={t("Listor")}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                    }}>
                                <Summarize/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText 
                            primary={t("Listor")} 
                            sx={{opacity: open ? 1 : 0}}
                        />
                        {listOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                </ListItem>
                <Collapse in={listOpen} timeout="auto" unmountOnExit>
                    <List>
                    {currentCustomer && currentCustomer.id >= 0 && (currentUser.isAdmin || isCustomerAdmin()) && customerAdminListItems.map((item) => (
                        <CustomDrawerListItem key={item.text} open={open} item={item}/>
                    ))}
                    {currentCustomer && currentCustomer.id >= 0 && hasNonViewerRole() && nonViewRoleCustomerListItems.map((item) => (
                        <CustomDrawerListItem key={item.text} open={open} item={item}/>
                    ))}
                    {currentCustomer && currentCustomer.id >= 0 && (currentUser.isAdmin || isCustomerAdmin()) &&
                        hasMarkedItemService() && markedListItems.map((item) => (
                        <CustomDrawerListItem key={item.text} open={open} item={item}/>
                    ))}
                    </List>
                </Collapse>
                <Divider/>
                </List>
                }
                {currentCustomer && currentCustomer.id > 0 && shouldShowRealtimeview() && realtimeItem.map((item) =>(
                <List>
                    <CustomDrawerListItem key={item.text} open={open} item={item}/>
                    </List>
                ))}
            {open &&
            <Box
            sx={{
                ml:2,
                backgroundImage: "url('../Facility_Labs_Logotyp_liggande60.png')",
                backgroundRepeat: "no-repeat",
                height: '75px',
                width: '225px'
              }}
              />
            }
            {!open &&
            <Box
            sx={{
                ml:1,
                backgroundImage: "url('../Logo_only.png')",
                backgroundRepeat: "no-repeat",
                height: '55px',
                width: '55px'
              }}
              />
            }
            </CustomDrawerRoot>
    );
};

export default CustomDrawer;