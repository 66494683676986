import {Customer} from "../types/Customer";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import {Facility} from "../types/Facility";
import { GridSortModel } from "@mui/x-data-grid";
import { ReportMap } from "../types/ReportMap";

export async function fetchCustomer(id: string): Promise<ApiResponseSingle<Customer>> {
    const apiUrl = `/api/customers/${id}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<Customer>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Customer>>;
}

export async function fetchCustomers(
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'name', sort: 'asc'}]
): Promise<ApiResponse<Customer>> {
    const params = [
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/customers?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<Customer>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<Customer>(responseData, 'customers'));
    return response as Promise<ApiResponse<Customer>>;
}

export async function getReportMap(customerId: number): Promise<ApiResponseSingle<ReportMap>> {
    const params = [
        `id=${customerId}`
    ];
    const apiUrl = `/api/customers/search/getReportMap?${params.join('&')}`;

    const options = {
        method: "GET"
    };
    const response = fetchData<ReportMap>(
        apiUrl,
        options
    );
    return response as Promise<ApiResponseSingle<ReportMap>>;
}

export async function createCustomer(customer: Customer): Promise<ApiResponseSingle<Customer>> {
    const apiUrl = `/api/customers`;

    const options = {
        headers: getIdTokenHeader(),
        method: "POST",
        body: JSON.stringify(customer)
    };

    const response = fetchData<Customer>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Customer>>;
}

export async function updateCustomer(id: string, customer: Customer): Promise<ApiResponseSingle<Customer>> {
    const apiUrl = `/api/customers/${id}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'PUT',
        body: JSON.stringify(customer)
    };

    const response = fetchData<Customer>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Customer>>;
}

export async function fetchCustomerFacilities(customerId: string): Promise<ApiResponse<Facility>> {
    const apiUrl = `/api/customers/${customerId}/facilities`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };
    
    const response = fetchData<Facility>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<Facility>(responseData, 'facilities'));
    return response as Promise<ApiResponse<Facility>>;
}

export async function findCustomerByNameLikeAndActiveIs(
    searchQuery: string,
    active: boolean | null,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'name', sort: 'asc'}]
): Promise<ApiResponse<Customer>> {
    const params = [
        `name=${searchQuery}%25`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];

    if (active !== null) {
        params.push(`active=${active}`);
    }

    const apiUrl = `/api/customers/search/findByNameLikeAndActiveIs?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<Customer>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<Customer>(responseData, 'customers'));
    return response as Promise<ApiResponse<Customer>>;
}