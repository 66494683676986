import {enGB, sv} from 'date-fns/locale';


export const getCurrentLanguage = (language : string) => {
    switch(language) {
        case 'en-UK':
        case 'en-US':
        case 'en':
            return 'en';
        default:
            return 'sv';
    }
}

export const getCurrentLocale = (language: string) => {
    switch(language) {
        case 'en-UK':
        case 'en-US':
        case 'en':
            return enGB;
        default:
            return sv;
    }
}