import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import { ExistsInfo } from "../types/ExistsInfo";
import { BillableInfo } from "../types/BillableInfo";
import { PreRegistration } from "../types/PreRegistration";
import {PreRegistrationStatusType} from "../types/enums/PreRegistrationType";
import {GridSortModel} from "@mui/x-data-grid";


export const fetchPreRegistration = async (id: string): Promise<ApiResponseSingle<PreRegistration>> => {
    const apiUrl = `/api/preRegistrations/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<PreRegistration>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PreRegistration>>;
}
  
export const fetchPreRegistrations  = async (
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<PreRegistration>> => {
    const params = [
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/preRegistrations?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<PreRegistration[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<PreRegistration>(responseData, 'preRegistrations')
    );
    return response as Promise<ApiResponse<PreRegistration>>;
}
  
export const findPreRegistrationByCustomerId = async (
    customerid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<PreRegistration>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/preRegistrations/search/findByFacilityCustomerId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<PreRegistration[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<PreRegistration>(responseData, 'preRegistrations')
    );
    return response as Promise<ApiResponse<PreRegistration>>;
}

export const findPreRegistrationByFacilityIdInVehicleOrOwnerLike = async (
    facilityids: string[],
    searchQuery: string, 
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<PreRegistration>> => {
  const params = [
    `facilityids=${facilityids}`,
    `search=${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl =`/api/preRegistrations/search/findByFacilityIdInAndVehicleOrOwnerLike?${params.join('&')}`;

   const options = {
       headers: getIdTokenHeader(),
       method: "GET"
   };
      
  const response = fetchData<PreRegistration[]> (
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<PreRegistration>(responseData, 'preRegistrations')
  );
  return response as Promise<ApiResponse<PreRegistration>>;
}
  
export const findPreRegistrationByFacilityId = async (
    facilityid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
  ) : Promise<ApiResponse<PreRegistration>> => {
    const params = [
      `facilityid=${facilityid}`,
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/preRegistrations/search/findByFacilityIdIn?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<PreRegistration[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<PreRegistration>(responseData, 'preRegistrations')
    );
    return response as Promise<ApiResponse<PreRegistration>>;
  }
  
  export const updatePreRegistration = async (id: string, vehicle: PreRegistration): Promise<ApiResponseSingle<PreRegistration>> => {
    const apiUrl =`/api/preRegistrations/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: 'PATCH',
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<PreRegistration>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PreRegistration>>;
  }

export const handlePreRegistration = async (id: string, status:PreRegistrationStatusType, reference:string, comment:string): Promise<ApiResponseSingle<PreRegistration>> => {
    const apiUrl =`/api/billableVisits/search/handlePreRegistration?id=${id}&status=${status}&reference=${reference}&comment=${comment}`;
    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };
    const response = fetchData<PreRegistration>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PreRegistration>>;
}


export const createPreRegistration = async(preRegistration: PreRegistration): Promise<ApiResponseSingle<PreRegistration>> => {
    const apiUrl = `/api/preRegistrations`;
    const options = {
      headers: getIdTokenHeader(),
      method: "POST",
      body: JSON.stringify({...preRegistration, status: "APPROVED"})
    };
    const response = fetchData<PreRegistration>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PreRegistration>>;
  }
  
  export const isVehicleBillable = async (regnumber: string): Promise<ApiResponseSingle<BillableInfo>> => {
    const apiUrl = `/api/preRegistrations/search/isBillable?regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<BillableInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableInfo>>;
  }
  
  export const preRegistrationExists = async (
    regnumber: string, 
    facilityids: number[],
    fromDate: string,
    toDate: string
  ) : Promise<ApiResponseSingle<ExistsInfo>> => {
    const params = [
      `regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`,
      `facilityids=${facilityids}`,
      `fromDate=${fromDate}`,
      `toDate=${toDate}`
    ];
    const apiUrl = `/api/preRegistrations/search/existsByFacilityRegnumberValidityPeriod?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<ExistsInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExistsInfo>>;
  }
