import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationSwedish from "./translation/translation-sv.json";
import translationEnglish from "./translation/translation-en.json";
import errorTranslationSwedish from "./translation/error-translations-sv.json";
import errorTranslationEnglish from "./translation/error-translations-en.json";

const resources = {
  sv: {
    translation: translationSwedish,
    error: errorTranslationSwedish
  },
  en: {
    translation: translationEnglish,
    error: errorTranslationEnglish
  },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "sv",
});

export default i18next;
