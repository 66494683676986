import {ExemptionType} from "./enums/ExemptionType";

export interface HistoricalExemption {
	id: string,
	regnumberClear: string,
	company: {orgnumber:string, name:string},
	updated: string,
	updatedBy: string,
	reference: string,
	comment: string,
	reason: ExemptionType,
	visitId: string,
	exemptionId: string,
	customerId: number,
	facilityId: number,
	facilityName: {id: number, name:string}
}

export const rowToHistoricalExemption = (data:any) => {
    const {
		id,
		regnumberClear,
		company,
		updated,
		updatedBy,
		comment,
		reference,
		reason,
		visitId,
		exemptionId,
		customerId,
        facilityId,
        facilityName
    } = data;
    return {
		id,
		regnumberClear,
		company,
		updated,
		updatedBy,
		comment,
		reference,
		reason,
		visitId,
		exemptionId,
		customerId,
        facilityId,
		facilityName
    } as HistoricalExemption;
}
