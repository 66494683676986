import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import isEmail from 'validator/lib/isEmail';
import { existsByUsername } from "../../../api/UserAPI";
import { isEmptyArray } from "../../../helpers/IsEmptyHelpers";
import { useAsync } from "react-use";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";

const AddUserModal = ({open, isAdding, addingError, handleClose, handleConfirmChanges}) => {
    const {t} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden);
    const {currentCustomer} = useCurrentCustomer();

    const [infoData, setInfoData] = useState({
    });
    const [formData, setFormData] = useState<{
        username: string,
        fullname: string,
        active: boolean,
        roles: number[],
        customer: string
    }>({
        username: '',
        fullname: '',
        active: true,
        roles: [],
        customer: `/customers/${currentCustomer.id}`
    });

    const [errors, setErrors] = useState({
        username: {value: false, message: ''},
        fullname: {value: false, message: ''}
    });

    const validUsername = (value:string) => {
        return isEmail(value);
    }

    const validateForm = () => {
        const usernameValid = validUsername(formData.username);
        const fullnameValid = formData.fullname.length > 0;

        setErrors({
            username: {value: !usernameValid, message: usernameValid ? '' : 'Ogiltigt_Användarnamn'},
            fullname: {value: !fullnameValid, message: fullnameValid? '' : "Namn_krävs"},
        });

        return usernameValid && fullnameValid;
    };

    const checkExists = useAsync(async () => {
        let exists = false;
        if(validUsername(formData.username)){
            const {data, errors: err} = await existsByUsername(formData.username);
            if(isEmptyArray(err)){
                exists = data.valueOf();
            }
            setErrors({...errors, username:{value: exists, message: exists? "Användare_existerar" : ""}});
        }
        return exists;
    },[formData.username])

    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.id]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
        if(e.target.id === 'username') {
            let valid = validUsername(e.target.value);
            setErrors({...errors,
                username: {value: !valid, message: valid? '' : 'Ogiltigt_Användarnamn'}
            })
        }
        else if(e.target.id === 'fullname') {
            let valid = e.target.value.length > 0;
            setErrors({...errors, 
                fullname: {value: !valid, message: valid? '' : "Namn_får_inte_vara_tom"}})
        }
         else {
            setErrors({...errors,
                username: {value: false, message: ''}
            })
        }
    };


    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();    
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        clearFormData();
        toggleHidden();
    };

    const clearFormData = () => {
        setFormData({
            username: '',
            fullname: '',
            active: true,
            roles: [],
            customer: `/customers/${currentCustomer.id}`
            });

        setInfoData({
        });

        setErrors({
            username: {value: false, message: ''},
            fullname: {value: false, message: ''}
        });
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle sx={{xs:12}} variant="h5">{t("Ny_Användare")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <TextField
                                id="username"
                                name={t("Användarnamn")}
                                label={t("Användarnamn")}
                                size="small"
                                required
                                value={formData.username}
                                onChange={handleInputChange}
                                error={errors.username.value}
                                helperText={errors.username.value ? t(errors.username.message) : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="fullname"
                                name={t("Namn")}
                                label={t("Namn")}
                                size="small"
                                required
                                value={formData.fullname}
                                onChange={handleInputChange}
                                error={errors.fullname.value}
                                helperText={errors.fullname.value ? t(errors.fullname.message) : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                        {addingError?
                            <Typography sx={{color: "red"}}>{t("Gick_inte_att_lägga_till_användare")}</Typography>
                            :
                            <Typography>{t("Ny_användare_hjälp")}</Typography>
                        }
                        </Grid>
                   </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" type="submit" disabled={checkExists.value || addingError} >{t("Lägg till")}</Button>
                    <Button variant="outlined" color="error" onClick={()=>{clearFormData(); handleClose();}}>{t("Avbryt")}</Button>
                </DialogActions>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 20}}
                    open={isAdding}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara Gratis Fordon")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default AddUserModal;