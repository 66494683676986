import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { fetchCustomer } from "../../api/CustomerAPI";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ChangeCustomerModal from "../Facilities/Modals/ChangeCustomerModal";
import AddFacilityModal from "../Facilities/Modals/AddFacilityModal";
import {useEffect, useState} from "react";
import Facilities from "../Facilities/Facilities";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { t } from "i18next";
import {CircularProgress} from "@mui/material";

export default function Customer() {
  let { id } = useParams();
  const [customerData, setCustomerData] = useState({});
  const [customerName, setCustomerName] =  useState("");
  const [addFacility, setAddFacility] =  useState(false);
  const [loading, setLoading] =  useState(true);
  const {currentCustomer, setCurrentCustomer} = useCurrentCustomer();
  const {currentUser} = useCurrentUser();

  useEffect(() => {
    getCustomer();
    setAddFacility(false);
  }, [id, addFacility]);

  const getCustomer = async () => {
    setLoading(true);
    if (id && currentUser && currentUser.isAdmin) {
        const {data} = await fetchCustomer(id);
        setCustomerData(data);
        setCustomerName(data.name);
        setCurrentCustomer({
          id: id,
          name: data.name,
          facilities: data.facilityNames,
          services: data.services,
          productNames: data.productNames,
          customerGroups: data.customerGroups
        });
    }
    else if(currentUser && currentUser.isAuthorized && !currentUser.isAdmin){
      const {data} = await fetchCustomer(currentUser.customerId.toString());
      setCustomerData(data);
      setCustomerName(data.name);
      setCurrentCustomer({
        id: currentUser.customerId,
        name: data.name,
        facilities: data.facilityNames,
        services: data.services,
        productNames: data.productNames,
        customerGroups: data.customerGroups
      });
    }
    setLoading(false);
  }

  function loadCustomer(): void {
    if (id) {
      getCustomer();
    }
  }

  function loadApiFacilities(): void {
    if (id) {
      setAddFacility(true);
    }
  }

  return (
      <>
      {currentCustomer && currentUser && currentCustomer.id > 0 && currentUser.isAuthorized &&  
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
        {!currentUser.isAdmin ? (
          <Box sx={{ marginLeft: "1rem" }}>
            {<h3>{t("Välkommen_Kund", {currentUser})} </h3>}
          </Box>
          ):(
            <>
              {loading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
              ):(
                  <>
                    <WarehouseIcon/>
                    <Box sx={{ marginLeft: "1rem" }}>
                      {customerName && <h2>{customerName}</h2>}
                    </Box>
                  </>
              )}
            </>
          )}
          {currentCustomer && currentUser && currentCustomer.id > 0 && currentUser.isAdmin && !loading &&
          <>
          <Box  sx={{ marginLeft: "1rem" }}>
            <ChangeCustomerModal onSuccess={() => loadCustomer()} customerDetails={customerData}></ChangeCustomerModal>
          </Box>
          <Box  sx={{ marginLeft: "1rem" }}>
            <AddFacilityModal customer={customerData} onSuccess={() => loadApiFacilities()} ></AddFacilityModal>
          </Box>
          </>
          }
        </Box>
        {currentCustomer && currentUser && currentCustomer.id > 0 && currentUser.isAdmin &&  !loading &&
        <Facilities update={addFacility}/>
        }
        </>
      }
      </>
  );
}
