import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {CircularProgress, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import {fetchSingleBillableVisit} from "../../../api/BillableVisitsAPI";
import {BillableVisit} from "../../../types/BillableVisit";
import dayjs from "dayjs";


export function RevisitComponent({onClick, visitData, close}) {
    const {t} = useTranslation();
    let [earlierVisits, setEarlierVisits] = useState<BillableVisit[]>([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        if (visitData && earlierVisits.length === 0) {
            getConnectedVisits();
        }
    }, []);

    const getConnectedVisits = async () => {
        setLoading(true);
        if (visitData.connectedBillableVisits) {
            if (visitData.connectedBillableVisits.length > 0) {
                visitData.connectedBillableVisits.map(async item => {
                    const {data, errors} = await fetchSingleBillableVisit(item);

                    if (data) {
                        earlierVisits.push(...data)
                        earlierVisits = earlierVisits.filter((item, index) => earlierVisits.indexOf(item) === index)
                        setLoading(false)
                    }
                })
            }
        }
    }

    return (
        <>
            <Box>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center">
                    <Typography>
                        {t("Tidigare besök denna gång")}
                    </Typography>
                </Box>
                <br/>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center">
                    {loading && (
                        <Box sx={{display: 'flex'}}>
                            <CircularProgress/>
                        </Box>
                    )}

                    {earlierVisits.map((item, index) => (
                        <Typography>
                            <Link color="inherit">
                                {dayjs(item.vehicleData.sensorTime).format('H:mm') + " (" + (t(item.type)) + ")"}
                            </Link>
                        </Typography>

                    ))}
                    <DialogActions style={{justifyContent: 'center', position: 'absolute', bottom: 20}}>
                        <Button onClick={() => close()} size="large" variant="outlined"
                                color="error">{t("Stäng")}</Button>
                    </DialogActions>
                </Box>
            </Box>
        </>
    )
}