import React, {useState} from "react";
import Button from "@mui/material/Button";
import {TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { useAsync } from "react-use";
import { PricePeriod } from "../../../types/PricePeriod";

const UpdatePricePeriodModal = ({open, handleClose, handleConfirmChanges, data, nextFromMonth}:
    {
        open: boolean,
        handleClose:any,
        handleConfirmChanges:any,
        data: PricePeriod,
        nextFromMonth: string
    }
) => {
    const {t} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [infoData, setInfoData] = useState({});
    const [dirtyForm, setDirtyForm] = useState(false);


    const [formData, setFormData] = useState<{
        facility:string,
        nextFromMonth:string,
        currentPrice:number,
        nextPrice: number
    }>({
        facility: '',
        nextFromMonth: '',
        currentPrice: 0,
        nextPrice: 0
    });

    const [errors, setErrors] = useState({
        nextPrice: {value: false, message: ''}
    });

    const {} = useAsync(async () => {
            setStartData();
    }, [data]);

    const setStartData = () => {
        if(data) {
            formData.facility = data.facilityName ? `/facilities/${data.facilityName.id}` : '';
            formData.nextFromMonth = nextFromMonth;
            formData.currentPrice = Math.floor(data.currentPrice) ?? 0;
            formData.nextPrice = Math.floor(data.nextPrice) ?? 0;
        }
    }
    const validateForm = () => {
        const nextPriceValid = formData.nextPrice >= 0;

        setErrors({
            nextPrice: {value: !nextPriceValid, message: nextPriceValid ? '' : "Ogiltig_avgift"}
        });

        return nextPriceValid
    };

    const handleNextPriceChange = (e) => {
        if(e.target.id === 'nextPrice'){
            setDirtyForm(true);
            let nextPrice;
            if(isNaN(e.target.value) || e.target.value < 0){
                nextPrice = 0;
            }
            else {
                nextPrice = Math.floor(e.target.value);
            }
            setFormData({...formData, nextPrice: nextPrice});
            setInfoData({...infoData, [e.target.name]: e.target.value});
            setErrors({...errors,
                nextPrice: {value: (isNaN(e.target.value) || e.target.value < 0), message: 'Ogiltig_avgift'}
            });
        }
    };

    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        toggleHidden();
        setDirtyForm(false);
    }

    const clearFormData = () => {
        setDirtyForm(false);
        setFormData({
            facility: '',
            nextFromMonth: '',
            currentPrice: 0,
            nextPrice: 0
        });
        setInfoData({
        })

        setErrors({
            nextPrice: {value: false, message: ''}
        });
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle variant="h5">{t("Redigera_Kommande_Avgift")} {t("för")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{data.facilityName ? data.facilityName.name : ''} {t("från")} {nextFromMonth}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                id="nextPrice"
                                name={t("Kommande_avgift")}
                                label={t("Kommande_avgift")}
                                size="small"
                                value={formData.nextPrice}
                                onChange={handleNextPriceChange}
                                fullWidth
                                error={errors.nextPrice.value}
                                helperText={errors.nextPrice.value ? t(errors.nextPrice.message) : 'SEK ' + t("exkl_moms")}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button disabled={!dirtyForm} color="inherit" variant="outlined" type="submit">{t("Uppdatera")}</Button>
                    <Button variant="outlined" color="error" onClick={() => {clearFormData(); handleClose()}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Redigera_kommande_avgift")}
                subtitle={t("Följande uppgifter kommer att ändras") + ' ' + data.facilityName.name}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default UpdatePricePeriodModal;