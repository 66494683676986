import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Autocomplete, Chip, OutlinedTextFieldProps, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { useAsync } from "react-use";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv'
import 'dayjs/locale/en';
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";
import { PreRegistration } from "../../../types/PreRegistration";
import { canBeHandledAfterVisit, canBeHandledBeforeVisit, PreRegistrationStatusType } from "../../../types/enums/PreRegistrationType";

const UpdatePreRegistrationModal = ({open, handleClose, handleConfirmChanges, data, mode='edit'}:
    {
        open: boolean,
        handleClose:any,
        handleConfirmChanges:any,
        data: PreRegistration
        mode?: 'edit' | 'handle'
    }
) => {
    const {currentCustomer} = useCurrentCustomer();
    const {t, i18n} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [infoData, setInfoData] = useState({});
    const [commentCharCount, setCommentCharCount] = useState(0);
    const maxCommentChars = 250;
    const [dirtyForm, setDirtyForm] = useState(false);
    const [facilityValue, setFacilityValue] = useState<{id:number, name:string} | null>(null);
    const [facilityInputValue, setFacilityInputValue] = useState("");
    const [statusInputValue, setStatusInputValue] = useState("");

    const today = dayjs().endOf("day");
    const [fromDate, setFromDate] = useState<dayjs.Dayjs>(today);
    const [toDate, setToDate] = useState<dayjs.Dayjs>(today);
    const [status, setStatus] = useState<PreRegistrationStatusType | null>(PreRegistrationStatusType.UNKNOWN);

    const [formData, setFormData] = useState<{
        regnumberClear:string,
        comment:string,
        facility: string,
        validFrom:any,
        validUntil:any,
        status: PreRegistrationStatusType
    }>({
        regnumberClear: '',
        comment: '',
        facility: '',
        validFrom: null,
        validUntil: null,
        status: PreRegistrationStatusType.UNKNOWN
    });

    const [errors, setErrors] = useState({
        comment: {value: false, message: ''},
        facility: {value: false, message: ''},
        validUntil: {value: false, message: ''},
        status: {value: false, message: ''}
    });

    const {} = useAsync(async () => {
            setStartData();
    }, [data]);

    const setStartData = () => {
        if(data) {
            formData.regnumberClear = data.regnumberClear ?? '';
            formData.comment = data.comment ?? '';
            formData.facility = `/facilities/${data.facilityName.id}`;
            formData.validFrom = dayjs(data.validFrom).format("YYYY-MM-DD HH:mm:ss");
            formData.validUntil = dayjs(data.validUntil).format("YYYY-MM-DD HH:mm:ss");
            formData.status = data.status;

            setFacilityValue(data.facilityName);
            setCommentCharCount(formData.comment.length);
            setFromDate(dayjs(formData.validFrom));
            setToDate(dayjs(formData.validUntil));
            setStatus(formData.status);
        }
    }
    const validateForm = () => {
        const commentValid = formData.comment.length <= maxCommentChars;
        const validUntilValid = formData.validUntil !== 'invalid' || (formData.validUntil && dayjs(formData.validUntil, 'YYYY-MM-DD HH:mm:ss', true).isValid());
        const facilitiesValid = formData.facility && formData.facility !== '';
        const statusValid = formData.status !== null;

        setErrors({
            comment: {value: !commentValid, message: commentValid ? '' : 'För lång'},
            facility: {value: !facilitiesValid, message: facilitiesValid? '' : "Anläggning_krävs"},
            validUntil: {value: !validUntilValid, message: validUntilValid ? '' : "Ogiltigt_datum"},
            status: {value: !statusValid, message: statusValid ? '' : "Status_krävs"}
        });

        return commentValid && validUntilValid && facilitiesValid && statusValid;
    };

    const getMaxUntilTime = () => {
        return fromDate.endOf('day');
    };

    const handleToDateChange = (newValue) => {
        setDirtyForm(true);
        setToDate(newValue);
        setFormData({...formData, validUntil: newValue ? (newValue.isValid() ? newValue.format("YYYY-MM-DD HH:mm:ss") : 'invalid') : null})
        setInfoData({...infoData, [t("Slutdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm') : t("Tillsvidare")});
        const valid = newValue !== null && newValue.isValid() && !newValue.isBefore(fromDate) && !newValue.isAfter(getMaxUntilTime());
        setErrors({...errors,
            validUntil: {value: !valid, message: valid? '' : t("Ogiltigt_datum")}
        });
    }

    const handleFacilitySelectChange = (newValue:{id:number, name:string} | null) => {
        setDirtyForm(true);
        setFacilityValue(newValue);
        if(newValue){
            setInfoData({...infoData, [t("Anläggning")]: newValue.name})
        }
        setFormData({...formData, facility: newValue ? `/facilities/${newValue.id}` : ''});
        setErrors({...errors,
            facility: {value: newValue === null, message: newValue === null ? "Anläggning_krävs" : ''}
        })
    }

    const handleInputChange = (e) => {
        setDirtyForm(true);
        setFormData({...formData, [e.target.id]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
        if(e.target.id === 'comment') {
            setErrors({...errors,
                comment: {value: (e.target.value.length > maxCommentChars), message: 'Kommentar får inte vara för lång'}
            });
            setCommentCharCount(e.target.value.length);
        }
    };

    const handleStatusChange = (newValue: PreRegistrationStatusType | null) => {
        setDirtyForm(true);
        setStatus(newValue);
        setFormData({...formData, status: newValue?? PreRegistrationStatusType.UNKNOWN});
        setInfoData({...infoData, [t("Status")]: newValue})
        setErrors({...errors,
            status: {value: newValue === null, message: newValue === null ? "Status_krävs" : ""}
        })
    };

    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        toggleHidden();
        setDirtyForm(false);
    }

    const clearFormData = () => {
        setDirtyForm(false);
        setFormData({
        regnumberClear: '',
        comment: '',
        facility: '',
        validFrom: null,
        validUntil: null,
        status: PreRegistrationStatusType.UNKNOWN
        });
        setInfoData({})
        setCommentCharCount(0);
        setFromDate(today);
        setToDate(today);

        setErrors({
            comment: {value: false, message: ''},
            facility: {value: false, message: ''},
            validUntil: {value: false, message: ''},
            status: {value: false, message: ''}
        });
    };

    const checkDisabled = () => {
        return !dirtyForm || !facilityValue || errors.validUntil.value || !status;
    };


    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle variant="h5">{t("Redigera_Föranmälan")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t("Fordon")}: {data.regnumberClear}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t("Företagsnamn")}: {data.ownerName}</Typography>
                    </Grid>
                    {mode === 'handle' && (
                    <>
                    <Grid item xs= {12}>
                        <Typography variant="subtitle1">{t("Besökte_vid", {visitTime: dayjs(data.visitTime).format('YYYY-MM-DD HH:mm')})}</Typography>
                    </Grid>
                    </>
                    )}
                    {mode === 'handle' && (
                    <Grid item xs={12}>
                        <Autocomplete
                            selectOnFocus
                            clearOnBlur
                            size="small"
                            id="status-select"
                            limitTags={2}
                            options={mode === 'handle' ? canBeHandledAfterVisit: canBeHandledBeforeVisit}
                            getOptionLabel={(option) => t(option)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={t(option)}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                                ))
                            }
                            value={status}
                            onChange={(e, newValue) => handleStatusChange(newValue)}
                            inputValue={statusInputValue}
                            isOptionEqualToValue={(option, value) => option === value}
                            onInputChange={(e, newInputValue) => setStatusInputValue(newInputValue)}
                            renderInput={(params) => (
                                <TextField 
                                    {...params as unknown as OutlinedTextFieldProps} 
                                    variant="outlined"
                                    error={errors.status.value}
                                    label={t("Välj_Status")}
                                    helperText={errors.status.value ? t(errors.status.message) : ''}
                                />
                            )}
                            sx={{
                                background: "#FFF",
                                borderRadius: "4px",
                            }}
                        />
                    </Grid>
                    )}
                    {mode === 'edit' && (
                    <>
                    <Grid item xs={12}>
                        {currentCustomer.facilities.length > 1 &&
                        <Autocomplete
                            selectOnFocus
                            clearOnBlur
                            size="small"
                            id="facility-select"
                            limitTags={2}
                            options={currentCustomer.facilities}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value, getTagProps) => value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option.name}
                                    size="small"
                                    {...getTagProps({ index })} />
                            ))}
                            value={facilityValue}
                            onChange={(e, newValue) => handleFacilitySelectChange(newValue)}
                            inputValue={facilityInputValue}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onInputChange={(e, newInputValue) => setFacilityInputValue(newInputValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params as unknown as OutlinedTextFieldProps}
                                    variant="outlined"
                                    error={errors.facility.value}
                                    label={t("Välj_Anläggning")}
                                    helperText={errors.facility.value ? t(errors.facility.message) : ''}
                                />
                            )}
                            sx={{
                                background: "#FFF",
                                borderRadius: "4px",
                            }} 
                        />
                        }
                        {currentCustomer.facilities.length === 1 &&
                        <Typography variant="subtitle1">{t("Anläggning")}: {currentCustomer.facilities[0].name}</Typography>}
                    </Grid>
                    </>
                )}
                    <Grid item xs={12}>
                        <TextField
                            id="comment"
                            name={t("Kommentar")}
                            label={t("Kommentar")}
                            size="small"
                            value={formData.comment}
                            onChange={handleInputChange}
                            fullWidth
                            error={errors.comment.value}
                            helperText={commentCharCount + '/' + maxCommentChars + ' ' + (errors.comment.value ? t(errors.comment.message) : '')}
                            autoComplete="off"
                            multiline
                            rows={4}
                        />
                    </Grid>
                {mode === 'edit' && (
                    <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                        <Grid item xs={6}>
                            <DateTimePicker
                                name={t("Från")}
                                label={t("Från")}
                                readOnly
                                views={['day','hours', 'minutes']}
                                timeSteps={{minutes: 15}}
                                ampm={false}
                                value={fromDate}
                                format="YYYY-MM-DD HH:mm" 
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimePicker
                                name={t("Till")}
                                label={t("Till")}
                                readOnly={fromDate.isBefore(dayjs())}
                                value={toDate}
                                format="YYYY-MM-DD HH:mm"
                                onChange={handleToDateChange}
                                ampm={false}
                                timeSteps={{minutes: 15}}
                                views={['day','hours', 'minutes']}
                                minDateTime={fromDate}
                                maxDateTime={getMaxUntilTime()}
                                slotProps={{
                                    textField: {
                                        helperText: errors.validUntil.value ? t("Ogiltigt_datum") : ''
                                    },
                                    field: {
                                        clearable: false
                                    }
                                }}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12}>
                        <Typography>{t("Uppdatering_Föranmälan_Förklaring")}</Typography>
                    </Grid>
                    </>
                )}
                {mode === 'handle' && (
                    <></>
                )}
                </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button disabled={checkDisabled()} color="inherit" variant="outlined" type="submit">{t("Uppdatera")}</Button>
                    <Button variant="outlined" color="error" onClick={() => {clearFormData(); handleClose()}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara_Föranmälan")}
                subtitle={t("Följande uppgifter kommer att ändras")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default UpdatePreRegistrationModal;