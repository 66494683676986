import React, {useState} from "react";
import {TextField, InputAdornment, useTheme, TextFieldVariants} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

interface SearchBarProps {
    label: string;
    size?: "small" | "medium";
    hideLabel?:boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchQuery: string;
    helperText?: string;
    autoFocus?: boolean;
    minWidth?: number;
}

const SearchBar: React.FC<SearchBarProps> = ({label, hideLabel=false, size = "medium", onChange, searchQuery, helperText, autoFocus = false, minWidth = 270}) => {
    const theme = useTheme();
    const [shrink, setShrink] = useState(false);
    const handleClearSearch = () => {
        onChange({target: {value: ""}} as React.ChangeEvent<HTMLInputElement>);
        setShrink(false)
    };

    return (

        <TextField
            id="input-with-icon-textfield"
            label={hideLabel ? (searchQuery !== "" ? "" : label) : label}
            variant="outlined"
            size={size}
            onChange={onChange}
            value={searchQuery}
            autoComplete="off"
            spellCheck="false"
            helperText={helperText}
            autoFocus={autoFocus}
            fullWidth
            onFocus={() => setShrink(!hideLabel)}
            onBlur={(e) => setShrink(!!e.target.value && !hideLabel)}
            InputLabelProps={{sx: {ml: 4}, shrink}}
            sx={() => ({
                "& .MuiOutlinedInput-notchedOutline": {
                    px: 5
                }
            })}
            InputProps={{
                sx: {
                    background: theme.palette.common.white,
                    minWidth: {xs: minWidth},
                    borderRadius: "4px",
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment
                        position="end"
                        onClick={handleClearSearch}
                        sx={{cursor: "pointer"}}
                    >
                        {searchQuery && <CloseIcon/>}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchBar;
