import React from "react";
import {emphasize, styled} from "@mui/material/styles";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Link as RouterLink} from "react-router-dom";
import SignInSignOutButton from "./SignInSignOutButton/SignInSignOutButton";
import {useTheme} from "@mui/material";
import {useIsAuthenticated} from "@azure/msal-react";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;

interface StyledAppBarProps extends MuiAppBarProps {
    open?: boolean;
    hidden?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "hidden",
})<StyledAppBarProps>(({theme, open, hidden}) => ({
    background: emphasize(theme.palette.background.default),
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    display: hidden ? "none" : "block",
}));

interface CustomAppBarProps extends MuiAppBarProps {
    open?: boolean;
    hidden?: boolean;
    handleDrawerOpen: () => void;
}

const CustomAppBar: React.FC<CustomAppBarProps> = ({open, hidden, handleDrawerOpen}) => {
    const theme = useTheme();
    const {i18n, t} = useTranslation();
    const isAuthenticated = useIsAuthenticated();


    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    return (
        <StyledAppBar position="fixed" open={open} hidden={hidden}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{marginRight: theme.spacing(5), ...((open || !isAuthenticated) && {display: "none"})}}>
                    <MenuIcon/>
                </IconButton>
                <Link
                    variant="h6"
                    component={RouterLink}
                    noWrap
                    to="/"
                    sx={{color: "black", textDecoration: "none"}}>
                    {t("Facility_Labs_Start_Link")}
                </Link>
                <Typography
                    component="div"
                    sx={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                        gridGap: theme.spacing(1),
                    }}>
                    {process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'test' &&
                    <Typography variant='h5' sx={{color: "red", textDecoration: "bold"}}>TESTMILJÖ</Typography>
                    }
                <Typography
                        sx={{display: {xs: "none", sm: "inline"}}}
                        component="span"
                        variant="subtitle2">
                        <Select
                            renderValue={() => t("Ändra_Språk")}
                            value={i18n.language}
                            sx={{
                                fontSize: "inherit",
                                fontWeight: "inherit",
                            }}
                            disableUnderline={true}
                            variant="standard">
                            <MenuItem value="svSE" onClick={() => handleChange("svSE")}>
                                {t("Svenska")}
                            </MenuItem>
                            <MenuItem value="en" onClick={() => handleChange("en")}>
                                {t("Engelska")}
                            </MenuItem>
                        </Select>
                    </Typography>
                    <SignInSignOutButton/>
                </Typography>
            </Toolbar>
        </StyledAppBar>
    );
};

export default CustomAppBar;
