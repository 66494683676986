import { ArrivalHistoryResponse } from "../types/ArrivalHistoryReponse";
import { ApiResponseSingle, fetchData, getIdTokenHeader } from "./apiUtils";

export async function fetchArrivalHistory(fromDate: string, toDate: string, accumulatiorPeriod: string, facilityIds?: string[] | null): Promise<ApiResponseSingle<ArrivalHistoryResponse>> {
    const apiUrl = `/api/carhistory?fromDate=${fromDate}&toDate=${toDate}&accumulatorPeriod=${accumulatiorPeriod}&facilityIds=${facilityIds? facilityIds : ''}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<ArrivalHistoryResponse>(
        apiUrl,
        options
    );

    return response as Promise<ApiResponseSingle<ArrivalHistoryResponse>>;
}
