import {ServiceType} from "./enums/ServiceType";

export interface Service {
    id: string,
    active: any,
    startDate: any,
    endDate: any,
    type: ServiceType,
}

export const toService = (data: any) => {
    const {
        active,
        startDate,
        endDate,
        type,
        _links: {
            self: {
                href,
            }
        }
    } = data;
    const id = href.slice(href.lastIndexOf('/') + 1);
    return {
        id,
        active,
        startDate,
        endDate,
        type,
    } as Service;
}