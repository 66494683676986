import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomSwitch} from "../Shared/CustomSwitch";


const mobilePhoneStyle = {
    padding: '10px',
    backgroundColor: '#f0f0f0',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    marginTop: '30px',
    overflow: "auto",
    '&::-webkit-scrollbar': {display: 'none'},
    border: '2px solid #000',
    borderRadius: 3,
    width:'95%',
    height:'90%'
};


export function SettingsComponent({onSave,data}) {
    const [viewData, setViewData] = useState<any[]>(data);
    const [changeData, setChangeData] = useState<any[]>([]);
    const [dataChange, setDataChange] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if(dataChange){
            setViewData(changeData)
            //call api to change settings
            setDataChange(false);
            setChangeData([])
        }
    }, [dataChange,viewData]);

    useEffect(() => {
        if(!loaded){
            setViewData(data)
            setLoaded(true)
        }

    }, [data]);

    const onChange = (event:any) => {
        const output = event.target.value;
        const checked = event.target.checked;
        const showOrNotify = event.target.name;

        let temp = viewData;
        let objIndex = temp.findIndex(obj => obj.name === output);
        temp[objIndex][showOrNotify] = checked;

        setChangeData(temp)
        onSave(temp);
        setDataChange(true)
    }

    return (
            <Box sx={mobilePhoneStyle}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>
                                {viewData && viewData?.map((row) => (
                                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0,marginBottom:1 } }}>
                                        <TableCell component="th" scope="row" sx={{maxWidth:100,minWidth:10}}>
                                            {t(row.name)}
                                        </TableCell>
                                        {row.show !== undefined && row.notify !== undefined ?(
                                            <TableCell component="th" scope="row" sx={{borderRight: 2,maxWidth:5}} />
                                            ): (
                                            <TableCell component="th" scope="row" sx={{borderRight: 0 }} />
                                             )
                                        }
                                        <TableCell align="right">
                                            {row.show !== undefined &&(
                                                <CustomSwitch onChange={e => onChange(e)} data={{name:"show", value:row.name, checked:row.show}}></CustomSwitch>
                                            )}
                                            {row.notify !== undefined &&(
                                                <CustomSwitch onChange={e => onChange(e)} data={{name:"notify", value:row.name, checked:row.notify}}></CustomSwitch>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
            </Box>
    )
}