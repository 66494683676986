import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Checkbox, FormControlLabel, Stack, TextField, useTheme} from "@mui/material";
import {toBillableVisitID} from "../../../types/BillableVisit";
import Grid from "@mui/material/Grid";
import CommentIcon from "@mui/icons-material/Comment";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";
import {
    canBeHandledAfterVisit,
    canBeHandledBeforeVisit,
    PreRegistrationStatusType
} from "../../../types/enums/PreRegistrationType";
import {handlePreRegistration} from "../../../api/PreRegistrationAPI";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import Typography from "@mui/material/Typography";
import { nonViewerRoles } from "../../../types/CurrentUserRoles";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";

export function PreRegistredComponent({visitData, exempt, close}) {
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        comment: '',
        reference:'',
    });
    const [disabled, setDisabled] = React.useState(true);
    const [canEdit, setCanEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [reviewed, setReviewed] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [visitId, setVisitId] = React.useState("");
    const {currentUser} = useCurrentUser();
    const theme = useTheme();

    useEffect(() => {
        if (visitData) {
            let id = toBillableVisitID(visitData);
            setVisitId(id)
            setChecked(visitData.foreignHandled)
            setReviewed([PreRegistrationStatusType.APPROVED_AFTER_REVIEW, PreRegistrationStatusType.INVOICE_AFTER_REVIEW, PreRegistrationStatusType.APPROVED].includes(visitData.preRegistration.status))
            setCanEdit(canBeHandledAfterVisit.includes(visitData.preRegistration.status) || canBeHandledBeforeVisit.includes(visitData.preRegistration.status))
            setFormData({
                ...formData,
                ["comment"]: (visitData.comment !== undefined) ? visitData.comment : "",
                ["reference"]: (visitData.reference !== undefined) ? visitData.reference : ""
            });
        }
    }, [visitData]);

    useEffect(() => {
        if (compareData("comment") || compareData("reference")) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [formData]);

    const compareData = (field: string) => {
        return !!(formData[field] && formData[field] !== visitData[field]) || !!(!formData[field] && visitData[field])
    }


    const saveVisitComment = async () => {
        if (visitId && formData.comment !== undefined) {
            setLoading(true)
            try {
                const result =  await handlePreRegistration(visitId,visitData.preRegistration.status,formData.reference, formData.comment );
                setLoading(false);
                if (!isEmptyArray(result.errors)) {
                    throw new Error("Kunde inte uppdatera");
                }
                visitData = {...result.data}
                return true;
            } catch (error: any) {
                return null;
            }
        }

        return true;
    }

    const saveVisitReference = async () => {
        if (visitId && formData.reference !== undefined) {
            setLoading(true)
            try {
                const result  = await handlePreRegistration(visitId,visitData.preRegistration.status,formData.reference,formData.comment  );
                setLoading(false);
                if (!isEmptyArray(result.errors)) {
                    throw new Error("Kunde inte uppdatera");
                }

                visitData = {...result.data}
                return true;
            } catch (error: any) {
                return null;
            }

        }

        return true;
    }

    const saveVisit = async () => {
        if (await saveVisitComment() && await saveVisitReference()) {
            close(visitData);
        }
    }

    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.id]: e.target.value});
    };

    const reInstateInvoice = async() => {
        if (visitId) {
            const {data, errors} = await handlePreRegistration(visitId,PreRegistrationStatusType.INVOICE_AFTER_REVIEW,formData.reference,formData.comment  );
            if (data) {
                close(data);
            }
        }
    }

    const removeInvoice = async() => {
        if (visitId) {
            const {data, errors} = await handlePreRegistration(visitId,PreRegistrationStatusType.APPROVED,formData.reference,formData.comment  );
            if (data) {
                close(data);
            }
        }
    }

    const reviewVisit = async() => {
        if (visitId) {
            const {data, errors} = await handlePreRegistration(visitId,setStatusBasedOnPreRegistration(),formData.reference,formData.comment  );
            if (data) {
                close(data);
            }
        }
    }

    function setStatusBasedOnPreRegistration():PreRegistrationStatusType {
        if(visitData.preRegistration) {
            switch (visitData.preRegistration.status) {
                case PreRegistrationStatusType.APPROVED:
                    return PreRegistrationStatusType.TO_REVIEW

                case PreRegistrationStatusType.TO_REVIEW:
                    return PreRegistrationStatusType.APPROVED
                case PreRegistrationStatusType.VISITED_WITHOUT_REVIEW:
                    return PreRegistrationStatusType.APPROVED_AFTER_REVIEW
                case PreRegistrationStatusType.APPROVED_AFTER_REVIEW:
                    return PreRegistrationStatusType.VISITED_WITHOUT_REVIEW
                case PreRegistrationStatusType.INVOICE_AFTER_REVIEW:
                    return PreRegistrationStatusType.VISITED_WITHOUT_REVIEW
                default:
                    return visitData.preRegistration.status
            }
        }
        return visitData.preRegistration.status
    }

    const hasNonViewerRole = () => {
        return currentUser && currentUser.isAuthorized && nonViewerRoles.some(r => currentUser.roles.some(cur => cur.id === r.id));
    }

return (
        <>
            <Box>
                <br/>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Grid container spacing={1}>
                        <Grid style={{justifyContent: 'center'}} container >

                                {((visitData.preRegistration.status === PreRegistrationStatusType.APPROVED) || (visitData.preRegistration.status === PreRegistrationStatusType.TO_REVIEW)) &&
                                <Typography variant="caption">
                                    {t("Föranmäld för passage")}
                                </Typography>
                                }
                        </Grid>
                        <Grid style={{justifyContent: 'center'}} container >

                            {((visitData.preRegistration.status === PreRegistrationStatusType.APPROVED)) &&
                                <Typography variant="caption">
                                    {t("Godkänd av")} {visitData.preRegistration.registeredBy}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Stack alignItems="center" direction="row" gap={2}>
                                <CommentIcon/>
                                <TextField
                                    id="comment"
                                    fullWidth
                                    label={t("Kommentar")}
                                    value={formData.comment}
                                    onChange={handleInputChange}
                                    InputLabelProps={{shrink: true, spellCheck: 'false'}}
                                    multiline
                                />
                            </Stack>
                        </Grid>
                        {visitData.preRegistration.status === PreRegistrationStatusType.INVOICE_AFTER_REVIEW &&
                            <Grid item xs={12}>
                                <Stack alignItems="center" direction="row" gap={2}>
                                    <AddToHomeScreenIcon/>
                                    <TextField
                                        id="reference"
                                        fullWidth
                                        label="Referens"
                                        size="small"
                                        value={formData.reference}
                                        onChange={handleInputChange}
                                        InputLabelProps={{shrink: true, spellCheck: 'false'}}
                                    />
                                </Stack>
                            </Grid>
                        }

                        <Grid style={{justifyContent: 'center'}} container xs={12}>
                        </Grid>
                    </Grid>
                </Box>
                <DialogActions style={{justifyContent: 'center'}}>
                    <Button disabled={disabled} variant="contained" color="inherit" onClick={saveVisit} size="large"
                            type="submit">{t("Spara")}</Button>
                    <Button size="large" onClick={() => close()} variant="outlined" color="error">{t("Avbryt")}</Button>
                </DialogActions>
                {hasNonViewerRole() && (
                <DialogActions style={{justifyContent: 'center'}}>
                    {visitData.preRegistration.status === PreRegistrationStatusType.INVOICE_AFTER_REVIEW && (
                        <Button disabled={!canEdit} onClick={removeInvoice} variant="contained" type="submit" color='inherit' sx={{backgroundColor: theme.palette.common.white}}>{t("Ska ej faktureras")}</Button>
                    )}

                    {visitData.preRegistration.status === PreRegistrationStatusType.APPROVED && (
                        <Button disabled={!canEdit}  onClick={reInstateInvoice} variant="contained" color='warning' type="submit" >{t("Ska faktureras")}</Button>
                    )}
                </DialogActions>
                )}
            </Box>

        </>

    )
}