export interface UserRole {
    id:number,
    name:string
}

export enum CurrentUserRoles {
    ADMIN = "ADMIN",
    CUSTOMERADMIN = "CUSTOMERADMIN",
    USER = "USER",
    VIEWER = "VIEWER"
}

export const flAdminRole: UserRole = {id:1, name: CurrentUserRoles.ADMIN};
export const customerAdminRole: UserRole = {id:3, name: CurrentUserRoles.CUSTOMERADMIN};
export const userRole: UserRole = {id:2, name: CurrentUserRoles.USER};
export const viewerRole: UserRole = {id:4, name: CurrentUserRoles.VIEWER};

export const allAdminUserRoles: UserRole[] = [
    flAdminRole, customerAdminRole
]

export const allAuthorizedUserRoles: UserRole[] = [
    flAdminRole, customerAdminRole, userRole, viewerRole
]

export const nonViewerRoles: UserRole[] = [
    flAdminRole, customerAdminRole, userRole
]

export const nonAdminRoles: UserRole[] = [
    userRole, viewerRole
]