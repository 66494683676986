export interface ExemptVehicle {
    id: string,
    regnumberClear: string,
    vehicleOwner: string,
    ownerName: string,
    ownerChanged: string,
    customerId: string,
    facilityNames: {id:number, name:string}[],
    comment: string,
    validFrom: any,
    validUntil: any,
    createdBy: string,
    updatedBy: string,
    created: any,
    updated: any,
    clientRef: string
}

export const rowToExemptVehicle = (data:any) => {
    const {
        id,
        regnumberClear,
        ownerName,
        facilityNames,
        validFrom,
        validUntil,
        comment
    } = data;
    return {
        id,
        regnumberClear,
        ownerName,
        facilityNames,
        validFrom,
        validUntil,
        comment
    } as ExemptVehicle;
}