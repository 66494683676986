import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import FacilityAutocomplete from "../../Shared/FacilityAutocomplete";
import { useTranslation } from "react-i18next";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";
import { useEffect, useState } from "react";
import { Facility } from "../../../types/Facility";
import { BillableVisit } from "../../../types/BillableVisit";
import { fetchBillableVisitsBySearchCriteria } from "../../../api/BillableVisitsAPI";
import { isEmptyArray } from "../../../helpers/IsEmptyHelpers";
import {ErrorResponse} from "../../../api/apiUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import i18n from "../../../i18n";
import dayjs from "dayjs";
import VehicleHistoryModal from "../../VehicleHistory/Modals/VehicleHistoryModal"
import { formatOwner } from "../../../helpers/VisitFormatters";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {TableSettings, TableStorage} from "../../../types/settings/TableSettings";

export default function VisitHistoryModal ({open, handleClose, regNumber, periodDays=30}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "timeStamp", sort: 'desc'}]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [vehicleVisitItems, setVehicleVisitItems] = useState<BillableVisit[]>([]);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const today = dayjs().endOf('day');
    const firstDayOfMonth = dayjs().startOf('month').startOf('day');
    const minDate = dayjs().subtract(3, 'years');
    const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(today.subtract(periodDays, 'days').startOf('day'));
    const [toDate, setToDate] = useState<dayjs.Dayjs | null>(today);
    const [showVisitHistoryModal] = useState(true);
    const [showingCurrentMonth, setShowingCurrentMonth] = useState(true);


    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        setFacilitiesFilterValue(facilities)
    };

    const handleChangeFromDate = (newValue) => {
        setFromDate(newValue? newValue : today.subtract(periodDays, 'days').startOf('day'));
        checkShowingCurrentMonth();
    }

    const handleChangeToDate = (newValue) => {
        setToDate(newValue? newValue : today );
        checkShowingCurrentMonth();
    }

    const checkShowingCurrentMonth = () => {
        if ((fromDate != null && fromDate.isBefore(firstDayOfMonth)) && 
            (toDate != null && toDate.isBefore(firstDayOfMonth))) {
            setShowingCurrentMonth(false);
        }
        else {
            setShowingCurrentMonth(true)
        }
    };

    const isRegnumberSearchable = ():boolean => {
        return regNumber ? regNumber.length > 5 : false;
    }

    useEffect(() => {
        if(isRegnumberSearchable()){
            fetchData();
        }
    },[regNumber, facilitiesFilterValue, paginationModel, sortModel, fromDate, toDate, showingCurrentMonth]);

    
    const fetchData = async() => {
        setLoading(true);
        try {
            let result;
            if(regNumber || facilitiesFilterValue) {
                result = await fetchBillableVisitsBySearchCriteria(
                    paginationModel.pageSize,
                    paginationModel.page,
                    sortModel,
                    currentCustomer.id,
                    encodeURIComponent(regNumber),
                    facilitiesFilterValue.map((facility) => facility.id),
                    fromDate? fromDate.startOf('day').valueOf(): undefined,
                    toDate? toDate.endOf('day').valueOf() : undefined,
                    undefined,
                    undefined,
                    showingCurrentMonth ? 'billableVisits' : 'previousVisits'
                );
            }
            else {
                result = {data: [], errors:[], page:{totalElements: 0, size: 10, number: 0, totalPages: 0}};
            }
            const {data, errors, page} = result;

            setErrors(errors);
            setVehicleVisitItems([...data]);
            page && setTotalElements(page.totalElements);
        }
        finally {
            setLoading(false);
        }
    };

    const handleChangeCurrentMonth = (value:boolean) => {
        setShowingCurrentMonth(value);
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleChangeShowingCurrentMonth = (e:React.ChangeEvent<HTMLInputElement>) => {
        setShowingCurrentMonth(e.target.checked)
    };

    const calculateDisplayPeriod = () => {
        if(showingCurrentMonth){
            return `${firstDayOfMonth.format("YYYY-MM-DD")} - ${today.format('YYYY-MM-DD')}`;
        }
        else if(toDate?.isBefore(firstDayOfMonth)) {
            return `${fromDate?.format("YYYY-MM-DD")} - ${toDate.format("YYYY-MM-DD")}`;
        }
        else {
            return `${fromDate?.format("YYYY-MM-DD")} - ${firstDayOfMonth.subtract(1, 'day').format("YYYY-MM-DD")}`;
        }
    };


    return (
        <>
        <Dialog
            maxWidth="lg"
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {minwidth: '30vw'}
            }}
        >
            <DialogTitle align="center" variant="h5">{t("Besökshistorik")}</DialogTitle>
            <DialogContent sx={{minWidth: '30vw', minHeight: '20vh'}}>
                <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                    <Grid item xs={6}>
                        <Typography align="center" variant="h6">{t("För_perioden")} {calculateDisplayPeriod()}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch
                                checked={showingCurrentMonth}
                                disabled={fromDate?.isSame(toDate, 'month') || (!fromDate?.isSame(today, 'month') && !toDate?.isSame(today, 'month'))}
                                onChange={handleChangeShowingCurrentMonth}
                            />} label={t("Visa_innevarande_månad")}
                        />
                    </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align="center" variant="h6">{t("Fordon")}:</Typography><Typography align="center" variant="h6">{!isEmptyArray(vehicleVisitItems) && vehicleVisitItems[0].vehicleData.regnumberClear}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align="center" variant="h6">{t("Företagsnamn")}:</Typography><Typography align="center" variant="h6">{!isEmptyArray(vehicleVisitItems) && 
                            formatOwner(vehicleVisitItems[0].vehicleData)}</Typography>
                    </Grid>

                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                        <Grid item xs="auto">
                                <DatePicker
                                    label={t("Startdatum")}
                                    onChange={handleChangeFromDate}
                                    minDate={minDate}
                                    maxDate={today}
                                    value={fromDate}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            InputProps : {
                                                readOnly: true
                                            },
                                            sx: {
                                                background: theme.palette.common.white,
                                                minWidth: {xs: 170},
                                                borderRadius: "4px",
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <DatePicker
                                    label={t("Slutdatum")}
                                    value={toDate}
                                    format="YYYY-MM-DD"
                                    onChange={handleChangeToDate}
                                    minDate={fromDate? fromDate : minDate}
                                    maxDate={today}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                                readOnly: true
                                            },
                                            sx: {
                                                background: theme.palette.common.white,
                                                minWidth: {xs: 170},
                                                borderRadius: "4px",
                                            },
                            
                                        },
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item sx={{ marginLeft: { xs: 0, md: "auto"}, pt:1}}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggningar")}
                                helperText={t("Alla_Anläggningar")}
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                disabled={!isRegnumberSearchable()}
                                onFacilitiesFilterChange={handleFilterFacilitiesChange}
                            />
                        </Grid>
                    </Grid>
                <VehicleHistoryModal
                    open={open}
                    handleClose={handleClose}
                    vehicleVisitItems={vehicleVisitItems}
                    loading={loading}
                    onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                    totalElements={totalElements}
                    fromDate={fromDate}
                    toDate={toDate}
                    paginationModel={paginationModel}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    showingCurrentMonth={showingCurrentMonth}
                    onChangeShowingCurrentMonth={handleChangeCurrentMonth}
                />
                {!showVisitHistoryModal && isRegnumberSearchable() && (
                <Box sx={{maxHeight: '70%'}}>
                {!isEmptyArray(errors) && errors.map((error, index) => (
                    <Typography key={index} variant="body1" style={{color: 'red'}}>
                        {error.message}
                    </Typography>
                    ))
                }
                {isRegnumberSearchable() && vehicleVisitItems.length < 1 && (
                    <Typography variant="subtitle1" style={{color: 'initial'}}>
                        {t("Hittade_inte_besök_för_vald_tidsperiod")}
                    </Typography>
                )}
                </Box>
                )}     
       </DialogContent>
       <DialogActions sx={{m: 1}}>
            <Button variant="outlined" color="error" onClick={() => {handleClose()}}>{t("Stäng")}</Button>
        </DialogActions>
       </Dialog>
       </>
    );
}