import { GridSortModel } from "@mui/x-data-grid";
import {PricePeriod} from "../types/PricePeriod"
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import { ExistsInfo } from "../types/ExistsInfo";
import { BillableInfo } from "../types/BillableInfo";
import { PreRegistration } from "../types/PreRegistration";


export async function fetchPricePeriod(id: string): Promise<ApiResponseSingle<PricePeriod>> {
    const apiUrl = `/api/pricePeriods/${id}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<PricePeriod>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PricePeriod>>;
}

export async function updatePricePeriod(id: string, pricePeriod: PricePeriod): Promise<ApiResponseSingle<PricePeriod>> {
    const apiUrl = `/api/pricePeriods/${id}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'PATCH',
        body: JSON.stringify(pricePeriod)
    };

    const response = fetchData<PricePeriod>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PricePeriod>>;
}

export async function updateNextPricePeriodByFacility(facilityId: string, nextPrice: number, nextFromMonth:string): Promise<ApiResponseSingle<PricePeriod>> {

    const params = [
        `facilityId=${facilityId}`,
        `nextPrice=${nextPrice}`,
        `nextFromMonth=${nextFromMonth}`
    ];

    const apiUrl = `/api/pricePeriods/search/updateNextPricePeriodByFacility?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET'
    };

    const response = fetchData<PricePeriod>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PricePeriod>>;
}


export async function findPricePeriodByFacilityId(
    facilityId: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'fromMonth', sort: 'desc'}]
): Promise<ApiResponse<PricePeriod>> {
    const params = [
        `facilityid=${facilityId}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];

    const apiUrl = `/api/pricePeriods/search/findByFacilityId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<PricePeriod>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<PricePeriod>(responseData, 'pricePeriods'));
    return response as Promise<ApiResponse<PricePeriod>>;
}
  
export const findPricePeriodByCustomerId = async (
    customerid: string,
    page: number = 0,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'fromMonth', sort: 'desc'}]
) : Promise<ApiResponse<PricePeriod>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/pricePeriods/search/findByFacilityCustomerId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<PricePeriod[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<PricePeriod>(responseData, 'pricePeriods')
    );
    return response as Promise<ApiResponse<PricePeriod>>;
}

export const deletePricePeriod = async(id: string) : Promise<ApiResponseSingle<PricePeriod>> => {
    const apiUrl = `/api/pricePeriods/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: 'DELETE'
    };
    const response = fetchData<PricePeriod>(apiUrl, options);
    return response as Promise<ApiResponseSingle<PricePeriod>>;
  }