export interface ExemptOrganization {
    id: string,
    orgnumber: string,
    parentOrgnumber: string,
    name: string,
    customerId: string,
    facilityNames: {id:number, name:string}[],
    comment: string,
    validFrom: any,
    validUntil: any,
    createdBy: string,
    updatedBy: string,
    created: any,
    updated: any,
    clientRef: string
}

export const rowToExemptOrganization = (data:any) => {
    const {
        id,
        orgnumber,
        name,
        parentOrgnumber,
        facilityNames,
        validFrom,
        validUntil,
        comment
    } = data;
    return {
        id,
        orgnumber,
        name,
        parentOrgnumber,
        facilityNames,
        validFrom,
        validUntil,
        comment
    } as ExemptOrganization;
}
