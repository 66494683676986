import React, {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {Chip, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { DatePicker, DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import Autocomplete from "@mui/material/Autocomplete";
import { OutlinedTextFieldProps, Typography } from "@mui/material";
import { exemptOrganizationExists, getOwnerInfo } from "../../../api/ExemptOrganizationAPI";
import { OwnerInfo } from "../../../types/OwnerInfo";
import { validOrgnumber } from "../../../helpers/OrgnumberVaildator";
import { useAsync } from "react-use";
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import { isEmptyArray } from "../../../helpers/IsEmptyHelpers";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";

const AddExemptOrganizationModal = ({open, handleClose, handleConfirmChanges}) => {
    const {currentCustomer} = useCurrentCustomer();
    const {t, i18n} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [commentCharCount, setCommentCharCount] = useState(0);
    const maxCommentChars = 250;

    const [orgnumberHelpText, setOrgnumberHelpText] = useState<{key:string, org?}>({key:''});
    const [facilityValues, setFacilityValues] = useState<{id:number, name:string}[]>(
        currentCustomer.facilities
    );
    const [facilityInputValue, setFacilityInputValue] = useState("");

    const firstDayInMonth = dayjs().startOf("month");
    const [fromDate, setFromDate] = useState(firstDayInMonth);
    const [toDate, setToDate] = useState<dayjs.Dayjs | null>(null);
    const [fromDateError, setFromDateError] = useState<DateValidationError|null>(null);
    const [toDateError, setToDateError] = useState<DateValidationError|null>(null);

    const [infoData, setInfoData] = useState({
        [t("Anläggning")]: currentCustomer.facilities.map(f=> f.name).toString(),
        [t("Startdatum")]: firstDayInMonth.format('YYYY-MM-DD'),
        [t("Slutdatum")]: t("Tillsvidare")
    });
    const [formData, setFormData] = useState({
        orgnumber: '',
        comment: '',
        facilities: currentCustomer.facilities.map(f=> `/facilities/${f.id}`),
        validFrom: firstDayInMonth.format('YYYY-MM-DD'),
        validUntil: null
    });

    const [errors, setErrors] = useState({
        orgnumber: {value: false, message: ''},
        facilities: {value: false, message: ''},
        comment: {value: false, message: ''},
        validFrom: {value: false, message: ''},
        validUntil: {value: false, message: ''}
    });

    const validateForm = () => {
        const orgnumberValid = validOrgnumber(formData.orgnumber);
        const commentValid = formData.comment.length <= maxCommentChars;
        const validFromValid = formData.validFrom && dayjs(formData.validFrom, "YYYY-MM-DD", true).isValid() && !dayjs(formData.validFrom).isBefore(firstDayInMonth);
        const validUntilValid = formData.validUntil !== 'invalid' || (formData.validUntil && dayjs(formData.validUntil, 'YYYY-MM-DD', true).isValid() && !dayjs(formData.validUntil).isBefore(fromDate));
        const facilitiesValid = formData.facilities.length > 0;

        setErrors({
            orgnumber: {value: !orgnumberValid, message: orgnumberValid ? '' : 'Ogiltigt_Orgnr'},
            facilities: {value: !facilitiesValid, message: facilitiesValid? '' : "Anläggning_krävs"},
            comment: {value: !commentValid, message: commentValid ? '' : 'För lång'},
            validFrom: {value: !validFromValid, message: validFromValid ? '' : 'Ogiltigt_datum'},
            validUntil: {value: !validUntilValid, message: validUntilValid ? '' : "Ogiltigt_datum"}
        });

        return orgnumberValid && commentValid && validFromValid && validUntilValid && facilitiesValid;
    };
    
    const handleFromDateChange = (newValue) => {
        setFromDate(newValue);
        setInfoData({...infoData, [t("Startdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD') : ''});
        setFormData({...formData, validFrom: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD') : 'invalid') : null})
    }

    const handleToDateChange = (newValue) => {
        setToDate(newValue);
        setInfoData({...infoData, [t("Slutdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD') : t("Tillsvidare")});
        setFormData({...formData, validUntil: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD') : 'invalid') : null})
    }

    const handleFacilitySelectChange = (newValue:{id:number, name:string}[]) => {
        setFacilityValues(newValue);
        if(newValue){
            setInfoData({...infoData, [t("Anläggning")]: newValue.map(v => v.name).toString()})
        }
        setFormData({...formData, facilities: newValue ? newValue.map(v => `/facilities/${v.id}`) : []});
        setErrors({...errors,
            facilities: {value: newValue.length < 1, message: newValue.length < 1 ? "Anläggning_krävs" : ''}
        })
    }

    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.id]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
        if(e.target.id === 'comment') {
            setErrors({...errors,
                comment: {value: (e.target.value.length > maxCommentChars), message: 'Kommentar får inte vara för lång'}
            });
            setCommentCharCount(e.target.value.length);
            }
        else if(e.target.id === 'orgnumber') {
            if(e.target.value.replaceAll(" ", "").replaceAll("-", "").length >= 10) {
                let valid = validOrgnumber(e.target.value.replaceAll(" ", ""));
                setErrors({...errors,
                    orgnumber: {value: !valid, message: valid? '' : "Ogiltigt_Orgnr"}
                })
            }
            else {
                setErrors({...errors,
                    orgnumber: {value: false, message: ''}
                })
            }
        }
    };

        const checkOwnerInfo = async() => {
            if(formData.orgnumber.replaceAll(" ", "").replaceAll("-", "").length >= 10) {
                if(validOrgnumber(formData.orgnumber)) {
                    let {data, errors} = await getOwnerInfo(formData.orgnumber);
                    if(isEmptyArray(errors)){
                        const {key, org} = buildOrgnumberHelpText(data);
                        setOrgnumberHelpText({key: key, org: org});
                    }
                }
            }
            else {
                setOrgnumberHelpText({key: ""});
            }
        };

        useEffect(() => {
            checkOwnerInfo();
        }, [formData.orgnumber]);

        const buildOrgnumberHelpText = (data: OwnerInfo) : {key: string, org?} => {
            const value = {...data, name: t(data.name), parentName: t(data.parentName)}
            if (data.isBranch){
                return {key: "Org_is_branch", org: value}; 
            }
            else if(data.numberOfBranches > 0) {
                return {key: "Org_has_branches", org: value};
            }
            else {
                return {key: "Org_info", org: value};
            }
        }
            const checkExists = useAsync(async() => {
            let exists: boolean = false;
            if(validOrgnumber(formData.orgnumber)) {
                if(facilityValues && fromDate && fromDate.isValid()) {
                    let {data, errors} = await exemptOrganizationExists(formData.orgnumber, facilityValues.map(v => v.id), formData.validFrom, formData.validUntil);
                   if(!errors || errors.length === 0) {
                        exists = data.valueOf();
                    }
                }
                   else {
                    exists = false;
                }
                setErrors({...errors,
                    orgnumber: {value: exists, message: exists? "Org_Finns_Redan": ''}
                })
            }
            return exists;
        }, [facilityValues, formData.orgnumber, formData.validFrom, formData.validUntil]);
    
    const handleSubmit = () => {
        if (validateForm() && checkExists) {
            toggleHidden();
            confirmationDialog.handleOpen();    
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        clearFormData();
        toggleHidden();
    };

    const clearFormData = () => {
        setFormData({
            orgnumber: '',
            comment: '',
            facilities: currentCustomer.facilities.map(f=> `/facilities/${f.id}`),
            validFrom: firstDayInMonth.format('YYYY-MM-DD'),
            validUntil: null 
        });

        setInfoData({
            [t("Anläggning")]: currentCustomer.facilities.map(f => f.name).toString(),
            [t("Startdatum")]: firstDayInMonth.format('YYYY-MM-DD'),
            [t("Slutdatum")]: t("Tillsvidare")
        });
        setFacilityValues(currentCustomer.facilities);
        setFacilityInputValue("");
        setCommentCharCount(0);
        setFromDate(firstDayInMonth);
        setToDate(null);
        setOrgnumberHelpText({key:""});

        setErrors({
            orgnumber: {value: false, message: ''},
            facilities: {value: false, message: ''},
            comment: {value: false, message: ''},
            validFrom: {value: false, message: ''},
            validUntil: {value: false, message: ''}
        });
    };

    const fromDateErrorString = useMemo(() => {
        switch(fromDateError) {
            case 'invalidDate':
            case 'minDate':
            case 'maxDate': {
                setErrors({...errors, validFrom: {value: true, message: "Ogiltigt_datum"}});
                return t("Ogiltigt_datum");
            }
            default: {
                setErrors({...errors, validFrom:{value: false, message: ''}});
                return '';
            }
        }
    },[fromDateError]);

    const toDateErrorString = useMemo(() => {
        switch(toDateError) {
            case 'invalidDate':
            case 'maxDate':
            case 'minDate': {
                setErrors({...errors, validUntil: {value: true, message: "Ogiltigt_datum"}});
                return t("Ogiltigt_datum");
            }
            default: {
                setErrors({...errors, validUntil: {value: false, message: ''}});
                return '';
            }
        }
    },[toDateError])

    const checkDisabled = () => {
        return checkExists.value ||
        errors.facilities.value || 
        !validOrgnumber(formData.orgnumber) || 
        fromDateError != null || 
        toDateError != null ||
        errors.comment.value;
    }
    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle sx={{xs:12}} variant="h5">{t("Ny_Gratis_Organisation")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <TextField
                                id="orgnumber"
                                name={t("Organisationsnummer")}
                                label={t("Organisationsnummer")}
                                size="small"
                                required
                                value={formData.orgnumber}
                                onChange={handleInputChange}
                                error={errors.orgnumber.value}
                                helperText={errors.orgnumber.value ? t(errors.orgnumber.message) : t(orgnumberHelpText.key, {org: orgnumberHelpText.org, interpolation: {escapeValue: false}})}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {currentCustomer.facilities.length > 1 &&
                            <Autocomplete
                                selectOnFocus
                                multiple
                                clearOnBlur
                                size="small"
                                id="facility-select"
                                limitTags={2}
                                options={currentCustomer.facilities}
                                getOptionLabel={(option) => option.name}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                    ))
                                }
                                value={facilityValues}
                                onChange={(e, newValue) => handleFacilitySelectChange(newValue)}
                                inputValue={facilityInputValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onInputChange={(e, newInputValue) => setFacilityInputValue(newInputValue)}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params as unknown as OutlinedTextFieldProps} 
                                    variant="outlined"
                                    error={errors.facilities.value}
                                    label={t("Välj_Anläggning")}
                                    helperText={errors.facilities.value ? t(errors.facilities.message): ''}
                                    />
                                )}
                                sx={{
                                    background: "#FFF",
                                    borderRadius: "4px",
                                }}
                            />
                            }
                            {currentCustomer.facilities.length === 1 &&
                            <Typography variant="subtitle1">{currentCustomer.facilities[0].name}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comment"
                                name={t("Kommentar")}
                                label={t("Kommentar")}
                                size="small"
                                value={formData.comment}
                                onChange={handleInputChange}
                                fullWidth
                                error={errors.comment.value}
                                helperText={commentCharCount + '/' + maxCommentChars + ' ' + (errors.comment.value ? t(errors.comment.message) : '')}
                                autoComplete="off"
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <Grid item xs={6}>
                                <DatePicker
                                    label={t("Startdatum")}
                                    onChange={(handleFromDateChange)}
                                    onError={(error) => setFromDateError(error)}
                                    minDate={firstDayInMonth}
                                    value={fromDate}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            helperText: fromDateErrorString
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    label={t("Slutdatum")}
                                    value={toDate}
                                    format="YYYY-MM-DD"
                                    onChange={handleToDateChange}
                                    onError={(error) => setToDateError(error)}
                                    minDate={fromDate}
                                    slotProps={{
                                        textField: {
                                            helperText: toDateErrorString ? toDateErrorString : t("Lämna_tom_för_tillsvidare")
                                        },
                                        field: {
                                            clearable: true
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <Typography>{t("Bakåtdatering_Gratis_Förklaring")}</Typography>
                        </Grid>
                   </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" type="submit" disabled={checkDisabled()}>{t("Lägg till")}</Button>
                    <Button variant="outlined" color="error" onClick={()=>{clearFormData(); handleClose();}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara Gratis Organisation")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default AddExemptOrganizationModal;