export const PostMessageHelper = (msg: any) => {
    const wk = (window as any).webkit;

    if (wk &&
        wk.messageHandlers &&
        wk.messageHandlers.ios) {
        wk.messageHandlers.ios.postMessage(msg);
        return true;
    }
    if ((window as any).appInterface !== undefined) {
        (window as any).appInterface.postMessage(msg)

        return true;
    }
    return false;
};