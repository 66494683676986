import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { HistoricalExemption, rowToHistoricalExemption } from '../../types/HistoricalExemption';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGridApiRef } from '@mui/x-data-grid';
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import AssociatedExemptionsModal from './AssociatedExemptionsModal';
import { BillableVisit } from '../../types/BillableVisit';
import { findAssociatedExemptionsByExemptionId } from '../../api/HistoricalExemptionAPI';
import { fetchBillableVisitById } from '../../api/BillableVisitsAPI';
import {ExemptionType} from "../../types/enums/ExemptionType";
import {formatExemptionIdColumn, formatUpdatedColumn} from "../../helpers/ColumnFormatters";
import { isEmptyArray } from '../../helpers/IsEmptyHelpers';
import { boxStyle } from '../Shared/Tables/listStyles';

interface HistoricalExemptionTableProps {
    historicalExemptionItems: HistoricalExemption[];
    loading: boolean;
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortMode: GridSortModel) => void;
}

const HistoricalExemptionTable = (({historicalExemptionItems, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange}: HistoricalExemptionTableProps) => {
    const { t } = useTranslation();
    const gridApiRef = useGridApiRef();

    const [historicalExemption, setHistoricalExemption] = useState<HistoricalExemption>(rowToHistoricalExemption({}));
    const [detailView, setDetailView] = useState(false);
    const [visit, setVisit] = useState<BillableVisit|null>(null);
    const [associatedExemptions, setAssociatedExemptions] = useState<HistoricalExemption[]|null>(null);

    const formatFacilityColumn = (params:GridValueGetterParams<any>) => {
        let value = params.value;
        if (value == null){ 
            return '';
        }
        else { 
            return value.name;
        }
    }

    const formatCompanyColumn = (params:GridValueGetterParams<any>) => {
        let value = params.value;
        if(value == null || value.orgnumber == null || value.name == null){
            return 'Okänd';
        }
        else {
            return t(value.name.trim()) + ' (' + value.orgnumber + ')';
        }
    }

    const formatReasonColumn = (params: GridValueGetterParams<any>) => {
        let value = params.value;
        if (value == null) {
            return '';
        } else if (value === ExemptionType.CANCELLED) {
            return t("Återförd_till_Fakturering")
        } else {
            return t(value);
        }
    }

    const columns: GridColDef[] = [
        {field: 'exemptionId', headerName: t("UndantagsId"), valueGetter: formatExemptionIdColumn, minWidth: 50, maxWidth:150, flex: 1, disableColumnMenu: true},
        {field: 'updated', headerName: t("Undantagen"), valueGetter: formatUpdatedColumn, minWidth: 50, maxWidth:150, flex: 1, disableColumnMenu: true},
        {field: 'updatedBy', headerName: t("Ändrad_av"), minWidth: 50, maxWidth:250, flex: 2, disableColumnMenu: true},
        {field: 'facilityName', headerName: t("Anläggning"), sortable: false, valueGetter: formatFacilityColumn, minWidth: 50, maxWidth: 250, flex:2, disableColumnMenu: true},
        {field: 'regnumber', headerName: t("Regnr_shortest"), minWidth: 50, maxWidth: 175, flex:2, disableColumnMenu: true},
        {field: 'company', headerName: t("Företagsnamn"), valueGetter: formatCompanyColumn, minWidth: 50, maxWidth:350, flex: 2, disableColumnMenu: true},
        {field: 'reason', headerName: t("Undantagsorsak"), sortable: false, valueGetter: formatReasonColumn, minWidth:50, maxWidth:200, flex:1, disableColumnMenu: true},
        {field: 'comment', headerName: t("Kommentar"), minWidth:50, maxWidth: 150, flex: 1, disableColumnMenu: true},
    ];

    const generateRows = (historicalExemptionItem: HistoricalExemption): any[] => {
        const rows: any[] = [{
            id: historicalExemptionItem.id, 
            facilityName: historicalExemptionItem.facilityName,
            regnumber: historicalExemptionItem.regnumberClear,
            exemptionId: historicalExemptionItem.exemptionId,
            reason: historicalExemptionItem.reason,
            comment: historicalExemptionItem.comment,
            updated: historicalExemptionItem.updated,
            updatedBy: historicalExemptionItem.updatedBy,
            company: historicalExemptionItem.company,
            visitId: historicalExemptionItem.visitId
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    useEffect(() => {
        setRows(historicalExemptionItems.map(generateRows));
    }, [historicalExemptionItems]);

    const handleClose = () => {
        setHistoricalExemption(rowToHistoricalExemption({}));
        setDetailView(false);
    };

    const [rows, setRows] = useState(() => historicalExemptionItems.map(generateRows));

    useEffect(() => {
        fetchData();
    }, [historicalExemption]);

    const fetchData = async() => {
        if(historicalExemption && historicalExemption.exemptionId) {
            let {data, errors} = await findAssociatedExemptionsByExemptionId(historicalExemption.exemptionId);
            if(isEmptyArray(errors)) {
                setAssociatedExemptions(data);
            }
        }
        if(historicalExemption && historicalExemption.visitId) {
            let {data, errors} = await fetchBillableVisitById(historicalExemption.visitId);
            if(isEmptyArray(errors)) {
                setVisit(data);
            }
            else if(errors.some(error => error.status === 404)) {
                await fetchBillableVisitById(historicalExemption.visitId, 'previousVisits').then(response =>
                    setVisit(response.data)
                ).catch(error => console.warn("Did not find a previous associated visit"));
            }
        }

    };

    const handleRowClick = (params: any) => {
        setHistoricalExemption(rowToHistoricalExemption(params.row));
        setDetailView(true);
    };


    const inactiveVisitRow = (params: GridRowParams) => {
        const reason = params.row.reason;
        if (reason === ExemptionType.CANCELLED) {
            return `custom-cancelled-item`;
        }
        else {
            return `custom-exempt-item`;
        }
    }


    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    getRowClassName={inactiveVisitRow}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onRowClick={handleRowClick}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <AssociatedExemptionsModal 
                            open={detailView} 
                            handleClose={handleClose} 
                            data={[visit, associatedExemptions]} 
                            formatReasonColumn={formatReasonColumn}
                            formatUpdatedColumn={formatUpdatedColumn}
                            formatExemptionIdColumn={formatExemptionIdColumn}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
});
export default HistoricalExemptionTable;