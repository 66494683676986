import * as React from "react";
import useTheme from "@mui/system/useTheme";
import FormControl from "@mui/material/FormControl";
import {FormGroup, Switch} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useTranslation} from "react-i18next";

export const CustomSwitch = ({onChange, data}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return <>
        <FormControl component="fieldset">
            <FormGroup aria-label="position">
                <FormControlLabel
                    value={data.value}
                    control={<Switch name={data.name} onChange={(e) => onChange(e)} sx={{   "& .MuiSwitch-switchBase": {
                            ".MuiSwitch-thumb": {
                                backgroundColor: theme.palette.error.main
                            },
                            "+ .MuiSwitch-track": {
                                backgroundColor: theme.palette.error.main
                            },
                            "&.Mui-checked": {
                                "+ .MuiSwitch-track": {
                                    backgroundColor: theme.palette.success.main
                                },
                                ".MuiSwitch-thumb": {
                                    backgroundColor: theme.palette.success.main
                                }
                            }}}} checked={data.checked} />}
                    label={t(data.name)}
                    labelPlacement="top"
                />
            </FormGroup>
        </FormControl>
    </>
};