import { PreRegistrationStatusType } from "./enums/PreRegistrationType";

export interface PreRegistration {
    id: string,
    regnumberClear: string,
    vehicleOwner: string,
    ownerName: string,
    ownerChanged: string,
    customerId: string,
    facilityName: {id:number, name:string},
    comment: string,
    validFrom: any,
    validUntil: any,
    createdBy: string,
    updatedBy: string,
    created: any,
    updated: any,
    clientRef: string,
    status: PreRegistrationStatusType,
    visitTime: number
}

export const rowToPreRegistration = (data:any) => {
    const {
        id,
        regnumberClear,
        ownerName,
        vehicleOwner,
        facilityName,
        validFrom,
        validUntil,
        comment,
        createdBy,
        created,
        status,
        visitTime
    } = data;
    return {
        id,
        regnumberClear,
        ownerName,
        vehicleOwner,
        facilityName,
        validFrom,
        validUntil,
        comment,
        createdBy,
        created,
        status,
        visitTime
    } as PreRegistration;
}