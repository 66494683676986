import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Paper,
    Grid,
} from "@mui/material";
import dayjs from "dayjs";
import {getCurrentLanguage} from "../../../helpers/LanguageHelper";
import i18n from "../../../i18n";
import {useCurrentCustomer} from "../../../contexts/CurrentCustomerContext";
import {DataGrid, GridColDef, GridPaginationModel, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid";
import {CustomNoRowsOverlay} from "../../Shared/Tables/CustomNoRowsOverlay";
import {findHistoricalExemptionByVisitId} from "../../../api/HistoricalExemptionAPI";
import {HistoricalExemption} from "../../../types/HistoricalExemption";
import {ErrorResponse} from "../../../api/apiUtils";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {Page} from "../../../types/Page";
import {formatExemptionIdColumn, formatUpdatedColumn} from "../../../helpers/ColumnFormatters";
import {ExemptionType} from "../../../types/enums/ExemptionType";

const ChangeHistoryModal = ({open, handleClose, billableVisit}) => {
    const {t} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [historicalExemptions, setHistoricalExemptions] = useState<HistoricalExemption[]>([]);
    const [page, setPage] = useState<Page>();
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 5
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'updated', sort: 'desc'}]);

    useEffect(() => {
        fetchHistoricalExemptions()
    }, [billableVisit, paginationModel, sortModel]);

    useEffect(() => {
        setRows(historicalExemptions.map(generateRows));
    }, [historicalExemptions]);

    const fetchHistoricalExemptions = async () => {
        try {
            setLoading(true)
            const {
                data,
                errors,
                page
            } = await findHistoricalExemptionByVisitId(
                billableVisit.id,
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
            setHistoricalExemptions(data);
            setErrors(errors);
            page && setPage(page);
        } finally {
            setLoading(false);
        }
    }

    const formatReasonColumn = (params: GridValueGetterParams<any>) => {
        let value = params.value;
        if (value == null) {
            return '';
        } else if (value === ExemptionType.CANCELLED) {
            return t("Återförd_till_Fakturering")
        } else {
            return t(value);
        }
    }

    const columns: GridColDef[] = [
        {field: 'updated', headerName: t("Tid"), valueGetter: formatUpdatedColumn, flex: 1, disableColumnMenu: true},
        {field: 'reason', headerName: t("Ändring"), valueGetter: formatReasonColumn, flex: 1, disableColumnMenu: true},
        {field: 'updatedBy', headerName: t("Ändrad_av"), flex: 1, disableColumnMenu: true},
        {
            field: 'exemptionId',
            headerName: t("UndantagsId"),
            valueGetter: formatExemptionIdColumn,
            flex: 1,
            disableColumnMenu: true
        },
    ];

    const generateRows = (historicalExemption: HistoricalExemption) => {
        const rows: any[] = [{
            id: historicalExemption.id,
            updated: historicalExemption.updated,
            reason: historicalExemption.reason,
            updatedBy: historicalExemption.updatedBy,
            exemptionId: historicalExemption.exemptionId,
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    }

    const [rows, setRows] = useState(() => historicalExemptions.map(generateRows));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{
                component: 'form',
            }}>
            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5">
                            {dayjs(billableVisit.vehicleData.sensorTime).locale(getCurrentLanguage(i18n.language)).format('D MMMM HH:mm:ss')}
                        </Typography>
                        <Typography variant="subtitle1">
                            {billableVisit.vehicleData.sensorName? billableVisit.vehicleData.sensorName :
                                currentCustomer.facilities.find(facility => facility.id === billableVisit.facilityId)?.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="right">
                            {t("Ändringshistorik")}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" align="center">{t("Fordon")}</Typography>
                        <Typography variant="body2"
                                    align="center">{billableVisit.vehicleData.regnumberClear}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" align="center">{t("Foretag")}</Typography>
                        <Typography variant="body2" align="center">{t(billableVisit.vehicleData.ownerName)}</Typography>
                        <Typography variant="body2"
                                    align="center">{"(" + billableVisit.vehicleData.owner + ")"}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{minHeight: '41vh'}} mt={6}>
                    <Paper>
                        <DataGrid
                            sx={{maxHeight: '70vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            paginationMode='server'
                            rowCount={page?.totalElements}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            sortModel={sortModel}
                            onSortModelChange={setSortModel}
                            sortingMode="server"
                            sortingOrder={['desc', 'asc']}
                            disableRowSelectionOnClick
                            pageSizeOptions={[5]}
                            hideFooterSelectedRowCount={true}
                            autoHeight
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions sx={{m: 1}}>
                <Button variant="outlined" color="error" onClick={() => {
                    setPaginationModel({page: 0, pageSize: 5});
                    handleClose();
                }}>{t("Stäng")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeHistoryModal;
