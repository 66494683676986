import { useState } from 'react';

interface DialogHookResult {
    open: boolean;
    handleOpen: () => void;
    handleClose:  (handleConfirmChanges: () => void) => void;
    handleAgree: (handleConfirmChanges: () => void) => void;
}

const useConfirmationDialog = (): DialogHookResult => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (callback: () => void) => {
        setOpen(false);
        callback();
    };

    const handleAgree = (callback: () => void) => {
        setOpen(false);
        callback();
    };

    return {
        open,
        handleOpen,
        handleClose,
        handleAgree,
    };
};

export default useConfirmationDialog;
