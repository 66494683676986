import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {ErrorResponse} from "../../api/apiUtils";
import {isEmptyArray} from "../../helpers/IsEmptyHelpers";
import {TextField} from '@mui/material';
import Box from "@mui/material/Box";

interface ConfirmationDialogProps {
    open: boolean;
    handleClose: () => void;
    handleAgree: () => void;
    title: string;
    subtitle: React.ReactNode;
    content: { [key: string]: string };
    errors: ErrorResponse[] | null,
    list: any,
    active: boolean,
    addtionalConfirmationText?: string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   open,
                                                                   handleClose,
                                                                   handleAgree,
                                                                   title,
                                                                   subtitle,
                                                                   content,
                                                                   errors,
                                                                   list,
                                                                   active,
                                                                   addtionalConfirmationText
                                                               }) => {
    const {t} = useTranslation(['translation', 'error']);
    const [agreed, setAgreed] = useState(!addtionalConfirmationText)
    const [textInput, setTextInput] = useState("");

    const handleTextInput = (e) => {
        let text = e.target.value;
        let confirmationString = addtionalConfirmationText ? addtionalConfirmationText.trim().replaceAll('"', '').toUpperCase() : '';
        let testString = text.trim().replaceAll('"', '').toUpperCase();
        setTextInput(text);
        setAgreed(confirmationString !== '' && text !== '' && (confirmationString === testString));
    }

    return (
        <React.Fragment>
            <Dialog
                PaperProps={{ component: 'form' }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" variant={"h6"} align="center">
                        {t(title)}
                </DialogTitle>

                {active ? (
                    <DialogContent>
                        <Typography variant="subtitle1">{t("Ändringar sker på dessa rader")}</Typography>
                        {list.rows.map((val, index) => (
                            <Box key={index}>
                                <Typography variant="body1">{val.facilityName + ": " + t(list.serviceType) + " " + t(list.statusText) + " " + list.dateToUpdate}</Typography>
                            </Box>
                        ))}
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Typography variant="subtitle1">{subtitle}</Typography>
                        {content && Object.entries(content).map(([key, value], index) => (
                            <Box key={index}>
                                <Typography variant="body1">{t(key)}: {t(value)}</Typography>
                            </Box>
                        ))}
                    </DialogContent>
                )}

                {addtionalConfirmationText && (
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="body1">
                                {t("Skriv_texten") + ' "' + addtionalConfirmationText + '" ' + t("för_att_fortsätta")}
                            </Typography>
                        </DialogContentText>
                        <TextField
                            variant="outlined"
                            required
                            size="small"
                            autoComplete="off"
                            error={!agreed}
                            onChange={handleTextInput}
                            helperText={textInput.length < 1 ? t("Skriv_text") : (agreed ? t('Matchar') : t('Matchar_ej'))}
                            sx={{ width: '50%' }}
                        />
                    </DialogContent>
                )}

                {!addtionalConfirmationText && (
                    <DialogContent>
                        <Typography variant="body1" align={"center"}>{t("Fortsätt")}?</Typography>
                    </DialogContent>
                )}

                <DialogContent>
                    <DialogContentText style={{ color: 'red' }}>
                        {errors && !isEmptyArray(errors) && errors.map((error, index) => (
                            <Typography key={index} variant="body1" style={{ color: 'red' }}>
                                {t(error.message, {ns: 'error'})}
                            </Typography>
                        ))}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" disabled={!agreed} onClick={handleAgree}>{t("OK")}</Button>
                    <Button variant="outlined" color="error" onClick={handleClose} autoFocus>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ConfirmationDialog;
