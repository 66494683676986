import {ApiResponseSingle, fetchData, getIdTokenHeader} from "./apiUtils";
import {Service} from "../types/Service";

export async function updateFacilityServices(serviceId: string, service: any): Promise<ApiResponseSingle<Service>> {
    const apiUrl = `/api/services/${serviceId}`;
    const options = {
        headers: getIdTokenHeader(),
        method: 'PUT',
        body: JSON.stringify(service)
    };

    const response = fetchData<Service>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Service>>;
}