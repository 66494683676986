import { Box, Grid, Typography, useTheme } from "@mui/material";
import SearchBar from "../Shared/SearchBar";
import FacilityAutocomplete from "../Shared/FacilityAutocomplete";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useTranslation } from "react-i18next";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { useEffect, useState } from "react";
import { Facility } from "../../types/Facility";
import { BillableVisit } from "../../types/BillableVisit";
import { fetchBillableVisitsBySearchCriteria } from "../../api/BillableVisitsAPI";
import { isEmptyArray } from "../../helpers/IsEmptyHelpers";
import VehicleHistoryStats from "./VehicleHistoryStats";
import {ErrorResponse} from "../../api/apiUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentLanguage } from "../../helpers/LanguageHelper";
import i18n from "../../i18n";
import dayjs from "dayjs";
import VehicleHistoryModal from "./Modals/VehicleHistoryModal";
import { useSearchParams } from "react-router-dom";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {TableSettings, TableStorage} from "../../types/settings/TableSettings";

export default function VehicleHistory () {
    const theme = useTheme();
    const {t} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);
    const [regNumber, setRegNumber] = useState("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'timeStamp', sort: 'desc'}])
    const [totalElements, setTotalElements] = useState<number>(0);
    const [vehicleVisitItems, setVehicleVisitItems] = useState<BillableVisit[]>([]);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const today = dayjs().endOf('day');
    const firstdayOfMonth = dayjs().startOf('month').startOf('day');
    const minDate = dayjs().subtract(3, 'years');
    const thirtyDaysBeforeToday = today.subtract(30, 'days').startOf('day');
    const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(thirtyDaysBeforeToday);
    const [toDate, setToDate] = useState<dayjs.Dayjs | null>(today);
    const [showVisitHistoryModal, setShowVisitHistoryModal] = useState(false);
    const [showingCurrentMonth, setShowingCurrentMonth] = useState(true);
    const [oneVisitItem, setOneVisitItem] = useState<BillableVisit|null>(null);

    const [searchParams] = useSearchParams();
    const regnumber = searchParams.get('regnumber');
    useEffect(() => {
        if(regnumber){
            setRegNumber(regnumber);
        }
    },[regnumber]);

    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        setFacilitiesFilterValue(facilities)
    };

    const handleRegNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationModel({...paginationModel, page: 0});
        setVehicleVisitItems([]);
        setRegNumber(e.target.value.toUpperCase());
    };

    const handleChangeFromDate = (newValue) => {
        setFromDate(newValue? newValue : thirtyDaysBeforeToday);
    }

    const handleChangeToDate = (newValue) => {
        setToDate(newValue? newValue : today );
    }

    const checkShowingCurrentMonth = () => {
        if (!showingCurrentMonth || ((fromDate != null && fromDate.isBefore(firstdayOfMonth)) && 
            (toDate != null && toDate.isBefore(firstdayOfMonth)))) {
            return false;
        }
        else {
            return true;
        }
    };

    const isRegnumberSearchable = ():boolean => {
        return regNumber ? regNumber.length > 5 : false;
    }

    useEffect(() => {
        if(isRegnumberSearchable()){
            fetchData();
        }
    },[regNumber, facilitiesFilterValue, paginationModel, sortModel, fromDate, toDate, showingCurrentMonth]);

    const mapSortFields = (model: GridSortModel): GridSortModel => {
        if(model.length > 0){
            const {field, sort} = model[0];
            let sortField;
            if(field === 'timeStamp') {
                sortField = 'vehicleData.sensorTime';
            }
            else {
                sortField = field;
            }
            return [{field: sortField, sort: sort}];
        }
        else {
            return [];
        }
    };

    const fetchFromCollection = async(collection: string) => {
        return await fetchBillableVisitsBySearchCriteria(
            paginationModel.pageSize,
            paginationModel.page,
            mapSortFields(sortModel),
            currentCustomer.id,
            encodeURIComponent(regNumber),
            facilitiesFilterValue.map((facility) => facility.id),
            fromDate? fromDate.startOf('day').valueOf(): undefined,
            toDate? toDate.endOf('day').valueOf() : undefined,
            undefined,
            undefined,
            collection
        );
    }

    const fetchData = async() => {
        setLoading(true);
        try {
            let result;
            if(regNumber || facilitiesFilterValue) {
                result = await fetchFromCollection(checkShowingCurrentMonth() ? 'billableVisits' : 'previousVisits');                
            }
            else {
                result = {data: [], errors:[], page:{totalElements: 0, size: 10, number: 0, totalPages: 0}};
            }
            const {data, errors, page} = result;

            setErrors(errors);
            setVehicleVisitItems([...data]);
            if(data.length > 0){
                setOneVisitItem(data[0])
            }
            else {
                const {data} = await fetchFromCollection('previousVisits');
                if(data.length > 0){
                    setOneVisitItem(data[0])
                }
                else {
                    setOneVisitItem(null);
                }
            }
            page && setTotalElements(page.totalElements);
        }
        finally {
            setLoading(false);
        }
    };

    const handleChangeCurrentMonth = (value:boolean) => {
        setPaginationModel({...paginationModel, page: 0});
        setShowingCurrentMonth(value);
    };

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <LocalShippingIcon/>
                        <h2>{t("Fordon_Historik")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Fordonshistorik_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <SearchBar
                                label={t("Regnr")}
                                onChange={handleRegNumberChange}
                                searchQuery={regNumber}
                                size={"medium"}
                                autoFocus={true}
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                        <Grid item xs="auto">
                                <DatePicker
                                    label={t("Startdatum")}
                                    onChange={handleChangeFromDate}
                                    minDate={minDate}
                                    maxDate={today}
                                    value={fromDate}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            InputProps : {
                                                readOnly: true
                                            },
                                            sx: {
                                                background: theme.palette.common.white,
                                                minWidth: {xs: 170},
                                                borderRadius: "4px",
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <DatePicker
                                    label={t("Slutdatum")}
                                    value={toDate}
                                    format="YYYY-MM-DD"
                                    onChange={handleChangeToDate}
                                    minDate={fromDate? fromDate : minDate}
                                    maxDate={today}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                                readOnly: true
                                            },
                                            sx: {
                                                background: theme.palette.common.white,
                                                minWidth: {xs: 170},
                                                borderRadius: "4px",
                                            },
                            
                                        },
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item sx={{ marginLeft: { xs: 0, md: "auto"}, pt:1}}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggningar")}
                                helperText={t("Alla_Anläggningar")}
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                disabled={!isRegnumberSearchable()}
                                onFacilitiesFilterChange={handleFilterFacilitiesChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <VehicleHistoryModal
                    open={showVisitHistoryModal}
                    handleClose={() => { setShowVisitHistoryModal(false)}}
                    vehicleVisitItems={vehicleVisitItems}
                    loading={loading}
                    onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
                    totalElements={totalElements}
                    fromDate={fromDate}
                    toDate={toDate}
                    paginationModel={paginationModel}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    showingCurrentMonth={checkShowingCurrentMonth()}
                    onChangeShowingCurrentMonth={handleChangeCurrentMonth}
                />
                {!showVisitHistoryModal && isRegnumberSearchable() && (
                <Box sx={{maxHeight: '70%'}}>
                {!isEmptyArray(errors) && errors.map((error, index) => (
                    <Typography key={index} variant="body1" style={{color: 'red'}}>
                        {error.message}
                    </Typography>
                    ))
                }
                {!loading && isRegnumberSearchable() && !oneVisitItem && (
                    <Typography variant="subtitle1" style={{color: 'initial'}}>
                        {t("Hittade_inte_besök_för_vald_tidsperiod")}
                    </Typography>
                )}
                {isRegnumberSearchable() && oneVisitItem && (
                    <VehicleHistoryStats
                        visitItem={oneVisitItem}
                        customerId={currentCustomer.id}
                        facilityIds={facilitiesFilterValue.map(f => parseInt(f.id))}
                        from={fromDate}
                        to={toDate}
                        handleShowVisits={() => setShowVisitHistoryModal(true)}
                    />
                )}
                </Box>
                )}     
            </Box>
       </Box>
    );
}