import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Backdrop, CircularProgress, Stack, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import CommentIcon from '@mui/icons-material/Comment';
import Typography from "@mui/material/Typography";
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import {updateVisitComment, updateVisitReference} from "../../../api/BillableVisitsAPI";
import {toBillableVisitID} from "../../../types/BillableVisit";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";
import { ExemptStatsComponent } from "../../Shared/ExemptStatsComponent";
import { nonViewerRoles } from "../../../types/CurrentUserRoles";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";


export function EditComponent({stats, data, exempt, close}) {
    const {t} = useTranslation();
    const [errorPut, setError] = useState({});
    const [visitId, setVisitId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [formData, setFormData] = useState({
        comment: '',
        reference: ''
    });
    const {currentUser} = useCurrentUser();

    useEffect(() => {
        if (data) {
            let id = toBillableVisitID(data);
            setVisitId(id)
            setFormData({
                ...formData,
                ["comment"]: (data.comment !== undefined) ? data.comment : "",
                ["reference"]: (data.reference !== undefined) ? data.reference : ""
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (compareData("comment") || compareData("reference")) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [formData]);

    const compareData = (field: string) => {
        return !!(formData[field] && formData[field] !== data[field]) || !!(!formData[field] && data[field])
    }

    const saveVisitComment = async () => {
        if (visitId && formData.comment !== undefined) {
            setLoading(true)
            try {
                const result = await updateVisitComment(visitId, formData.comment);
                setLoading(false);
                if (!isEmptyArray(result.errors)) {
                    throw new Error("Kunde inte uppdatera");
                }
                data = {...result.data}
                return true;
            } catch (error: any) {
                setError({message: "Kunde ej uppdatera kommentar"});
                return null;
            }
        }

        return true;
    }

    const saveVisitReference = async () => {
        if (visitId && formData.reference !== undefined) {
            setLoading(true)
            try {
                const result = await updateVisitReference(visitId, formData.comment, formData.reference);
                setLoading(false);
                if (!isEmptyArray(result.errors)) {
                    throw new Error("Kunde inte uppdatera");
                }

                data = {...result.data}
                return true;
            } catch (error: any) {
                setError({message: "Kunde ej uppdatera referens"});
                return null;
            }

        }

        return true;
    }

    const saveVisit = async () => {
        if (await saveVisitComment() && await saveVisitReference()) {
            close(data);
        }
    }

    const handleInputChange = (e) => {
        let input = (e.target.value === undefined) ? "" : e.target.value;
        setFormData({...formData, [e.target.id]: input});
    };

    const closeError = () => {
        setError({});
        setLoading(false);
    };

    const hasNonViewerRole = () => {
        return currentUser && currentUser.isAuthorized && nonViewerRoles.some(r => currentUser.roles.some(cur => cur.id === r.id));
    }

    return (
        <>
            <Box>
                <Grid container spacing={1}>
                    {data.watchListTitle &&
                        <>
                            <Grid sx={{color: "blue"}} justifyContent="center" container>
                                <Typography color="info" variant="subtitle2" align={'left'}>
                                    {t(data.watchListTitle)}
                                </Typography>
                            </Grid>
                            <Grid justifyContent="center" container>
                                <Typography variant="caption" align={'left'}>
                                    {t(data.watchListComment)}
                                </Typography>
                            </Grid>
                            <br/>
                        </>
                    }
                    <Grid item xs={12}>
                        <Stack alignItems="center" direction="row" gap={2}>
                            <CommentIcon/>
                            <TextField
                                id="comment"
                                fullWidth
                                label={t("Kommentar")}
                                value={formData.comment}
                                onChange={handleInputChange}
                                InputLabelProps={{shrink: true, spellCheck: 'false'}}
                                multiline
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack alignItems="center" direction="row" gap={2}>
                            <AddToHomeScreenIcon/>
                            <TextField
                                id="reference"
                                fullWidth
                                label={t("Referens")}
                                size="small"
                                value={formData.reference}
                                onChange={handleInputChange}
                                InputLabelProps={{shrink: true, spellCheck: 'false'}}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <DialogActions style={{justifyContent: 'center'}}>
                    <Button disabled={disabled} variant="contained" color="inherit" onClick={saveVisit} size="large"
                            type="submit">{t("Spara")}</Button>
                    <Button size="large" onClick={() => close()} variant="outlined" color="error">{t("Avbryt")}</Button>
                </DialogActions>
                {hasNonViewerRole() && (
                <DialogActions style={{justifyContent: 'center'}}>
                    <Button onClick={exempt} variant="contained" size="small" type="submit"
                            color="warning">{t("Undanta_från_fakturering")}</Button>
                </DialogActions>
                )}
                <ExemptStatsComponent
                    regNumber={data.regNumber}
                    customerId={data.customerId}
                    stats={stats}
                />
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 20}}
                    open={(loading || Object.keys(errorPut).length !== 0)}
                >
                    {Object.keys(errorPut).length > 0 ?
                        <>
                            <CloseIcon onClick={closeError} style={{float: 'right'}}/>
                            <Typography variant="caption" display="block" gutterBottom ml={1}>
                                {t(Object.values(errorPut))}
                            </Typography>
                        </> :
                        <CircularProgress color="inherit"/>
                    }
                </Backdrop>
            </Box>
        </>
    )
}