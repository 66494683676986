import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import {updateCustomer} from "../../../api/CustomerAPI";
import {useParams} from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ErrorResponse} from "../../../api/apiUtils";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";

const ChangeCustomerModal = ({onSuccess, customerDetails}) => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const handleHidden = () => setHidden(!hidden)
    const [infoData, setInfoData] = useState({});
    let {id} = useParams();
    const [activeCustomerFilter, setActiveCustomerFilter] = useState("Aktiva kunder");
    const [errors, setErrors] = useState<ErrorResponse[]>([]);

    const [formData, setFormData] = useState({
        name: '',
        fullName: '',
        orgnumber: '',
        active: false,
    });

    let [oldData] = useState({
        name: '',
        fullName: '',
        orgnumber: '',
        active: false,
    });

    const [errorsValidation, setErrorsValidation] = useState({
        name: false,
        fullName: false,
        orgnumber: false,
    });

    useEffect(() => {
        setStartData();
    }, [customerDetails]);

    const setStartData = () => {
        if (customerDetails) {
            formData.name = customerDetails.name;
            formData.fullName = customerDetails.fullName;
            formData.orgnumber = customerDetails.orgnumber;
            formData.active = customerDetails.active;


            oldData.name = customerDetails.name;
            oldData.fullName = customerDetails.fullName;
            oldData.orgnumber = customerDetails.orgnumber;
            oldData.active = customerDetails.active;

            setActiveCustomerFilter("Inaktiv")
            if (customerDetails.active) {
                setActiveCustomerFilter("Aktiv")
            }
        }
    }

    const validateForm = () => {
        const nameValid = formData.name.trim() !== '';
        const fullNameValid = formData.fullName.trim() !== '';
        const orgnumberValid = /^\d{6}-\d{4}$/.test(formData.orgnumber);

        setErrorsValidation({
            name: !nameValid,
            fullName: !fullNameValid,
            orgnumber: !orgnumberValid,
        });

        return nameValid && fullNameValid && orgnumberValid;
    };

    const validateData = (e) => {
        if (e.target.name === "Status") {
            setActiveCustomerFilter(e.target.value);
            setFormData({...formData, active: !formData.active});
        } else {
            setFormData({...formData, [e.target.id]: e.target.value});
        }
    }

    const compareData = () => {
        return JSON.stringify(oldData) !== JSON.stringify(formData);
    }

    const saveCustomer = async (changeData: any) => {
        if (id) {
            const {data, errors} = await updateCustomer(id, changeData);
            if (isEmptyArray(errors)) {
                handleClose();
                onSuccess({});
                confirmationDialog.handleClose(handleHidden);
            } else {
                setErrors(errors)
            }
        }
    }

    const handleInputChange = (e) => {
        setInfoData({...infoData, [e.target.name]: e.target.value});
        validateData(e);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleHidden();
            confirmationDialog.handleOpen();
        }
    };

    const handleConfirmChanges = () => {
        const newCustomer = {...formData}
        saveCustomer(newCustomer);
    };

    const handleOpen = () => {
        setStartData();
        setHidden(false);
        setOpen(true);
    };

    const handleClose = () => {
        clearValues();
        setOpen(false);
    };

    const handleBack = () => {
        setHidden(false);
        setErrors([]);
    };


    const clearValues = () => {
        setFormData({
            name: '',
            fullName: '',
            orgnumber: '',
            active: false,
        });
        setErrorsValidation({
            name: false,
            fullName: false,
            orgnumber: false,
        });
        setInfoData({});
        setErrors([]);
    };

    return (
        <>
            <Button variant="outlined" color="inherit" onClick={handleOpen}>{t("Ändra kunduppgifter")}</Button>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <Grid item xs={12}>
                    <DialogTitle variant="h4">{t("Ändra kunduppgifter")}</DialogTitle>
                </Grid>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="name"
                                name={t("Kort Namn")}
                                label={t("Kort Namn")}
                                size="small"
                                value={formData.name}
                                onChange={handleInputChange}
                                error={errorsValidation.name}
                                helperText={errorsValidation.name ? t('Namn krävs') : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="fullName"
                                name={t("Fullständigt Namn")}
                                label={t("Fullständigt Namn")}
                                size="small"
                                value={formData.fullName}
                                onChange={handleInputChange}
                                error={errorsValidation.fullName}
                                helperText={errorsValidation.fullName ? t('Fullständigt namn krävs') : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="orgnumber"
                                name={t("Organisationsnummer")}
                                label={t("Organisationsnummer")}
                                size="small"
                                value={formData.orgnumber}
                                onChange={handleInputChange}
                                error={errorsValidation.orgnumber}
                                helperText={errorsValidation.orgnumber ? t('Orgnr krävs') : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="Status"
                            id="active"
                            value={activeCustomerFilter}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="Aktiv" id="active" control={<Radio/>} label={t("Aktiv")}/>
                            <FormControlLabel value="Inaktiv" id="inactive" control={<Radio/>} label={t("Inaktiv")}/>
                        </RadioGroup>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" disabled={!compareData()} type="submit">{t("Uppdatera")}</Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(handleBack)}
                handleAgree={handleConfirmChanges}
                title={t("Spara kunduppgifter")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={errors}
                list={[]}
                active={false}
            />
        </>
    );
};
export default ChangeCustomerModal;