enum MessageType {
    NEW_VISIT = "NEW_VISIT",
    SETTINGS_DATA = "SETTINGS_DATA"
}

enum MobileAction {
    SOUND = "sound",
    NOTIFICATION = "notification",
    VIBRATE = "vibrate",
}

interface MobileNotification {
    type: MessageType;
    action?: MobileAction[];
    message?: string;
    data?: any;
}

export class NewVisitNotification implements MobileNotification {
    type: MessageType.NEW_VISIT;
    action: MobileAction[];
    message?: string;
    data?: any;

    constructor(message?: string, data?: any) {
        this.type = MessageType.NEW_VISIT;
        this.action = [MobileAction.NOTIFICATION, MobileAction.SOUND, MobileAction.VIBRATE];
        this.message = message;
        this.data = data;
    }
}

export class SettingsData implements MobileNotification {
    type: MessageType.SETTINGS_DATA;
    action: MobileAction[];
    message?: string;
    data?: any;

    constructor(message?: string, data?: any) {
        this.type = MessageType.SETTINGS_DATA;
        this.action = [];
        this.message = message;
        this.data = data;
    }
}
