export interface MarkedVehicle {
    id: string,
    title: string,
    text: string,
    regnumberClear: string,
    vehicleOwner: string,
    ownerName: string,
    ownerChanged: string,
    customerId: string,
    facilityNames: {id:number, name: string}[],
    comment: string,
    validFrom: any,
    validUntil: any,
    createdBy: string,
    updatedBy: string,
    created: any,
    updated: any,
    clientRef: string
}

export const rowToMarkedVehicle = (data:any) => {
    const {
        id,
        title,
        text,
        regnumberClear,
        ownerName,
        facilityNames,
        validFrom,
        validUntil
    } = data;
    return {
        id,
        title,
        text,
        regnumberClear,
        ownerName,
        facilityNames,
        validFrom,
        validUntil
    } as MarkedVehicle;
}

