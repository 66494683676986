import Box from "@mui/material/Box";
import { ExemptOrganization } from "../../types/ExemptOrganization";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useState } from "react";
import {
    useTheme,
    Typography,
} from "@mui/material";
import { createExemptOrganization, findExemptOrganizationByCustomerId, findExemptOrganizationByFacilityIdIn, findExemptOrganizationByFacilityIdInAndOrgnumberLikeOrNameLike } from "../../api/ExemptOrganizationAPI";
import { useAsync } from "react-use";
import ExemptOrganizationTable from "./ExemptOrganisationTable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddExemptOrganizationModal from "./Modals/AddExemptOrganizationModal";
import { CheckCircle } from "@mui/icons-material";
import {Facility} from "../../types/Facility";
import FacilityAutocomplete from "../Shared/FacilityAutocomplete";
import SearchBar from "../Shared/SearchBar";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {TableSettings, TableStorage} from "../../types/settings/TableSettings";

export default function ExemptOrganizations() {
    const {currentCustomer} = useCurrentCustomer();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "validFrom", sort: 'desc'}])
    const [exemptOrganizationItems, setExemptOrganizationItems] = useState<ExemptOrganization[]>([]);

    const [adding, setAdding] = useState(false);
    const [loadData, setLoadData] = useState(false);

    const { t } = useTranslation();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);

    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setPaginationModel({...paginationModel, page: 0});
        setExemptOrganizationItems([]);
        setSearchQuery(search);
    };

    const handleChangePaginationModel = (newModel: GridPaginationModel) => {
        TableStorage.saveLocalStorage(newModel.pageSize)
        setPaginationModel(newModel);
    };

    const addExemptOrganization = async (newExemptOrganization: ExemptOrganization) => {
        try {
            const {data, errors} = await createExemptOrganization(newExemptOrganization);
            if(!errors || errors.length === 0) {
                setLoadData(true);
                handleClose();
            }
            else {
                //TODO: Handle other response codes
            }
        } catch (error) {
            console.error('Error when creating exemptOrganization', error);
        }
    };

    const handleOpen = () => {
        setAdding(true);
    };

    const handleClose = () => {
        setAdding(false);
    }

    const {
        loading,
        error,
        value: totalElements,
    } = useAsync(async () => {
        let result;
        if(searchQuery && facilitiesFilterValue && facilitiesFilterValue.length > 0) {
            result = await findExemptOrganizationByFacilityIdInAndOrgnumberLikeOrNameLike(
                facilitiesFilterValue.map((f) => f.id.toString()),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        } else if (facilitiesFilterValue && facilitiesFilterValue.length > 0){
            result = await findExemptOrganizationByFacilityIdIn(
                facilitiesFilterValue.map((f) => f.id.toString()), 
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        else if (searchQuery) {
            result = await findExemptOrganizationByFacilityIdInAndOrgnumberLikeOrNameLike(
                currentCustomer.facilities.map((f) => f.id.toString()),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        else if (currentCustomer){
            result = await findExemptOrganizationByCustomerId(
                currentCustomer.id.toString(), 
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        const {data, errors, page :{totalElements}} = result;
        
        setExemptOrganizationItems([...data]);
        setLoadData(false);
        return totalElements;
    }, [searchQuery, paginationModel, sortModel, facilitiesFilterValue, loadData]);

    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        setFacilitiesFilterValue(facilities)
    };

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <CheckCircle/>
                        <h2>{t("Gratis_Organisationer")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Gratis_Org_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <SearchBar
                                label={t("Sök_Organisation")}
                                helperText={t("Orgnr_eller_namn")}
                                onChange={handleSearchQueryChange}
                                searchQuery={searchQuery}
                                size={"medium"}
                            />
                        </Grid>
                        <Grid item sx={{ marginLeft: { xs: 0, md: "auto"}, pt:1}}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggningar")}
                                helperText={t("Alla_Anläggningar")}
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                onFacilitiesFilterChange={handleFilterFacilitiesChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                            <Button variant="outlined" color="inherit" onClick={handleOpen}>{t("Lägg_till_ny_gratis_organisation")}</Button>
                            <AddExemptOrganizationModal open={adding} handleClose={handleClose} handleConfirmChanges={addExemptOrganization}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{maxHeight: '70%'}}>
                {error && <div>{t("Något gick fel", {error})}</div>}
                {!error && (
                    <ExemptOrganizationTable
                        exemptOrganizationItems={exemptOrganizationItems}
                        loading={loading}
                        totalElements={totalElements}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handleChangePaginationModel}
                        onSortModelChange={setSortModel}
                        sortModel={sortModel}
                    />
                )}
            </Box>
        </Box>
    );
}