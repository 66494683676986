import {HistoricalExemption} from "../types/HistoricalExemption";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "../api/apiUtils";
import { GridSortModel } from "@mui/x-data-grid";

export const fetchHistoricalExemption = async (id: string): Promise<ApiResponseSingle<HistoricalExemption>> => {
    const apiUrl = `/api/historicalExemptions/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<HistoricalExemption>(apiUrl, options);
    return response as Promise<ApiResponseSingle<HistoricalExemption>>;
}

export const fetchHistoricalExemptions  = async (
    page: number, 
    rowsPerPage: number = 100, 
    sortModel: GridSortModel = [{field: 'updated', sort: 'desc'}]
) : Promise<ApiResponse<HistoricalExemption>> => {
    const params = [
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/historicalExemptions?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<HistoricalExemption[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<HistoricalExemption>(responseData, 'historicalExemptions')
    );
    return response as Promise<ApiResponse<HistoricalExemption>>;
}

export const findHistoricalExemptionByVisitId = async (
    visitId: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'updated', sort: 'desc'}]
) : Promise<ApiResponse<HistoricalExemption>> => {
    const params = [
        `visitid=${visitId}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
      ];
    const apiUrl = `/api/historicalExemptions/search/findByVisitId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<HistoricalExemption[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<HistoricalExemption>(responseData, 'historicalExemptions')
    );
    return response as Promise<ApiResponse<HistoricalExemption>>;
}

export const findHistoricalExemptionByCustomerId = async (
    customerid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'updated', sort: 'desc'}]
) : Promise<ApiResponse<HistoricalExemption>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
      ];
    const apiUrl = `/api/historicalExemptions/search/findByFacilityCustomerId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<HistoricalExemption[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<HistoricalExemption>(responseData, 'historicalExemptions')
    );
    return response as Promise<ApiResponse<HistoricalExemption>>;
}

export const findHistoricalExemptionByFacilityIdInAndRegnumberClearLikeOrExemptionIdLikeOrCompanyLike = async (
    facilityids: string[],
    searchQuery: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'updated', sort: 'desc'}]
) : Promise<ApiResponse<HistoricalExemption>> => {
  const params = [
    `facilityids=${facilityids}`,
    `regnumber=${searchQuery}%25`,
    `exemptionid=%25${searchQuery}%25`,
    `company=%25${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl =`/api/historicalExemptions/search/findByFacilityIdInAndRegnumberClearLikeOrExemptionIdLikeOrCompanyLike?${params.join('&')}`;

   const options = {
       headers: getIdTokenHeader(),
       method: "GET"
   };

  const response = fetchData<HistoricalExemption[]> (
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<HistoricalExemption>(responseData, 'historicalExemptions')
  );
  return response as Promise<ApiResponse<HistoricalExemption>>;
}

export const findHistoricalExemptionByFacilityIdIn = async (
    facilityids: string[],
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'updated', sort: 'desc'}]
  ) : Promise<ApiResponse<HistoricalExemption>> => {
    const params = [
      `facilityids=${facilityids}`,
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/historicalExemptions/search/findByFacilityIdIn?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<HistoricalExemption[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<HistoricalExemption>(responseData, 'historicalExemptions')
    );
    return response as Promise<ApiResponse<HistoricalExemption>>;
  }

export const findAssociatedExemptionsByExemptionId = async (exemptionId: string): Promise<ApiResponse<HistoricalExemption>> => {
    const params = [
      `exemptionid=${exemptionId}`
    ];
    const apiUrl = `/api/historicalExemptions/search/findAssociatedExemptionsByExemptionId?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<HistoricalExemption[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<HistoricalExemption>(responseData, "historicalExemptions")
    );
    return response as Promise<ApiResponse<HistoricalExemption>>;
  }
  
  