export const validOrgnumber = (value:string) => {
    let valid = /^\d{6}-?\d{4}$/.test(value);
    return  valid && luhnCheck(value.replaceAll("-", ""));
}

export const isCompanyOrgnumber = (value:string) => {
    let valid = /^\d{2}[2-9]\d{3}-?\d{4}$/.test(value); //Companies must have third digit > 1
    return valid && luhnCheck(value.replaceAll("-", ""));
}

const luhnCheck = (value:string) => {
    const arr = `${value}`
      .split('')
      .reverse()
      .map(x => Number.parseInt(x));
    const lastDigit = arr.shift();
    if(lastDigit !== undefined) {
        let sum = arr.reduce(
        (acc, val, i) =>
            i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
        0
        );
        sum += lastDigit;
        return sum % 10 === 0;
    }
    else {
        return false;
    }
  };