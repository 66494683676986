import Customer from "../components/Customers/Customer";
import { AuthenticatedPage } from "./AuthenticatedPage";


export function CustomerPage() {
    return (
        <AuthenticatedPage>
            <Customer />
        </AuthenticatedPage>
      )
}