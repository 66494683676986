import { GridSortModel } from "@mui/x-data-grid";
import {MarkedOrganization} from "../types/MarkedOrganization";
import { OwnerInfo } from "../types/OwnerInfo";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";

export const updateMarkedOrganization = async(id: string, organization: MarkedOrganization) : Promise<ApiResponseSingle<MarkedOrganization>> => {
  const apiUrl = `/api/markedOrganizations/${id}`;
  const options = {
    headers: getIdTokenHeader(),
    method: 'PATCH',
    body: JSON.stringify(organization)
  };
  const response =  fetchData<MarkedOrganization>(apiUrl, options);
  return response as Promise<ApiResponseSingle<MarkedOrganization>>;
}
  
export const createMarkedOrganization = async(organization: MarkedOrganization) : Promise<ApiResponseSingle<MarkedOrganization>> => {
  const apiUrl = `/api/markedOrganizations`;
  const options = {
    headers: getIdTokenHeader(),
    method: "POST",
    body: JSON.stringify(organization)
  };
  const response =  fetchData<MarkedOrganization>(apiUrl, options);
  return response as Promise<ApiResponseSingle<MarkedOrganization>>;
}
  
export const findMarkedOrganizationByCustomerId = async(
  customerid: string,
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<MarkedOrganization>> => {
  const params = [
    `customerid=${customerid}`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/markedOrganizations/search/findByFacilityCustomerId?${params.join('&')}`;
  const options = {
    headers: getIdTokenHeader(),
    method: "GET"
  };
  const response = fetchData<MarkedOrganization>(
    apiUrl, 
    options,
    (responseData) => extractEmbeddedResource<MarkedOrganization>(responseData, 'markedOrganizations')
);
  return response as Promise<ApiResponse<MarkedOrganization>>;
}
  
  
export const findMarkedOrganizationByFacilityIdInAndOrgnumberLikeOrNameLike = async(
  facilityids: string[],
  searchQuery: string,
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<MarkedOrganization>> => {
  const params = [
    `facilityids=${facilityids}`,
    `orgnumber=${searchQuery}%25`,
    `name=%25${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/markedOrganizations/search/findByFacilityIdInAndOrgnumberLikeOrNameLike?${params.join('&')}`;
  const options = {
    headers: getIdTokenHeader(),
    method: "GET"
  };
      
  const response = fetchData<MarkedOrganization>(
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<MarkedOrganization>(responseData, 'markedOrganizations')
  );
  return response as Promise<ApiResponse<MarkedOrganization>>;
}

export const findMarkedOrganizationByFacilityIdIn = async(
  facilityids: string[],
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<MarkedOrganization>> => {
  const params = [
    `facilityids=${facilityids}`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/markedOrganizations/search/findByFacilityIdIn?${params.join('&')}`;
  const options = {
    headers: getIdTokenHeader(),
    method: "GET"
  };
  
  const response = fetchData(
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<MarkedOrganization>(responseData, 'markedOrganizations')
  );
  return response as Promise<ApiResponse<MarkedOrganization>>;
}

export const markedOrganizationExists = async(
  orgnumber:string, 
  facilityids: number[],
  fromDate: string,
  toDate: string | null
) : Promise<ApiResponseSingle<Boolean>> => {
    const params = [
      `orgnumber=${orgnumber.replaceAll(" ", "")}`,
      `facilityids=${facilityids}`,
      `fromDate=${fromDate}`,
      `toDate=${toDate}`
    ];
    const apiUrl = `/api/markedOrganizations/search/existsByFacilityOrgnumberValidityPeriod?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<Boolean>(apiUrl, options);
    return response as Promise<ApiResponseSingle<boolean>>;
  }

export const getOwnerInfo = async(orgnumber:string): Promise<ApiResponseSingle<OwnerInfo>> => {
    const apiUrl = `/api/markedOrganizations/search/getOwnerInfo?orgnumber=${orgnumber}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    }
    const response = fetchData<OwnerInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<OwnerInfo>>;
  }