import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Stack, TextField, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {updateInvoice} from "../../../api/BillableVisitsAPI";
import {toBillableVisitID} from "../../../types/BillableVisit";
import Grid from "@mui/material/Grid";
import CommentIcon from "@mui/icons-material/Comment";
import {updateVisitComment} from "../../../api/BillableVisitsAPI";
import {BillableVisitType} from "../../../types/enums/BillableVisitType";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";
import { ExemptStatsComponent } from "../../Shared/ExemptStatsComponent";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import { nonViewerRoles } from "../../../types/CurrentUserRoles";


export function ExemptComponent({visitData, stats, exempt, close, back}) {
    const {t} = useTranslation();
    const [showExemptReasons, setShow] = React.useState(false);
    const [formData, setFormData] = useState({
        comment: '',
    });
    const [disabled, setDisabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [visitId, setVisitId] = React.useState("");
    const {currentUser} = useCurrentUser();
    const theme = useTheme();

    useEffect(() => {
        if (visitData) {
            let id = toBillableVisitID(visitData);
            setVisitId(id)
            setFormData({...formData, ["comment"]: (visitData.comment !== undefined) ? visitData.comment : ""});
        }
    }, [visitData]);

    useEffect(() => {
        if (compareData("comment")) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [formData]);

    const compareData = (field: string) => {
        return !!(formData[field] && formData[field] !== visitData[field]) || !!(!formData[field] && visitData[field]);
    }

    const saveVisitComment = async () => {
        if (visitId && formData.comment !== undefined) {
            setLoading(true)
            try {
                const result = await updateVisitComment(visitId, formData.comment);
                setLoading(false);
                if (!isEmptyArray(result.errors)) {
                    throw new Error("Kunde inte uppdatera");
                }
                visitData = {...result.data}
                return true;
            } catch (error: any) {
                return null;
            }
        }

        return true;
    }

    const saveVisit = async () => {
        if (await saveVisitComment()) {
            close(visitData);
        }

    }
    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.id]: e.target.value});
    };

    const reInstateInvoice = async () => {
        if (visitId) {
            const {data, errors} = await updateInvoice(visitId,visitData.reference, formData.comment);
            if (data) {
                close(data);
            }
        }
    }

    const hasNonViewerRole = () => {
        return currentUser && currentUser.isAuthorized && nonViewerRoles.some(r => currentUser.roles.some(cur => cur.id === r.id));
    }

    return (
        <>
            {!showExemptReasons &&
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} alignItems="start">
                            <Typography>{t("Exempt_By_Text",
                                {
                                    exemptionTime: new Date(visitData.exemption.time).toLocaleTimeString(navigator.language, 
                                    {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }), 
                                    exemptBy: visitData.exemption.changedBy
                                })}
                            </Typography>
                            <Typography>
                                {t("Exemption_ID", {exemptionId: visitData.exemption.exemptionId})}
                            </Typography>
                            {stats && stats.stats && visitData.type === BillableVisitType.EXEMPT &&
                            <ExemptStatsComponent
                                regNumber={visitData.regNumber}
                                customerId={visitData.customerId}
                                stats={stats}
                                alignment="left"
                            />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Stack alignItems="center" direction="row" gap={2}>
                                <CommentIcon/>
                                <TextField
                                    id="comment"
                                    fullWidth
                                    label={t("Kommentar")}
                                    value={formData.comment}
                                    onChange={handleInputChange}
                                    InputLabelProps={{shrink: true, spellCheck: 'false'}}
                                    multiline
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <DialogActions style={{justifyContent: 'center'}}>
                        <Button disabled={disabled} variant="contained" color="inherit" onClick={saveVisit} size="large"
                                type="submit">{t("Spara")}</Button>
                        <Button size="large" onClick={() => close()} variant="outlined"
                                color="error">{t("Avbryt")}</Button>
                    </DialogActions>
                    {hasNonViewerRole() && (
                    <>
                    <DialogActions style={{justifyContent: 'center'}}>
                        <Button onClick={reInstateInvoice} variant="contained" color="inherit" size="small" type="submit"
                                sx={{backgroundColor: theme.palette.common.white}}>{t("Återinför fakturering")}
                        </Button>
                    </DialogActions>

                    <DialogActions style={{justifyContent: 'center'}}>
                        <Button onClick={exempt} variant="contained" size="small" type="submit"
                                color="warning">{t("Undanta med ny orsak")}</Button>
                    </DialogActions>
                    </>
                    )}
                </Box>
            }
        </>
    )
}