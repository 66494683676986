import { InputBase, InputBaseProps, styled, TextFieldProps } from "@mui/material";
import { getGridDateOperators, GRID_DATE_COL_DEF, GRID_DATETIME_COL_DEF, GridColTypeDef, GridFilterInputValueProps, GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { getCurrentLocale } from "../../../helpers/LanguageHelper";
import i18n from "../../../i18n";

const dateAdapter = new AdapterDateFns({ locale: getCurrentLocale(i18n.language) });

function GridEditDateCell ({
    id,
    field,
    value,
    colDef
}: GridRenderEditCellParams<any, Date | null, string>) {
    const apiRef = useGridApiContext();
    const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

    const handleChange = (newValue:unknown) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue});
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getCurrentLocale(i18n.language)}>
        <Component
            value={value}
            autoFocus
            disablePast
            onChange={handleChange}
            slots={{textField: WrappedGridEditDateInput}}
        />
        </LocalizationProvider>
    );
}

const GridEditDateInput = styled(InputBase)({
    fontSize: 'inherit',
    padding: '0 9px',
  });
  
function WrappedGridEditDateInput(props: TextFieldProps) {
    const {InputProps, focused, ...other} = props;
    return (
        <GridEditDateInput fullWidth {...InputProps} {...(other as InputBaseProps)}/>
    );
}

export const dateColumnType: GridColTypeDef<Date, string> = {
    ...GRID_DATE_COL_DEF,
    resizable: false,
    renderEditCell: (params) => {
        return <GridEditDateCell {...params}/>
    },
    filterOperators: getGridDateOperators(false).map((item) => ({
        ...item,
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime: false }
    })),
    valueFormatter: (date) => {
        if(date && date.value) {
            return dateAdapter.formatByString(date.value, 'yyyy-MM-dd');
        }
        return '';
    }
}
export const dateTimeColumnType: GridColTypeDef<Date, string> = {
    ...GRID_DATETIME_COL_DEF,
    resizable: false,
    renderEditCell: (params) => {
        return <GridEditDateCell {...params} />;
    },
    filterOperators: getGridDateOperators(true).map((item) => ({
        ...item,
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime: true }
    })),
    valueFormatter: (dateTime) => {
        if(dateTime && dateTime.value) {
            return dateAdapter.formatByString(dateTime.value, 'yyyy-MM-dd HH:mm:ss');
        }
        return '';
    }
};

function GridFilterDateInput(
    props: GridFilterInputValueProps & { showTime? : boolean }
) {
    const { item, showTime, applyValue, apiRef} = props;

    const Component = showTime ? DateTimePicker : DatePicker;

    const handleFilterChange = (newValue: unknown) => {
        applyValue({...item, value: newValue});
    }

    return (
        <Component
        value={item.value ? new Date(item.value) : null}
        autoFocus
        label={apiRef.current.getLocaleText('filterPanelInputLabel')}
        disablePast
        slotProps={{
          textField: {
            variant: 'outlined',
          },
          inputAdornment: {
            sx: {
              '& .MuiButtonBase-root': {
                marginRight: -1,
              },
            },
          },
        }}
        onChange={handleFilterChange}
      />
    );
}