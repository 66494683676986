import useTheme from "@mui/system/useTheme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { fetchExemptStats } from "../../api/BillableVisitsAPI";
import { ExemptStats } from "../../types/ExemptStats";
import { ErrorResponse } from "../../api/apiUtils";
import { isEmptyArray } from "../../helpers/IsEmptyHelpers";
import { BillableVisit } from "../../types/BillableVisit";
import dayjs from "dayjs";
import { BillableVisitType } from "../../types/enums/BillableVisitType";
import { formatOwner } from "../../helpers/VisitFormatters";
import { boxStyle } from "../Shared/Tables/listStyles";

interface VehicleHistoryStatsProps {
    visitItem: BillableVisit,
    customerId: number,
    facilityIds: number[],
    handleShowVisits: () => void,
    from?: dayjs.Dayjs | null,
    to?: dayjs.Dayjs | null
}

const VehicleHistoryStats = (({visitItem, customerId, facilityIds, handleShowVisits, from, to}: VehicleHistoryStatsProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [stats, setStats] = useState<ExemptStats | null>(null);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchStatData();
    }, [visitItem, customerId, facilityIds, from, to]);


    const fetchStatData = async () => {
        setLoading(true);
        const {data, errors} = await fetchExemptStats(visitItem.vehicleData.regNumber, encodeURIComponent(customerId), facilityIds, from? from.valueOf(): null, to? to.valueOf() : null);
        if(data) {
            setStats(data);
        }
        setErrors(errors);
        setLoading(false);
    };


    return (
        <Box sx={boxStyle} >
            {loading? (
                <Skeleton animation='wave' variant="text"/>
            ) :!isEmptyArray(errors) ? (
                <Typography variant="caption" style={{color: theme.palette.error.main}} gutterBottom>
                    {t("Exempt_stats_failed_to_load")}
                </Typography>
            ) : (
                <Paper>
                <Box sx={{p: 1}}>
                <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                    <Grid item xs={12} >
                        <Typography variant="subtitle1">{t("Fordon")}: {visitItem && visitItem.vehicleData.regnumberClear}</Typography>
                        <Typography align="left" variant="subtitle1">{t("Företagsnamn")}: {visitItem && formatOwner(visitItem.vehicleData)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="left" variant="subtitle1">{stats?.grandTotal}{' ' + t("Besök_gjorda_under_perioden") + ' '}
                            {stats? stats.periodStart.substring(0,10) : ''} - {stats? stats.periodEnd.substring(0,10) : ''} 
                        </Typography>
                    </Grid>
                     <Grid item xs={12}>
                        {stats && stats.stats.INVOICE && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.INVOICE)}: {stats.stats.INVOICE.total}</Typography>)}
                        {stats && stats.stats.EXEMPT && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.EXEMPT)}: {stats.stats.EXEMPT.total}</Typography>)}
                        {stats && stats.stats.PRE_REGISTERED && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.PRE_REGISTERED)}: {stats.stats.PRE_REGISTERED.total}</Typography>)}
                        {stats && stats.stats.REVISIT && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.REVISIT)}: {stats.stats.REVISIT.total}</Typography>)}
                        {stats && stats.stats.FREE_VEHICLE && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.FREE_VEHICLE)}: {stats.stats.FREE_VEHICLE.total}</Typography>)}
                        {stats && stats.stats.FREE_ORGANIZATION && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.FREE_ORGANIZATION)}: {stats.stats.FREE_ORGANIZATION.total}</Typography>)}
                        {stats && stats.stats.FOREIGN && (
                        <Typography align="left" variant="subtitle1">{t(BillableVisitType.FOREIGN)}: {stats.stats.FOREIGN.total}</Typography>)}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="inherit" onClick={handleShowVisits}>{t("Visa_besök")}</Button>
                    </Grid>
                </Grid>
                </Box>
                </Paper>
            )}
        </Box>
    );

});

export default VehicleHistoryStats;