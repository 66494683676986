import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useHideOnRoute = (hiddenPath: string) => {
    const location = useLocation();
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        setIsHidden(location.pathname.includes(hiddenPath));
    }, [location.pathname, hiddenPath]);

    return isHidden;
};

export default useHideOnRoute;
