export enum SettingsTypeDouble {
    FOREIGN = "foreign",
    FREE_VEHICLE = "free",
    REVISIT = "revisit",
    INVOICE = "invoice",
}

export enum SettingsTypeSingleNotify {
    RED_LIST = "redlist",
    PRE_REGISTERED = "preregistration",
}

export enum SettingsTypeSingleShow {
    SENSOR_NAME = "sensorname",
}

export const loadDefaultData = ():any[] => {
    let data:any[]=[
        {name: "FOREIGN", show: false, notify:false},
        {name: "FREE_VEHICLE", show: true, notify: false},
        {name: "REVISIT", show: false, notify: false},
        {name: "INVOICE", show: true, notify: true},
        {name: "RED_LIST", notify: true},
        {name: "PRE_REGISTERED", notify: true},
        {name: "SENSOR_NAME", show: false}
    ]
    return data;
}