import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { MarkedVehicle, rowToMarkedVehicle } from '../../types/MarkedVehicle';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { formatMultiFacilityColumn } from '../../helpers/ColumnFormatters';
import { updateMarkedVehicle } from '../../api/MarkedVehicleAPI';
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGridApiRef } from '@mui/x-data-grid';
import UpdateMarkedVehicleModal from "./Modals/UpdateMarkedVehicleModal";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { boxStyle, getDateVisitRowClassName, isActiveDate } from '../Shared/Tables/listStyles';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { useNavigate } from 'react-router-dom';

interface MarkedVehicleTableProps {
    markedVehicleItems: MarkedVehicle[];
    loading: boolean;
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const MarkedVehicleTable = (({markedVehicleItems, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange}: MarkedVehicleTableProps) => {
    const { t } = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const gridApiRef = useGridApiRef();

    const today = dayjs().endOf("day");
    const [markedVehicle, setMarkedVehicle] = useState<MarkedVehicle>(rowToMarkedVehicle({}));
    const [cancelling, setCancelling] = useState(false);
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const {currentCustomer} = useCurrentCustomer();

    const columns: GridColDef[] = [
        {field: 'validFrom', headerName: t("Från"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'validUntil', headerName: t("Till"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'link', headerName: "", width: 50, disableColumnMenu: true, sortable: false, hideSortIcons: true,
            renderCell: (params) => {
            return (
            <Tooltip title={t("Öppna i") + ' ' + t("Fordon_Historik")} sx={{cursor: "pointer"}}>
                <LocalShippingIcon fontSize="small" />
            </Tooltip>);
        }},
        {field: 'regnumberClear', headerName: t("Regnr_shortest"), minWidth: 50, maxWidth:125, flex: 1, disableColumnMenu: true},
        {field: 'ownerName', headerName: t("Företagsnamn"), minWidth: 50, maxWidth: 400, flex:1, disableColumnMenu: true},
        {field: 'vehicleOwner', headerName: t("Orgnr"), minWidth: 50, maxWidth:125, flex:1, disableColumnMenu:true},
        {field: 'facilityNames', headerName: t("Anläggnings_Namn"), sortable: false, valueGetter: formatMultiFacilityColumn, minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu: true},
        {field: 'title', headerName: t("Rubrik"), minWidth:50, maxWidth: 300, flex: 1, disableColumnMenu: true},
        {field: 'text', headerName: t("Beskrivning"), minWidth:50, flex:1, disableColumnMenu: true},
        {field: 'edit', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (isActiveDate(params.row) && <Tooltip title={t("Redigera")} sx={{cursor: "pointer"}}><EditIcon/></Tooltip>)
            }
        },
        {field: 'cancel', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (isActiveDate(params.row) && <Tooltip title={t("Inaktivera")} sx={{cursor: "pointer"}}><CancelIcon/></Tooltip>)
        }}
    ];

    const generateRows = (markedVehicleItem: MarkedVehicle): any[] => {
        const rows: any[] = [{
            id: markedVehicleItem.id, 
            validFrom: markedVehicleItem.validFrom, 
            validUntil: markedVehicleItem.validUntil,
            facilityNames: markedVehicleItem.facilityNames,
            regnumberClear: markedVehicleItem.regnumberClear,
            ownerName: t(markedVehicleItem.ownerName),
            vehicleOwner: markedVehicleItem.vehicleOwner,
            title: markedVehicleItem.title,
            text: markedVehicleItem.text
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const setCancelValidUntilDate = ():string => {
        const fromDate = dayjs(markedVehicle.validFrom);
        if(fromDate.isAfter(today)) {
            return markedVehicle.validFrom
        } else {
            return today.format('YYYY-MM-DD');
        }

    }

    const handelConfirmCancelItem = async() => {
        const {data, errors} = await updateMarkedVehicle(
            markedVehicle.id,
            rowToMarkedVehicle({validUntil: setCancelValidUntilDate()})
        );
        gridApiRef.current.updateRows([data]);
        setCancelling(false);
    };

    const handleCancelItem = (row: any) => {
        setMarkedVehicle(row);
        setCancelling(true);
    }

    const handleConfirmClose = () => {
        toggleHidden();
        setCancelling(false);
    };

    useEffect(() => {
        setRows(markedVehicleItems.map(generateRows));
    }, [markedVehicleItems]);

    const handleConfirmChanges = async(updatedItem: MarkedVehicle) => {
        const {data, errors} = await updateMarkedVehicle(markedVehicle.id, updatedItem);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    const handleClose = () => {
        setUpdating(false);
    };

    const [rows, setRows] = useState(() => markedVehicleItems.map(generateRows));

    const handleCellClick = (params:any) => {
        if(params.field === 'cancel' && isActiveDate(params.row)) {
            handleCancelItem(params.row);
        } else if(params.field === 'edit' && isActiveDate(params.row)) {
            setUpdating(true);
            setMarkedVehicle(rowToMarkedVehicle(params.row));
        }
        else if(params.field === 'link') {
            navigate(`/vehiclehistory/${currentCustomer.id}?regnumber=${params.row.regnumberClear}`);
        }
        else if(params.field === 'regnumberClear') {
            //Do Nothing
        }
    }


    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    getRowClassName={getDateVisitRowClassName}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    rowSelection={false}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdateMarkedVehicleModal open={updating} handleClose={handleClose} handleConfirmChanges={handleConfirmChanges} data={markedVehicle}/>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={cancelling}
                handleClose={() => confirmationDialog.handleClose(handleConfirmClose)}
                handleAgree={() => confirmationDialog.handleAgree(handelConfirmCancelItem)}
                title={t("Inaktivera_Markerat_Fordon")}
                subtitle={markedVehicle.regnumberClear + ' ' + t("kommer_att_inaktiveras") + ' ' + t("Tidigare_flaggning_påverkas_inte")}
                content={{}}
                errors={null}
                list={[]}
                active={false}
            />
        </Box>
);
});
export default MarkedVehicleTable;