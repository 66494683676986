import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import FacilityAutocomplete from "../Shared/FacilityAutocomplete";
import CommuteIcon from '@mui/icons-material/Commute';
import { useTranslation } from "react-i18next";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { useEffect, useState } from "react";
import { Facility } from "../../types/Facility";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentLanguage } from "../../helpers/LanguageHelper";
import i18n from "../../i18n";
import dayjs from "dayjs";
import minMax from 'dayjs/plugin/minMax';
import { AccumulatorPeriodType, MAXPERIODS_DAY, MAXPERIODS_HOUR, MAXPERIODS_MONTH } from "../../types/enums/AccumulatorPeriodType";
import { fetchArrivalHistory } from "../../api/ArrivalHistoryAPI";
import { isEmptyArray } from "../../helpers/IsEmptyHelpers";
import { ArrivalHistoryResponse, CarHistoryResponse } from "../../types/ArrivalHistoryReponse";
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { boxStyle } from "../Shared/Tables/listStyles";

export default function ArrivalHistory () {
    const theme = useTheme();
    const gridApiRef = useGridApiRef();
    const {t} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);
    const [loading, setLoading] = useState(false);
    const today = dayjs().endOf('day');
    const minDate = dayjs('2021-01-01').startOf('day');
    const oneWeekBeforeToday = today.subtract(1, 'week').startOf('day');
    const [fromDate, setFromDate] = useState<dayjs.Dayjs>(oneWeekBeforeToday);
    const [toDate, setToDate] = useState<dayjs.Dayjs>(today);
    const [accumulatorPeriod, setAccumulatorPeriod] = useState<string>(AccumulatorPeriodType.DAY);
    const [arrivals, setArrivals] = useState<ArrivalHistoryResponse|null>(null);

    dayjs.extend(minMax);

    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        setFacilitiesFilterValue(facilities)
    };

    const getMaxToDate = () => {
        if(accumulatorPeriod === AccumulatorPeriodType.DAY) {
            return dayjs.min(today, fromDate.add(MAXPERIODS_DAY, 'days').endOf('day'));
        }
        else if(accumulatorPeriod === AccumulatorPeriodType.HOUR) {
            return dayjs.min(today, fromDate.add(MAXPERIODS_HOUR, 'days').endOf('day'));
        }
        else if (accumulatorPeriod === AccumulatorPeriodType.MONTH) {
            return dayjs.min(today, fromDate.add(MAXPERIODS_MONTH, 'months').endOf('month'));
        }
        else {
            return today;
        }
    }

    const handleChangeFromDate = (newValue) => {
        setFromDate(newValue? newValue : oneWeekBeforeToday);
    }

    const handleChangeToDate = (newValue) => {
        setToDate(newValue? newValue : today );
    }

    const handleChangeAccumulatorPeriod = (e:SelectChangeEvent) => {
        setAccumulatorPeriod(e.target.value);
    }

    useEffect(() => {
        fetchData();
    }, [facilitiesFilterValue, fromDate, toDate, accumulatorPeriod]);


    useEffect(() => {
        setColumns(() => generateColumns());
        if(arrivals != null) {
            setRows(() => Object.entries(arrivals).map(([key,value]) => generateRows(value)));
        }
    }, [arrivals])

    const fetchData = async() => {
        setLoading(true);
        try {
            if(fromDate.isValid() && toDate.isValid() && toDate.isAfter(fromDate) && !toDate.isAfter(getMaxToDate())){
                const facilityIds = facilitiesFilterValue.length > 0 ? facilitiesFilterValue.map(f => f.id) : currentCustomer.facilities.map(f => f.id.toString());
                const {data, errors} = await fetchArrivalHistory(fromDate? fromDate.format('YYYY-MM-DD') : '', toDate? toDate.format('YYYY-MM-DD'): '', accumulatorPeriod, facilityIds);
                if(isEmptyArray(errors)) {
                    setArrivals(data);
                }
                else {
                    setArrivals(null);
                }
            }
        }
        finally {
            setLoading(false);
        }
    };

    const getDateList = () => {
        const dates:string[] = [];
        let currentDate = fromDate;

        if(accumulatorPeriod === AccumulatorPeriodType.MONTH) {
            while (!currentDate.isAfter(toDate, 'month')) {
                dates.push(currentDate.format('YYYY-MM'));
                currentDate = currentDate.add(1, 'month');
            }
        }
        else {
            while(!currentDate.isAfter(toDate)) {
                if(accumulatorPeriod === AccumulatorPeriodType.HOUR) {
                    dates.push(currentDate.format('YYYY-MM-DD HH'));
                    currentDate = currentDate.add(1, 'hour');
                }
                else if(accumulatorPeriod === AccumulatorPeriodType.DAY) {
                    dates.push(currentDate.format('YYYY-MM-DD'));
                    currentDate = currentDate.add(1, 'day');
                }
            }
        }
        return dates;
    };


    const generateColumns = (): GridColDef[] => {
        const dateList = getDateList();
        let columns = [{field: "sensorName", headerName: t("Sensor"), minWidth: 200, maxWidth: 500, disableColumnMenu: true, sortable: true}] 
        if(accumulatorPeriod === AccumulatorPeriodType.DAY) { 
            columns = [...columns, ...dateList.map(entry => {return {field: entry, headerName: dayjs(entry).locale(getCurrentLanguage(i18n.language)).format('D/M'), minWidth:50, maxWidth: 70,  disableColumnMenu: true, sortable:false}})];
        }
        else if (accumulatorPeriod === AccumulatorPeriodType.HOUR) {
            columns = [...columns, ...dateList.map(entry => {return {field: entry, headerName: dayjs(entry).locale(getCurrentLanguage(i18n.language)).format('HH'), minWidth:50, maxWidth: 70,  disableColumnMenu: true, sortable:false}})];
        }
        else if (accumulatorPeriod === AccumulatorPeriodType.MONTH) {
            columns = [...columns, ...dateList.map(entry => {return {field: entry, headerName: dayjs(entry).locale(getCurrentLanguage(i18n.language)).format('MMM'), minWidth:50, maxWidth: 70,  disableColumnMenu: true, sortable:false}})];
        }
        return columns;
    };

    const generateRows = (carHistoryResponse: CarHistoryResponse): any[] => {
        const rows: any[] = [{
            id: carHistoryResponse.sensorId,
            sensorName: carHistoryResponse.sensorName,
            ...carHistoryResponse.buckets
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<any[][]>([]);

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <CommuteIcon/>
                        <h2>{t("Besökshistorik")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Besökshistorik_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                        <Grid item xs="auto">
                                <DatePicker
                                    label={t("Från")}
                                    onChange={handleChangeFromDate}
                                    minDate={minDate}
                                    maxDate={today.startOf('day')}
                                    value={fromDate}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            InputProps : {
                                                readOnly: true
                                            },
                                            sx: {
                                                background: theme.palette.common.white,
                                                minWidth: {xs: 170},
                                                borderRadius: "4px",
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <DatePicker
                                    label={t("Till")}
                                    value={toDate}
                                    format="YYYY-MM-DD"
                                    onChange={handleChangeToDate}
                                    minDate={fromDate.endOf('day')}
                                    maxDate={getMaxToDate()}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                                readOnly: true
                                            },
                                            sx: {
                                                background: theme.palette.common.white,
                                                minWidth: {xs: 170},
                                                borderRadius: "4px",
                                            },
                            
                                        },
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs="auto">
                            <FormControl>
                                <InputLabel id="drop-down-label">{t("Visningsenhet")}</InputLabel>
                                <Select
                                    labelId="drop-down-label"
                                    name={t("Välj_visningsenhet")}
                                    label={t("Visningsenhet")}
                                    value={accumulatorPeriod}
                                    onChange={handleChangeAccumulatorPeriod}
                                    
                                    sx= {{
                                        background: theme.palette.common.white,
                                        minWidth: {xs: 170},
                                        borderRadius: "4px",
                                    }}                            
                                >
                                    <MenuItem value={AccumulatorPeriodType.HOUR}>{t(AccumulatorPeriodType.HOUR)}</MenuItem>
                                    <MenuItem value={AccumulatorPeriodType.DAY}>{t(AccumulatorPeriodType.DAY)}</MenuItem>
                                    <MenuItem value={AccumulatorPeriodType.MONTH}>{t(AccumulatorPeriodType.MONTH)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ pt:1}}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggningar")}
                                helperText={t("Alla_Anläggningar")}
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                onFacilitiesFilterChange={handleFilterFacilitiesChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={boxStyle}>
                <Paper>
                <Box sx={{maxHeight: '70%'}}>
                    {!loading && arrivals != null && rows.length > 0 && (
                        <DataGrid 
                            sx={{maxWidth: '86vw', width: '100%'}}
                            columns={columns} 
                            rows={rows}
                            getRowId={(row:any) => row.id}
                            hideFooter
                            apiRef={gridApiRef}
                        />
                    )}
                </Box>
                <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    mt={3}
                    pb={3}>
                    <Grid item xs="auto">
                        <Button variant="outlined" type="submit" color="inherit"
                                onClick={() => gridApiRef.current.exportDataAsCsv({delimiter: ';', utf8WithBom: true})}>{t("Exportera_CSV")}</Button>
                    </Grid>
                </Grid>
            </Box>
                </Paper>
            </Box>
       </Box>
    );
}