import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {fetchExemptStats} from '../../api/BillableVisitsAPI';
import {ErrorResponse} from '../../api/apiUtils';
import {ExemptStats} from "../../types/ExemptStats";
import {Skeleton, useTheme} from "@mui/material";
import {isEmptyArray} from "../../helpers/IsEmptyHelpers";
import {useTranslation} from "react-i18next";

interface ExemptStatsComponentProps {
    regNumber: string;
    customerId: number;
    stats?: ExemptStats;
    alignment?: string
}

export const ExemptStatsComponent: React.FC<ExemptStatsComponentProps> = ({regNumber, customerId, stats, alignment="center"}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [exemptStats, setExemptStats] = useState<ExemptStats | null>(null);
    const [exemptStatsText, setExemptStatsText] = useState<String>("");
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchInitialData();
    }, [regNumber, customerId, stats]);

    const fetchInitialData = async () => {
        if(stats) {
            setExemptStats(stats);
            buildExemptStatsText(stats);
        } else {
           const {data, errors} = await fetchExemptStats(regNumber, encodeURIComponent(customerId));
            if (data) {
                setExemptStats(data);
                buildExemptStatsText(data);
            }
            setErrors(errors);
        }
        setLoading(false);
    };

    const buildExemptStatsText = (data: ExemptStats): void => {
        const freeAndPreRegisteredTotal = data.freeTotal + (data.stats.PRE_REGISTERED? data.stats.PRE_REGISTERED.total : 0);
        if (!data.stats.EXEMPT) {
            setExemptStatsText(t("Exempt_Stats_Text", {exemptStats: data, exemptTotal: 0, freeAndPreRegisteredTotal: freeAndPreRegisteredTotal}));
        } else if (data.stats.EXEMPT.total === 1) {
            setExemptStatsText(t("Exempt_Stats_Text_Single", {exemptStats: data, freeAndPreRegisteredTotal: freeAndPreRegisteredTotal}));
        } else {
            setExemptStatsText(t("Exempt_Stats_Text", {exemptStats: data, exemptTotal: data.stats.EXEMPT.total, freeAndPreRegisteredTotal: freeAndPreRegisteredTotal}));
        }
    }

    return (
        <Box minHeight={'20px'} display="flex" justifyContent={alignment} alignItems={alignment}>
            {loading ? (
                <Skeleton animation="wave" variant="text" width={300} height={10}/>
            ) : !isEmptyArray(errors) ? (
                <Typography variant="caption" style={{color: theme.palette.error.main}} gutterBottom>
                    {t("Exempt_stats_failed_to_load")}
                </Typography>
            ) : (
                exemptStats && exemptStats.stats && (
                    <Typography variant="caption" gutterBottom>
                        {exemptStatsText}
                    </Typography>
                )
            )}
        </Box>
    );
};