import {Facility} from "../types/Facility";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import {Service} from "../types/Service";

export async function createFacility(facility: Facility): Promise<ApiResponseSingle<Facility>> {
    const apiUrl = `/api/facilities`;

    const options = {
        headers: getIdTokenHeader(),
        method: "POST",
        body: JSON.stringify(facility)
    };

    const response = fetchData<Facility>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Facility>>;
}

export async function updateFacility(id: string, facility: any): Promise<ApiResponseSingle<Facility>> {
    const apiUrl = `/api/facilities/${id}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'PUT',
        body: JSON.stringify(facility)
    };

    const response = fetchData<Facility>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Facility>>;
}

export async function fetchFacilityServices(facilityID: string): Promise<ApiResponse<Service>> {
    const apiUrl = `/api/facilities/${facilityID}/services`;
    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<Service>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<Service>(responseData, 'services')
    );
    return response as Promise<ApiResponse<Service>>;
}