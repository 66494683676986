import { t } from "i18next";
import { VehicleData } from "../types/VehicleData";

export const formatOwner = (vehicleData: VehicleData) => {
    if(!vehicleData) {
        return '';
    }
    let ownerString = t(vehicleData.ownerName);
    if(vehicleData.owner && vehicleData.owner.length > 0){
        return ownerString + ' (' + vehicleData.owner +')';
    }
    else {
        return ownerString;
    }
}

