import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import dayjs from "dayjs";
import 'dayjs/locale/sv'
import 'dayjs/locale/en';
import { HistoricalExemption } from "../../types/HistoricalExemption";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../Shared/Tables/CustomNoRowsOverlay";
import { BillableVisit } from "../../types/BillableVisit";
import { boxStyle } from "../Shared/Tables/listStyles";

const AssociatedExemptionsModal = ({open, loading, handleClose, data, formatReasonColumn, formatUpdatedColumn, formatExemptionIdColumn}:
    {
        open: boolean,
        loading?: boolean,
        handleClose:any,
        data: [BillableVisit|null, HistoricalExemption[]|null],
        formatReasonColumn: (params: GridValueGetterParams<any>) => string,
        formatUpdatedColumn: (params: GridValueGetterParams<any>) => string,
        formatExemptionIdColumn: (params: GridValueGetterParams<any>) => string
    }
) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [historicalExemptionItems, setHistoricalExemptionItems] = useState<HistoricalExemption[]|null>(null);
    const [visit, setVisit] = useState<BillableVisit|null>(null);

    useEffect(() => {
        if(data) {
            setStartData()
        }
    }, [data]);

    const setStartData = () => {
        const [visitItem, exemptionItems] = data;
        setHistoricalExemptionItems(exemptionItems);
        setVisit(visitItem);
    }

    useEffect(() => {
        if(historicalExemptionItems){
            setRows(historicalExemptionItems.map(generateRows));
        }
    }, [historicalExemptionItems])

    const columns: GridColDef[] = [
        {field: 'updated', headerName: t("Undantagen"), valueGetter: formatUpdatedColumn, minWidth: 50, maxWidth:150, flex: 1, disableColumnMenu: true},
        {field: 'updatedBy', headerName: t("Ändrad_av"), minWidth: 50, maxWidth:250, flex: 2, disableColumnMenu: true},
        {field: 'reason', headerName: t("Undantag"), valueGetter: formatReasonColumn, minWidth:50, maxWidth:200, flex:1, disableColumnMenu: true},
        {field: 'exemptionId', headerName: t("UndantagsId"), valueGetter: formatExemptionIdColumn, minWidth: 50, maxWidth:150, flex: 1, disableColumnMenu: true},
        {field: 'comment', headerName: t("Kommentar"), minWidth:50, maxWidth: 150, flex: 1, disableColumnMenu: true},
    ];

    const generateRows = (historicalExemptionItem: HistoricalExemption): any[] => {
        const rows: any[] = [{
            id: historicalExemptionItem.id, 
            facilityName: historicalExemptionItem.facilityName,
            regnumber: historicalExemptionItem.regnumberClear,
            exemptionId: historicalExemptionItem.exemptionId,
            reason: historicalExemptionItem.reason,
            comment: historicalExemptionItem.comment,
            updated: historicalExemptionItem.updated,
            updatedBy: historicalExemptionItem.updatedBy,
            company: historicalExemptionItem.company,
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };
    const [rows, setRows] = useState([].map(generateRows));

    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {minWidth: '60vw'},
                }}
            >
                <DialogTitle align="center" variant="h5">{t("Undantagshistorik") + ' ' + t("för") + ' ' + t("besök")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h6">{visit && dayjs(visit.vehicleData.sensorTime).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                            <Typography align="center" variant="h6">{historicalExemptionItems && historicalExemptionItems[0].facilityName.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="center" variant="h6">{t("Fordon")}:</Typography><Typography align="center" variant="h6"> {visit && visit.vehicleData.regnumberClear}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="center" variant="h6">{t("Företagsnamn")}:</Typography><Typography align="center" variant="body1"> {visit && t(visit.vehicleData.ownerName) +' (' + visit.vehicleData.owner + ')'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Box sx={boxStyle}>
                            <DataGrid
                                sx={{maxHeight : '55vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                                loading={loading}
                                rows={rows}
                                columns={columns}
                                getRowId={(row: any) => row.id}
                                initialState={{
                                    sorting: {sortModel: [{field: 'updated', sort: 'desc' }]}
                                }}
                                hideFooter={true}
                                hideFooterSelectedRowCount={true}
                                slots={{
                                    noRowsOverlay: CustomNoRowsOverlay,
                                }}
                            />
                        </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="error" onClick={() => {handleClose()}}>{t("Stäng")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default AssociatedExemptionsModal;