import React, {useState} from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import {Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useCurrentCustomer} from "../../contexts/CurrentCustomerContext";
import {BillableVisitType} from "../../types/enums/BillableVisitType";
import { CurrentCustomerProducts } from '../../types/CurrentCustomerProducts ';

export enum FilterType {
    TYPE_FILTER = "TYPE_FILTER",
    CUSTOM_FILTER = "CUSTOM_FILTER"
}

export interface FilterItem {
    filterType: FilterType;
    label: string;
    value: string | BillableVisitType;
}

interface ChipFilterProps {
    onSelectedFiltersChange: (selectedFilters: FilterItem[]) => void;
}

const ChipFilter: React.FC<ChipFilterProps> = ({onSelectedFiltersChange}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();

    const filtersArray: FilterItem[] = [
        {filterType: FilterType.TYPE_FILTER, label: "Fakturerade", value: BillableVisitType.INVOICE},
        {filterType: FilterType.TYPE_FILTER, label: "Återbesök", value: BillableVisitType.REVISIT},
        {filterType: FilterType.TYPE_FILTER, label: "Föranmälda", value: BillableVisitType.PRE_REGISTERED},
        {filterType: FilterType.TYPE_FILTER, label: "Undantagna", value: BillableVisitType.EXEMPT},
        {
            filterType: FilterType.TYPE_FILTER,
            label: "Gratislistade",
            value: BillableVisitType.FREE_VEHICLE + "," + BillableVisitType.FREE_ORGANIZATION
        },
        {filterType: FilterType.CUSTOM_FILTER, label: "Enskilda_Firmor", value: "isSoleTrader=true"},
        {filterType: FilterType.TYPE_FILTER, label: "Utländska", value: BillableVisitType.FOREIGN},
        {filterType: FilterType.CUSTOM_FILTER, label: "Organisationer", value: "isSoleTrader=false"},
        ...(
            currentCustomer.productNames.includes(CurrentCustomerProducts.MARKED_SERVICES)
                ? [{filterType: FilterType.CUSTOM_FILTER, label: "Flaggade", value: "isFlagged=true"}]
                : []
        )
    ];

    const sortFilters = (a: FilterItem, b:FilterItem): number => {
        return t(a.label).localeCompare(t(b.label));
    }
    
    const [filters, setFilters] = useState<FilterItem[]>(filtersArray.sort(sortFilters));
    const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([]);

    const handleChipClick = (filter: FilterItem): void => {
        setFilters((prevFilters) => prevFilters.filter((f) => f !== filter).sort(sortFilters));
        setSelectedFilters(prevSelectedFilters => [...prevSelectedFilters, filter].sort(sortFilters));
        onSelectedFiltersChange([...selectedFilters, filter]);
    };

    const handleDelete = (filter: FilterItem): void => {
        setSelectedFilters(prevSelectedFilters => prevSelectedFilters.filter(f => f !== filter).sort(sortFilters));
        setFilters((prevFilters) => [...prevFilters, filter].sort(sortFilters));
        onSelectedFiltersChange(selectedFilters.filter(f => f !== filter));
    };

    return (
        <Box>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item sx={{display: 'flex', gap: theme.spacing(1)}}>
                    <Typography variant={"subtitle1"} sx={{whiteSpace: 'nowrap'}}>
                        {t("Välj_Filter")}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center" useFlexGap flexWrap="wrap">
                        {filters.map((filter, index) => (
                            <Chip
                                key={index}
                                label={t(filter.label)}
                                onClick={() => handleChipClick(filter)}
                                size="small"
                            />
                        ))}
                    </Stack>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item sx={{display: 'flex', gap: theme.spacing(1)}}>
                    <Typography variant={"subtitle1"} sx={{whiteSpace: 'nowrap'}}>
                        {t("Aktiva_Filter")}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center" useFlexGap flexWrap="wrap">
                        {selectedFilters.map((filter, index) => (
                            <Chip
                                key={index}
                                label={t(filter.label)}
                                onDelete={() => handleDelete(filter)}
                                color="secondary"
                                size="small"
                            />
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChipFilter;
