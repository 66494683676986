import { Typography } from "@mui/material";
import ErrorPage from "./ErrorPage";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { PropsWithChildren } from "react";
import { t } from "i18next";

const Loading = () => {
    return <Typography variant="h6">{t("Autentisering pågår")}</Typography>
}

export function AuthenticatedPage({children}: PropsWithChildren) {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorPage} 
            loadingComponent={Loading}
        >
            {children}
        </MsalAuthenticationTemplate>
      )
}