import Box from "@mui/material/Box";
import { User } from "../../types/User";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useCallback, useState } from "react";
import {
    useTheme,
    Typography,
    Switch,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import { createUser, findUserByCustomerIdAndActiveIsAndUsernameLikeOrFullnameLike, findUserByCustomerIdAndUsernameLikeOrFullnameLike, updateUser } from "../../api/UserAPI";
import { useAsync } from "react-use";
import UserTable from "./UserTable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddUserModal from "./Modals/AddUserModal";
import GroupIcon from "@mui/icons-material/Group";
import SearchBar from "../Shared/SearchBar";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { isEmptyArray } from "../../helpers/IsEmptyHelpers";
import {ErrorResponse} from "../../api/apiUtils";
import { GridPaginationModel, GridRowModel, GridSortModel } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {TableSettings, TableStorage} from "../../types/settings/TableSettings";


export default function Users() {
    const {currentCustomer} = useCurrentCustomer();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'fullname', sort: 'asc'}])
    const [userItems, setUserItems] = useState<User[]>([]);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [changeData, setChangeData] = useState({});
    const [promiseArguments, setPromiseArguments] = useState<any>(null);

    const [adding, setAdding] = useState(false);
    const [addingError, setAddingError] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [hideInactive, setHideInactive] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const { t } = useTranslation();

    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setPaginationModel({...paginationModel, page: 0});
        setUserItems([]);
        setSearchQuery(search);
    };

    const handleHideActiveChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationModel({...paginationModel, page: 0});
        setUserItems([]);
        setHideInactive(e.target.checked);
    }

    const computeMutation = (newRow: GridRowModel, oldRow: GridRowModel):boolean => {
        if(newRow.fullname !== oldRow.fullname) {
            setChangeData({...changeData, [t("Namn")]: newRow.fullname});
            return true;
        }
        else if(newRow.active !== oldRow.active) {
            setChangeData({...changeData, [newRow.fullname]: newRow.active? t("Aktiv"): t("Inaktiv")});
            return true;
        }
        else if(newRow.expires !== oldRow.expires) {
            setChangeData({...changeData, [t("Giltig_till")]: newRow.expires && dayjs(newRow.expires).isValid() ? dayjs(newRow.expires).format('YYYY-MM-DD') : t("tillsvidare")});
            return true;
        }
        else {
            return false;
        }
    }

    const handleProcessRowUpdate = useCallback((
        newRow: GridRowModel, 
        oldRow: GridRowModel
    ) => 
        new Promise<GridRowModel>((resolve, reject) => {
            if(computeMutation(newRow, oldRow)) {
                setPromiseArguments({resolve, reject, newRow, oldRow});
            }
            else {
                resolve(oldRow);
            }
            setErrors([]);
        }
    ),[]);

    const onClose = () => {
        const {resolve, oldRow} = promiseArguments;
        resolve(oldRow);
        setPromiseArguments(null);
        setErrors([]);
        setChangeData({});
    }

    const addNewUser = async (newUser: User) => {
        try {
            setIsAdding(true);
            const {data, errors} = await createUser(newUser);
            if(isEmptyArray(errors)) {
                setLoadData(true);
                handleCloseAddUserDialog();
            }
            else {
                setAddingError(true);
            }
        } catch (error) {
            console.error('Error when creating user', error);
        }
        finally {
            setIsAdding(false);
        }
    };

    const handleOpenAddUserDialog = () => {
        setAdding(true);
        setAddingError(false);
    };

    const handleCloseAddUserDialog = () => {
        setAdding(false);
        setAddingError(false);
    }

    const onUpdateUser = async () => {
        const {resolve, newRow, oldRow} = promiseArguments;
        if (newRow.id) {
            const {data, errors} = await updateUser(newRow.id, {
                fullname: newRow.fullname, 
                active: newRow.active,
                expires: newRow.expires !== null ? dayjs(newRow.expires).format('YYYY-MM-DD'): null});
            if(isEmptyArray(errors)) {
                resolve(data);
                setPromiseArguments(null);
            }
            else {
                setErrors(errors);
                resolve(oldRow);
            }
        }
    };


    const {
        loading,
        error,
        value: totalElements,
    } = useAsync(async () => {
        let result;
        if(!hideInactive) {
            result = await findUserByCustomerIdAndUsernameLikeOrFullnameLike(
                currentCustomer.id.toString(),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        else if(hideInactive) {
            result = await findUserByCustomerIdAndActiveIsAndUsernameLikeOrFullnameLike(
                currentCustomer.id.toString(),
                true,
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        const {data, errors, page :{totalElements}} = result;
        setUserItems([...data]);
        setLoadData(false);
        return totalElements;
    }, [searchQuery, hideInactive, paginationModel, sortModel, loadData]);


    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <GroupIcon/>
                        <h2>{t("Användare")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Användare_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <SearchBar
                                label={t("Sök_Användare")}
                                onChange={handleSearchQueryChange}
                                searchQuery={searchQuery}
                                size={"medium"}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <FormGroup>
                                <FormControlLabel control={
                            <Switch
                                checked={hideInactive}
                                onChange={handleHideActiveChanged}
                            />} label={t("Dölj_Inaktiva")}/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                            <Button variant="outlined" color="inherit" onClick={handleOpenAddUserDialog}>{t("Lägg_till_ny_användare")}</Button>
                            <AddUserModal open={adding} isAdding={isAdding} addingError={addingError} handleClose={handleCloseAddUserDialog} handleConfirmChanges={addNewUser}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
                {error && <Typography variant="body1">{t("Något gick fel", {error})}</Typography>}
                {!error && (
                <Box sx={{maxHeight: '70%'}}>
                    <UserTable
                        userItems={userItems}
                        loading={loading}
                        totalElements={totalElements}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => {TableStorage.saveLocalStorage(newModel.pageSize);setPaginationModel(newModel)}}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        handleProcessRowUpdate={handleProcessRowUpdate}
                    />
                    <ConfirmationDialog
                        open={!!promiseArguments}
                        title={t("Spara_Användare")}
                        subtitle={t("Följande uppgifter kommer att ändras") + ":"}
                        content={changeData}
                        handleAgree={onUpdateUser}
                        handleClose={() => onClose()}
                        errors={errors}
                        active={false} 
                        list={[]}                    
                        />
                </Box>
            )}
                
        </Box>
    );
}