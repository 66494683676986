import React, {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField, {OutlinedTextFieldProps} from '@mui/material/TextField';
import {useTheme} from '@mui/material/styles';
import {Facility} from "../../types/Facility";
import {Context} from "node:vm";

interface FacilityAutocompleteProps {
    label: string;
    helperText?: string;
    size?: 'small' | 'medium';
    currentCustomer: Context,
    limitTags: number;
    onFacilitiesFilterChange: (facilities: Facility[]) => void;
    valueInitialized?: boolean;
    disabled?: boolean;
}

const FacilityAutocomplete: React.FC<FacilityAutocompleteProps> = ({
                                                                       label,
                                                                       helperText,
                                                                       size = 'medium',
                                                                       currentCustomer,
                                                                       limitTags,
                                                                       onFacilitiesFilterChange,
                                                                       valueInitialized = false,
                                                                       disabled = false
                                                                   }) => {
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>(
        valueInitialized? currentCustomer.facilities as unknown as Facility[] : []);
    const [facilitiesFilterInputValue, setFacilitiesInputValue] = useState('');
    const handleFacilitiesFilterChange = (newValue: Facility[]) => {
        setFacilitiesFilterValue(newValue);
        onFacilitiesFilterChange(newValue);
    };

    const theme = useTheme();

    return (
        <>
            {currentCustomer.facilities.length > 1 && (
                <Autocomplete
                    multiple
                    disabled={disabled}
                    selectOnFocus
                    clearOnBlur
                    size={size}
                    limitTags={limitTags}
                    fullWidth
                    id="multiple-facilities-select"
                    options={currentCustomer.facilities}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option.name}
                                size="small"
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    value={facilitiesFilterValue}
                    onChange={(e, newValue) => handleFacilitiesFilterChange(newValue)}
                    inputValue={facilitiesFilterInputValue}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onInputChange={(e, newInputValue) => setFacilitiesInputValue(newInputValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params as unknown as OutlinedTextFieldProps}
                            variant="outlined"
                            label={label}
                            helperText={helperText}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    background: theme.palette.common.white,
                                },
                                minWidth: {xs: 270},
                                borderRadius: "4px",
                            }}
                        />
                    )}
                />
            )}
        </>
    );
};

export default FacilityAutocomplete;
