import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { ExemptVehicle, rowToExemptVehicle } from '../../types/ExemptVehicle';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { updateExemptVehicle } from '../../api/ExemptVehicleAPI';
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { formatMultiFacilityColumn } from '../../helpers/ColumnFormatters';
import { useGridApiRef } from '@mui/x-data-grid';
import UpdateExemptVehicleModal from "./Modals/UpdateExemptVehicleModal";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import { Tooltip } from '@mui/material';
import { boxStyle, getDateVisitRowClassName, isActiveDate } from '../Shared/Tables/listStyles';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';

interface ExemptVehicleTableProps {
    exemptVehicleItems: ExemptVehicle[];
    loading: boolean;
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const ExemptVehicleTable = (({exemptVehicleItems, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange}: ExemptVehicleTableProps) => {
    const { t } = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const gridApiRef = useGridApiRef();
    const lastDayOfPrevMonth = dayjs().subtract(1, 'month').endOf('month');

    const [exemptVehicle, setExemptVehicle] = useState<ExemptVehicle>(rowToExemptVehicle({}));
    const [cancelling, setCancelling] = useState(false);
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const {currentCustomer} = useCurrentCustomer();

    const columns: GridColDef[] = [
        {field: 'validFrom', headerName: t("Från"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'validUntil', headerName: t("Till"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'link', headerName: "", width: 50, disableColumnMenu: true, sortable: false, hideSortIcons: true,
            renderCell: (params) => {
            return (
            <Tooltip title={t("Öppna i") + ' ' + t("Fordon_Historik")} sx={{cursor: "pointer"}}>
                <LocalShippingIcon fontSize="small" />
            </Tooltip>);
        }},
        {field: 'regnumberClear', headerName: t("Regnr_shortest"), minWidth: 50, maxWidth:125, flex: 1, disableColumnMenu: true},
        {field: 'ownerName', headerName: t("Företagsnamn"), minWidth: 50, maxWidth: 400, flex:1, disableColumnMenu: true},
        {field: 'vehicleOwner', headerName: t("Orgnr"), minWidth: 50, maxWidth:125, flex:1, disableColumnMenu:true},
        {field: 'facilityNames', headerName: t("Anläggnings_Namn"), sortable: false, valueGetter: formatMultiFacilityColumn, minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu: true},
        {field: 'comment', headerName: t("Kommentar"), minWidth: 50, flex:2, disableColumnMenu:true},
        {field: 'edit', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (canEdit(params.row) && <Tooltip title={t("Redigera")} sx={{cursor: "pointer"}}><EditIcon/></Tooltip>)
            }
        },
        {field: 'cancel', headerName:'', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (isActiveDate(params.row) && <Tooltip title={t("Inaktivera")} sx={{cursor: "pointer"}}><CancelIcon/></Tooltip>)
            }
        }
    ];

    const generateRows = (exemptVehicleItem: ExemptVehicle): any[] => {
        const rows: any[] = [{
            id: exemptVehicleItem.id, 
            validFrom: exemptVehicleItem.validFrom, 
            validUntil: exemptVehicleItem.validUntil,
            facilityNames: exemptVehicleItem.facilityNames,
            regnumberClear: exemptVehicleItem.regnumberClear,
            ownerName: t(exemptVehicleItem.ownerName),
            vehicleOwner: exemptVehicleItem.vehicleOwner,
            comment: exemptVehicleItem.comment
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const canEdit = (row):boolean => {
        if(!isActiveDate(row)) {
            const validUntil = row.validUntil;
            return dayjs(validUntil).isSame(lastDayOfPrevMonth, 'day');
        }
        else {
            return true;
        }
    };

    const setCancelValidUntilDate = ():string => {
        const fromDate = dayjs(exemptVehicle.validFrom);
        if(fromDate.isAfter(lastDayOfPrevMonth)) {
            return exemptVehicle.validFrom;
        }
        else {
            return lastDayOfPrevMonth.format('YYYY-MM-DD');
        }
    }

    const handleConfirmCancelItem = async() => {
        const {data, errors} = await updateExemptVehicle(
            exemptVehicle.id, 
            rowToExemptVehicle({validUntil: setCancelValidUntilDate()})
        );
        gridApiRef.current.updateRows([data]);
        setCancelling(false);
    };

    const handleCancelItem = (row: any) => {
        setExemptVehicle(row);
        setCancelling(true);
    }

    const handleConfirmClose = () => {
        toggleHidden();
        setCancelling(false);
    };


    useEffect(() => {
        setRows(exemptVehicleItems.map(generateRows));
    }, [exemptVehicleItems]);

    const handleConfirmUpdateItem = async(updatedItem: ExemptVehicle) => {
        const {data, errors} = await updateExemptVehicle(exemptVehicle.id, updatedItem);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    const handleClose = () => {
        setUpdating(false);
    };

    const [rows, setRows] = useState(() => exemptVehicleItems.map(generateRows));

    const handleCellClick = (params:any) => {
        if(params.field === 'cancel' && isActiveDate(params.row)) {
            handleCancelItem(params.row);
        } else if(params.field === 'edit' && canEdit(params.row)) {
            setUpdating(true);
            setExemptVehicle(rowToExemptVehicle(params.row));
        }
        else if(params.field === 'link') {
            navigate(`/vehiclehistory/${currentCustomer.id}?regnumber=${params.row.regnumberClear}`);
        }
        else if(params.field === 'regnumberClear') {
            //Do Nothing
        }
    }

    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    getRowClassName={getDateVisitRowClassName}
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    hideFooter={false}
                    rowSelection={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdateExemptVehicleModal open={updating} handleClose={handleClose} handleConfirmChanges={handleConfirmUpdateItem} data={exemptVehicle}/>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={cancelling}
                handleClose={() => confirmationDialog.handleClose(handleConfirmClose)}
                handleAgree={() => confirmationDialog.handleAgree(handleConfirmCancelItem)}
                title={t("Inaktivera_Gratis_Fordon")}
                subtitle={exemptVehicle.regnumberClear + ' ' + t("kommer_att_inaktiveras") + ' ' + t("Tidigare_besök_uppdateras")}
                content={{}}
                errors={null}
                list={[]}
                active={false}
                addtionalConfirmationText={t("Jag_inaktiverar") + ' ' + exemptVehicle.regnumberClear}
            />
        </Box>
);
});
export default ExemptVehicleTable;