import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { MarkedOrganization, rowToMarkedOrganization } from '../../types/MarkedOrganization';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { formatMultiFacilityColumn } from '../../helpers/ColumnFormatters';
import { updateMarkedOrganization } from "../../api/MarkedOrganizationAPI";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGridApiRef } from '@mui/x-data-grid';
import UpdateMarkedOrganizationModal from "./Modals/UpdateMarkedOrganizationModal";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { boxStyle, getDateVisitRowClassName, isActiveDate } from '../Shared/Tables/listStyles';

interface MarkedOrganizationTableProps {
    markedOrganizationItems: MarkedOrganization[];
    loading: boolean;
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel,
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const MarkedOrganizationTable = (({markedOrganizationItems, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange}: MarkedOrganizationTableProps) => {
    const { t } = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const gridApiRef = useGridApiRef();

    const today = dayjs().endOf("day");
    const [markedOrganization, setMarkedOrganization] = useState<MarkedOrganization>(rowToMarkedOrganization({}));
    const [cancelling, setCancelling] = useState(false);
    const [updating, setUpdating] = useState(false);

    const columns: GridColDef[] = [
        {field: 'validFrom', headerName: t("Från"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'validUntil', headerName: t("Till"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'orgnumber', headerName: t("Orgnr"), minWidth: 50, maxWidth:125, flex: 1, disableColumnMenu: true},
        {field: 'name', headerName: t("Namn"), minWidth:50, maxWidth:400, flex:1, disableColumnMenu: true},
        {field: 'parentOrgnumber', headerName: t("Moder"), minWidth: 50, maxWidth: 125, flex: 1, disableColumnMenu: true},
        {field: 'facilityNames', headerName: t("Anläggnings_Namn"), sortable: false, valueGetter: formatMultiFacilityColumn, minWidth: 50, maxWidth: 250, flex:1, disableColumnMenu: true},
        {field: 'title', headerName: t("Rubrik"), minWidth:50, maxWidth: 250, flex: 1, disableColumnMenu: true},
        {field: 'text', headerName: t("Beskrivning"), minWidth:50, flex:1, disableColumnMenu: true},
        {field: 'edit', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (isActiveDate(params.row) &&<Tooltip title={t("Redigera")} sx={{cursor: "pointer"}}><EditIcon/></Tooltip>)
            }
        },
        {field: 'cancel', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (isActiveDate(params.row) && <Tooltip title={t("Inaktivera")} sx={{cursor: "pointer"}}><CancelIcon/></Tooltip>)
        }}
    ];

    const generateRows = (markedOrganizationItem: MarkedOrganization): any[] => {
        const rows: any[] = [{
            id: markedOrganizationItem.id, 
            validFrom: markedOrganizationItem.validFrom, 
            validUntil: markedOrganizationItem.validUntil,
            facilityNames: markedOrganizationItem.facilityNames,
            orgnumber: markedOrganizationItem.orgnumber,
            name: markedOrganizationItem.name,
            parentOrgnumber : markedOrganizationItem.parentOrgnumber,
            title: markedOrganizationItem.title,
            text: markedOrganizationItem.text
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const setCancelValidUntilDate = ():string => {
        const fromDate = dayjs(markedOrganization.validFrom);
        if(fromDate.isAfter(today)) {
            return markedOrganization.validFrom;
        }
        else {
            return today.format('YYYY-MM-DD');
        }
    }

    const handleConfirmCancelItem = async() => {
        const {data, errors} = await updateMarkedOrganization(
            markedOrganization.id,
            rowToMarkedOrganization({validUntil: setCancelValidUntilDate()})
        );
        gridApiRef.current.updateRows([data]);
        setCancelling(false);
    };

    const handleCancelItem = (row: any) => {
        setMarkedOrganization(row);
        setCancelling(true);
    }

    const handleConfirmClose = () => {
        toggleHidden();
        setCancelling(false);
    };

    useEffect(() => {
        setRows(markedOrganizationItems.map(generateRows));
    },[markedOrganizationItems]);

    const handleConfirmChanges = async(updatedItem) => {
        const {data, errors} = await updateMarkedOrganization(markedOrganization.id, updatedItem);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    const handleClose = () => {
        setUpdating(false);
    };

    const [rows, setRows] = useState(() => markedOrganizationItems.map(generateRows));

    const handleCellClick = (params:any) => {
        if(params.field === 'cancel' && isActiveDate(params.row)) {
            handleCancelItem(params.row);
        } else if(params.field === 'edit' && isActiveDate(params.row)) {
            setUpdating(true);
            setMarkedOrganization(rowToMarkedOrganization(params.row));
        }
    }

    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    getRowClassName={getDateVisitRowClassName}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    rowSelection={false}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdateMarkedOrganizationModal open={updating} handleClose={handleClose} handleConfirmChanges={handleConfirmChanges} data={markedOrganization}/>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={cancelling}
                handleClose={() => confirmationDialog.handleClose(handleConfirmClose)}
                handleAgree={() => confirmationDialog.handleAgree(handleConfirmCancelItem)}
                title={t("Inaktivera_Markerad_Organisation")}
                subtitle={markedOrganization.orgnumber + ' (' + t(markedOrganization.name) + ') ' + t("kommer_att_inaktiveras") + ' ' + t("Tidigare_flaggning_påverkas_inte")}
                content={{}}
                errors={null}
                list={[]}
                active={false}
            />
        </Box>
);
});
export default MarkedOrganizationTable;