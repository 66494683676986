import {Service} from "./Service";

export interface Facility {
    id: string,
    created: string,
    updated: string,
    name: string,
    latitude: string,
    longitude: string,
    active: any,
    services: Service[]
}

export const toFacility= (data: any) => {
    const {
        name,
        active,
        services,
        _links: {
            self: {
                href,
            }
        }
    } = data;
    const id = href.slice(href.lastIndexOf('/') + 1);
    return {
        id,
        name,
        active,
        services,
    } as Facility;
}