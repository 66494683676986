import OnlinePredictionRoundedIcon from "@mui/icons-material/OnlinePredictionRounded";
import Chip, {ChipProps} from "@mui/material/Chip";
import * as React from "react";
import {GridRenderCellParams } from "@mui/x-data-grid";
import useTheme from "@mui/system/useTheme";

export const StatusIcon = (params: GridRenderCellParams) => {
    const theme = useTheme();

    function getChipProps(row: any): ChipProps {
        if (row.status === true ) {
            return {
                icon: <OnlinePredictionRoundedIcon sx={{  "&&": { color: theme.palette.success.main } }}></OnlinePredictionRoundedIcon>,
                variant: "outlined",
                style:{
                    border:"none",
                }
            }
        }if (row.status === false) {
            return {
                icon: <OnlinePredictionRoundedIcon sx={{  "&&": { color: theme.palette.error.main } }}></OnlinePredictionRoundedIcon>,
                variant: "outlined",
                style:{
                    border:"none"
                }
            };
        }if (row.status === "neutral") {
            return {
                icon: <OnlinePredictionRoundedIcon sx={{  "&&": { color: theme.palette.info.main } }}></OnlinePredictionRoundedIcon>,
                variant: "outlined",
                style:{
                    border:"none"
                }
            };
        }else {
            return {
                icon: <OnlinePredictionRoundedIcon sx={{  "&&": { color: theme.palette.error.main } }}></OnlinePredictionRoundedIcon>,
                variant: "outlined",
                style:{
                    border:"none"
                }
            };
        }
    }

    return <Chip variant="outlined" {...getChipProps(params)} />;
};