import {useState} from "react";
import {useMsal} from "@azure/msal-react";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {useTranslation} from "react-i18next";
import { Typography } from "@mui/material";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";

export const SignOutButton = () => {
    const {instance} = useMsal();
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {currentUser, setCurrentUser} = useCurrentUser();
    const {setCurrentCustomer} = useCurrentCustomer();

    const handleLogout = (logoutType: string) => {
        setAnchorEl(null);
        setCurrentCustomer({})
        setCurrentUser({});

        if (logoutType === "popup") {
            instance.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    return (
        <div>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
            >
                {currentUser && currentUser.isAuthorized ?
                <Typography variant="caption">{currentUser.fullName}</Typography>
                :
                <Typography variant="caption">{t("Logout_Redirect")}</Typography>
                }
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogout("redirect")}
                          key="logoutRedirect">{t("Logout_Redirect")}</MenuItem>
                        
            </Menu>
        </div>
    )
};