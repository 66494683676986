import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { AdditionalVehicle, rowToAdditionalVehicle } from '../../types/AdditionalVehicle';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { updateAdditionalVehicle } from '../../api/AdditionalVehicleAPI';
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGridApiRef } from '@mui/x-data-grid';
import { formatMultiFacilityColumn } from '../../helpers/ColumnFormatters';
import UpdateAdditionalVehicleModal from "./Modals/UpdateAdditionalVehicleModal";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import { Tooltip } from '@mui/material';
import { boxStyle, getDateVisitRowClassName, isActiveDate } from '../Shared/Tables/listStyles';
import dayjs from 'dayjs';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { useNavigate } from 'react-router-dom';

interface AdditionalVehicleTableProps {
    additionalVehicleItems: AdditionalVehicle[];
    loading: boolean;
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPageModelChange: (newPageModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const AdditionalVehicleTable = (({additionalVehicleItems, loading, totalElements, paginationModel, onPageModelChange, sortModel, onSortModelChange}: AdditionalVehicleTableProps) => {
    const { t } = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const gridApiRef = useGridApiRef();
    const today = dayjs().endOf('day');

    const [additionalVehicle, setAdditionalVehicle] = useState<AdditionalVehicle>(rowToAdditionalVehicle({}));
    const [cancelling, setCancelling] = useState(false);
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const {currentCustomer} = useCurrentCustomer();

    const columns: GridColDef[] = [
        {field: 'validFrom', headerName: t("Från"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'validUntil', headerName: t("Till"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'link', headerName: "", width: 50, disableColumnMenu: true, sortable: false, hideSortIcons: true,
            renderCell: (params) => {
            return (
            <Tooltip title={t("Öppna i") + ' ' + t("Fordon_Historik")} sx={{cursor: "pointer"}}>
                <LocalShippingIcon fontSize="small" />
            </Tooltip>);
        }},
        {field: 'regnumberClear', headerName: t("Regnr_shortest"), minWidth: 50, maxWidth:125, flex: 1, disableColumnMenu: true},
        {field: 'ownerName', headerName: t("Företagsnamn"), minWidth: 50, maxWidth: 400, flex:1, disableColumnMenu: true},
        {field: 'vehicleOwner', headerName: t("Orgnr"), minWidth: 50, maxWidth:125, flex:1, disableColumnMenu:true},
        {field: 'facilityNames', headerName: t("Anläggnings_Namn"), sortable: false, valueGetter: formatMultiFacilityColumn, minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu: true},
        {field: 'comment', headerName: t("Kommentar"), minWidth: 50, flex:2, disableColumnMenu:true},
        {field: 'edit', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (canEdit(params.row) &&<Tooltip title={t("Redigera")} sx={{cursor: "pointer"}}><EditIcon/></Tooltip>)
            }
        },
        {field: 'cancel', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (canCancel(params.row) && <Tooltip title={t("Inaktivera")} sx={{cursor: "pointer"}}><CancelIcon/></Tooltip>)
            }
        }
    ];
    
    const generateRows = (additionalVehicleItem: AdditionalVehicle): any[] => {
        const rows: any[] = [{
            id: additionalVehicleItem.id, 
            validFrom: additionalVehicleItem.validFrom, 
            validUntil: additionalVehicleItem.validUntil,
            facilityNames: additionalVehicleItem.facilityNames,
            regnumberClear: additionalVehicleItem.regnumberClear,
            ownerName: t(additionalVehicleItem.ownerName),
            vehicleOwner: additionalVehicleItem.vehicleOwner,
            comment: additionalVehicleItem.comment
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const handleConfirmCancelItem = async() => {
        const {data, errors} = await updateAdditionalVehicle(
            additionalVehicle.id,
            rowToAdditionalVehicle({validUntil: today.format('YYYY-MM-DD')})
        );
        gridApiRef.current.updateRows([data]);
        setCancelling(false);
    };

    const handleCancelItem = (row: any) => {
        setAdditionalVehicle(row);
        setCancelling(true);
    }

    const handleConfirmClose = () => {
        toggleHidden();
        setCancelling(false);
    };

    const handleConfirmUpdateItem = async(updatedItem) => {
        const {data, errors} = await updateAdditionalVehicle(additionalVehicle.id, updatedItem);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    useEffect(() => {
        setRows(additionalVehicleItems.map(generateRows));
    }, [additionalVehicleItems]);

    const handleClose = () => {
        setUpdating(false);
    };

    const [rows, setRows] = useState(() => additionalVehicleItems.map(generateRows));

    const canCancel = (row) => {
        return isActiveDate(row) || row.validUntil == null || !dayjs(row.validUntil).isBefore(today);
    }

    const canEdit = (row) => {
        return isActiveDate(row) || row.validUntil == null || !dayjs(row.validUntil).isBefore(today);
    }

    const handleCellClick = (params:any) => {
        if(params.field === 'cancel' && canCancel(params.row)) {
            handleCancelItem(params.row);
        } else if(params.field === 'edit' && canEdit(params.row)){
            setUpdating(true);
            setAdditionalVehicle(rowToAdditionalVehicle(params.row));
        }
        else if(params.field === 'link') {
            navigate(`/vehiclehistory/${currentCustomer.id}?regnumber=${params.row.regnumberClear}`);
        }
        else if(params.field === 'regnumberClear') {
            //Do Nothing
        }
    }

    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    getRowClassName={getDateVisitRowClassName}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPageModelChange}
                    sortModel={sortModel}
                    sortingMode='server'
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    rowSelection={false}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdateAdditionalVehicleModal open={updating} handleClose={handleClose} handleConfirmChanges={handleConfirmUpdateItem} data={additionalVehicle}/>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={cancelling}
                handleClose={() => confirmationDialog.handleClose(handleConfirmClose)}
                handleAgree={() => confirmationDialog.handleAgree(handleConfirmCancelItem)}
                title={t("Inaktivera_Extra_Fordon")}
                subtitle={additionalVehicle.regnumberClear + ' ' + t("kommer_att_inaktiveras") + ' ' + t("Tidigare_besök_påverkas_inte")}
                content={{}}
                errors={null}
                list={[]}
                active={false}
            />
        </Box>
);
});
export default AdditionalVehicleTable;