import dayjs from "dayjs";

export const FormatStringYYYYmmdd = (input: any) => {

    if(input && (Date.parse(input) !== Date.parse(new Date(0).toString()))){
        return new Intl.DateTimeFormat('sv-SE', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(input)
    }

    return '';
}

export const roundToMinutes = (time: dayjs.Dayjs, roundTo: number) => {
    const roundedMinute = Math.round(time.minute()/roundTo) * roundTo;
    return time.startOf('hour').add(roundedMinute, 'minutes');
}
