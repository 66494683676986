import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "./App.css";
import {CssBaseline, ThemeProvider, createTheme} from "@mui/material";
import {grey } from "@mui/material/colors";
import {svSE, enUS} from "@mui/material/locale";
import {useTranslation} from "react-i18next";
import ErrorPage from "./pages/ErrorPage";
import HeaderContainer from "./components/Header/HeaderContainer";
import './i18n';
// MSAL imports
import {
    IPublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider} from "@azure/msal-react";
import { Home} from "./components/Home";
import { CustomerPage} from "./pages/CustomerPage";
import { CustomersPage} from "./pages/CustomersPage";
import { RealTimeView} from "./pages/RealTimeView";
import { SettingsView} from "./pages/SettingsView";
import { MarkedVehiclesPage } from "./pages/MarkedVehiclesPage";
import { MarkedOrganizationsPage } from "./pages/MarkedOrganizationsPage";
import { BillingPage} from "./pages/BillingPage";
import { ExemptVehiclesPage } from "./pages/ExemptVehiclesPage";
import { ExemptOrganizationsPage } from "./pages/ExemptOrganizationsPage";
import { AdditionalVehiclesPage } from "./pages/AdditionalVehiclesPage";
import { PricePeriodPage } from "./pages/PricePeriodPage";
import { HistoricalExemptionPage } from "./pages/HistoricalExemptionPage";
import { UsersPage } from "./pages/UsersPage";
import { VehicleHistoryPage } from "./pages/VehicleHistoryPage";
import { ReportPage } from "./pages/ReportPage";
import { PreRegistrationPage } from "./pages/PreRegistrationPage";
import { HelpView } from "./pages/HelpView";
import { ProblemReportView } from "./pages/ProblemReportView";
import ArrivalHistory from "./components/ArrivalHistory/ArrivalHistory";
import { FacilitySelectionView } from "./pages/FacilitySelectionView";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HeaderContainer/>,
        children: [
            { path: "/", element: <Home/>},
            { path: "/customers", element: <CustomersPage/>},
            { path: "/customers/:id", element: <CustomerPage/>},
            { path: "/billing", element: <BillingPage/>},
            { path: "/realtimeview", element: <RealTimeView/>},
            { path: "/realtimeview/facilityselection", element: <FacilitySelectionView/>},
            { path: "/realtimeview/settings", element: <SettingsView/> },
            { path: "/realtimeview/help", element: <HelpView/> },
            { path: "/realtimeview/report", element: <ProblemReportView/> },
            { path: "/flaggedvehicle/:customerId", element: <MarkedVehiclesPage/> },
            { path: "/flaggedorg/:customerId", element: <MarkedOrganizationsPage/>},
            { path: "/freevehicle/:customerId", element: <ExemptVehiclesPage/>},
            { path: "/freeorg/:customerId", element: <ExemptOrganizationsPage/>},
            { path: "/additionalvehicle/:customerId", element: <AdditionalVehiclesPage/>},
            { path: "/fees/:customerId", element: <PricePeriodPage/>},
            { path: "/exemptionhistory", element: <HistoricalExemptionPage/>},
            { path: "/users/:customerId", element: <UsersPage/>},
            { path: "/reports", element: <ReportPage/>},
            { path: "/vehiclehistory/:customerId", element: <VehicleHistoryPage/>},
            { path: "/preregistration/:customerId", element: <PreRegistrationPage/>},
            { path: "/arrivalhistory/:customerId", element: <ArrivalHistory/>}
    ],
        errorElement: <ErrorPage/>
    },
]);

const getMuiLanguage = (language: string) => {
    switch (language) {
        case 'en-UK':
        case 'en-US':
        case 'en':
            return enUS;
        default:
            return svSE;
    }
}

type AppProps = {
    pca: IPublicClientApplication;
};

function App({pca}: AppProps) {
    const {i18n} = useTranslation();

    const myTheme = createTheme({
            components: {
            // Overrides default main padding on smaller screens.
            MuiCssBaseline: {
                    styleOverrides: (theme) => `
                    @media (max-width: ${theme.breakpoints.values.sm}px) {
                      main {
                        padding: 0 !important;
                      }
                    }
                  `,
                },
            },
            palette: {
                primary: {
                    main: grey[500],
                    dark: grey[700],
                },
                secondary: {
                    main: grey[700],
                    contrastText: grey[100]
                },
                background: {
                    default: "#f7f7e8"
                },
            },
        },
        getMuiLanguage(i18n.language)
    );

    return (
        <MsalProvider instance={pca}>
            <CssBaseline/>
            <ThemeProvider theme={myTheme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </MsalProvider>
    );
}

export default App;
