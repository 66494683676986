import React, {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {Chip, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { DatePicker, DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import Autocomplete from "@mui/material/Autocomplete";
import { OutlinedTextFieldProps, Typography } from "@mui/material";
import { isVehicleBillable, additionalVehicleExists } from "../../../api/AdditionalVehicleAPI";
import { useAsync } from "react-use";
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import { ExistsInfo } from "../../../types/ExistsInfo";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";

const AddAdditionalVehicleModal = ({open, handleClose, handleConfirmChanges}) => {
    const {currentCustomer} = useCurrentCustomer();
    const {t, i18n} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [commentCharCount, setCommentCharCount] = useState(0);
    const maxCommentChars = 250;

    const [regNumberHelpText, setRegNumberHelpText] = useState("");
    const [facilityValues, setFacilityValues] = useState<{id:number, name:string}[]>(
        currentCustomer.facilities
    );
    const [facilityInputValue, setFacilityInputValue] = useState("");

    const tomorrow = dayjs().add(1, 'day').startOf('day');
    const [fromDate, setFromDate] = useState(tomorrow);
    const [toDate, setToDate] = useState<dayjs.Dayjs | null>(null);
    const [fromDateError, setFromDateError] = useState<DateValidationError|null>(null);
    const [toDateError, setToDateError] = useState<DateValidationError|null>(null);
    const [infoData, setInfoData] = useState({
        [t("Anläggning")]: currentCustomer.facilities.map(f=> f.name).toString(),
        [t("Startdatum")]: tomorrow.format('YYYY-MM-DD'),
        [t("Slutdatum")]: t("Tillsvidare")
    });
    const [formData, setFormData] = useState({
        regnumberClear: '',
        comment: '',
        facilities: currentCustomer.facilities.map(f=> `/facilities/${f.id}`),
        validFrom: tomorrow.format('YYYY-MM-DD'),
        validUntil: null
    });

    const [errors, setErrors] = useState({
        regnumberClear: {value: false, message: ''},
        facilities: {value: false, message: ''},
        comment: {value: false, message: ''},
        validFrom: {value: false, message: ''},
        validUntil: {value: false, message: ''}
    });

    const validRegnumber = (value:string) => {
        return /^[A-Z]{3}[0-9]{2}[A-Z0-9]{1}$/.test(value.replaceAll(" ", "").trim().toUpperCase());
    }

    const validateForm = () => {
        const regNumberValid = validRegnumber(formData.regnumberClear);
        const commentValid = formData.comment.length <= maxCommentChars;
        const validFromValid = formData.validFrom && dayjs(formData.validFrom, "YYYY-MM-DD", true).isValid() && !dayjs(formData.validFrom).isBefore(tomorrow);
        const validUntilValid = formData.validUntil !== 'invalid' || (formData.validUntil && dayjs(formData.validUntil, 'YYYY-MM-DD', true).isValid() && !dayjs(formData.validUntil).isBefore(fromDate));
        const facilitiesValid = formData.facilities.length > 0;

        setErrors({
            regnumberClear: {value: !regNumberValid, message: regNumberValid ? '' : 'Ogiltigt_Regnr'},
            facilities: {value: !facilitiesValid, message: facilitiesValid? '' : "Anläggning_krävs"},
            comment: {value: !commentValid, message: commentValid ? '' : 'För lång'},
            validFrom: {value: !validFromValid, message: validFromValid ? '' : 'Ogiltigt_datum'},
            validUntil: {value: !validUntilValid, message: validUntilValid ? '' : "Ogiltigt_datum"}
        });

        return regNumberValid && commentValid && validFromValid && validUntilValid && facilitiesValid;
    };

    const handleFromDateChange = (newValue) => {
        setFromDate(newValue);
        setInfoData({...infoData, [t("Startdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD') : ''});
        setFormData({...formData, validFrom: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD') : 'invalid') : null});
    }

    const handleToDateChange = (newValue) => {
        setToDate(newValue);
        setInfoData({...infoData, [t("Slutdatum")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM-DD') : t("Tillsvidare")});
        setFormData({...formData, validUntil: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM-DD') : 'invalid') : null});
    }

    const handleFacilitySelectChange = (newValue:{id:number, name:string}[]) => {
        setFacilityValues(newValue);
        if(newValue){
            setInfoData({...infoData, [t("Anläggning")]: newValue.map(v => v.name).toString()})
        }
        setFormData({...formData, facilities: newValue ? newValue.map(v => `/facilities/${v.id}`) : []});
        setErrors({...errors,
            facilities: {value: newValue.length < 1, message: newValue.length < 1 ? "Anläggning_krävs" : ''}
        })
    }

    const handleInputChange = (e) => {
        if(e.target.id === 'comment') {
            setFormData({...formData, [e.target.id]: e.target.value});
            setInfoData({...infoData, [e.target.name]: e.target.value});
                setErrors({...errors,
                comment: {value: (e.target.value.length > maxCommentChars), message: 'Kommentar får inte vara för lång'}
            });
            setCommentCharCount(e.target.value.length);
        } else if(e.target.id === 'regnumberClear') {
            let regNr = e.target.value.toUpperCase();
            setFormData({...formData, [e.target.id]: regNr});
            setInfoData({...infoData, [e.target.name]: regNr});
                if(e.target.value.replaceAll(" ", "").length >= 6) {
                let valid = validRegnumber(e.target.value.replaceAll(" ", ""));
                setErrors({...errors,
                    regnumberClear: {value: !valid, message: valid? '' : 'Ogiltigt_Regnr'}
                })
            }
            else {
                setErrors({...errors,
                    regnumberClear: {value: false, message: ''}
                })
            }
        }
    };

    const checkCanBeExtra = useAsync(async () => {
        let canBeExtra:boolean = false;
        if(formData.regnumberClear.replaceAll(" ", "").length >= 6) {
            if(validRegnumber(formData.regnumberClear)) {
                let {data, errors} = await isVehicleBillable(formData.regnumberClear);
                if(!errors || errors.length === 0) {
                    let billableInfoText:string = formData.regnumberClear.toUpperCase()
                    + t("Fordonstyp_Ägartyp", {vehicleType: data.vehicleType, ownerType: data.ownerType});
                    if(data.usageType === "RENTAL"){
                        billableInfoText += t("Användning_Hyrbil")
                    }
                    if(data.canBeExtra) {
                        billableInfoText += t("Kan_bli_Fakturerbar")
                    }
                    if(!data.canBeExtra) {
                        billableInfoText += t("Ej_Extra_Fordon")
                    }
                    canBeExtra = data.canBeExtra;
                    setRegNumberHelpText(billableInfoText);
                }
            }
        }
        else {
            setRegNumberHelpText("");
        }
        return canBeExtra;
    },[formData.regnumberClear]);

    const checkExists = useAsync(async () => {
        let exists: ExistsInfo = {existsInAdditionalList: false, existsInExemptList: false, existsInMarkedList: false, existsInPreRegistrationList: false}
        if(validRegnumber(formData.regnumberClear)) {
            if(facilityValues && fromDate && fromDate.isValid()) {
                let {data, errors} = await additionalVehicleExists(formData.regnumberClear, facilityValues.map(v => v.id), formData.validFrom, formData.validUntil);
               if(!errors || errors.length === 0) {
                    exists = data;
                }
            }
            else {
                exists = {existsInAdditionalList: false, existsInExemptList: false, existsInMarkedList: false, existsInPreRegistrationList: false};
            }
            let errormessage:string = '';
            if(exists.existsInAdditionalList) {
                errormessage = "Extralistan_Finns_Redan_i_Extralistan";
            }
            setErrors({...errors,
                regnumberClear: {value: exists.existsInAdditionalList, message: errormessage}
            });
        }
        return exists.existsInAdditionalList;
    },[facilityValues, formData.regnumberClear, formData.validFrom, formData.validUntil]);

    const handleSubmit = () => {
        if (validateForm() && checkExists) {
            toggleHidden();
            confirmationDialog.handleOpen();    
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        clearFormData();
        toggleHidden();
    };

    const clearFormData = () => {
        setFormData({
            regnumberClear: '',
            comment: '',
            facilities: currentCustomer.facilities.map(f=> `/facilities/${f.id}`),
            validFrom: tomorrow.format('YYYY-MM-DD'),
            validUntil: null 
        });

        setInfoData({
            [t("Anläggning")]: currentCustomer.facilities.map(f => f.name).toString(),
            [t("Startdatum")]: tomorrow.format('YYYY-MM-DD'),
            [t("Slutdatum")]: t("Tillsvidare")
        });
        setFacilityValues(currentCustomer.facilities);
        setFacilityInputValue("");
        setCommentCharCount(0);
        setFromDate(tomorrow);
        setToDate(null);
        setRegNumberHelpText("");

        setErrors({
            regnumberClear: {value: false, message: ''},
            facilities: {value: false, message: ''},
            comment: {value: false, message: ''},
            validFrom: {value: false, message: ''},
            validUntil: {value: false, message: ''}
        });
    };

    const fromDateErrorString = useMemo(() => {
        switch(fromDateError) {
            case 'invalidDate':
            case 'maxDate':
            case 'minDate': {
                setErrors({...errors, validFrom: {value: true, message: "Ogiltigt_datum"}});
                return t("Ogiltigt_datum");
            }
            default: {
                setErrors({...errors, validFrom: {value: false, message: ''}});
                return '';
            }
        }
    }, [fromDateError]);

    const toDateErrorString = useMemo(() => {
        switch(toDateError) {
            case 'invalidDate':
            case 'maxDate':
            case 'minDate': {
                setErrors({...errors, validUntil: {value: true, message: "Ogiltigt_datum"}});
                return t("Ogiltigt_datum");
            }
            default: {
                setErrors({...errors, validUntil: {value: false, message: ''}});
                return '';
            }
        }
    }, [toDateError]);

    const checkDisabled = () => {
        return checkExists.value || 
        !checkCanBeExtra.value ||
        !validRegnumber(formData.regnumberClear) ||
        errors.facilities.value ||
        fromDateError != null || 
        toDateError != null ||
        errors.comment.value;
    }

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle sx={{xs:12}} variant="h5">{t("Nytt_Extra_Fordon")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <TextField
                                id="regnumberClear"
                                name={t("Regnr")}
                                label={t("Regnr")}
                                size="small"
                                required
                                value={formData.regnumberClear}
                                onChange={handleInputChange}
                                error={errors.regnumberClear.value}
                                helperText={errors.regnumberClear.value ? t(errors.regnumberClear.message) : t(regNumberHelpText)}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {currentCustomer.facilities.length > 1 &&
                            <Autocomplete
                                selectOnFocus
                                multiple
                                clearOnBlur
                                size="small"
                                id="facility-select"
                                limitTags={2}
                                options={currentCustomer.facilities}
                                getOptionLabel={(option) => option.name}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                    ))
                                }
                                value={facilityValues}
                                onChange={(e, newValue) => handleFacilitySelectChange(newValue)}
                                inputValue={facilityInputValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onInputChange={(e, newInputValue) => setFacilityInputValue(newInputValue)}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params as unknown as OutlinedTextFieldProps} 
                                    variant="outlined"
                                    error={errors.facilities.value}
                                    label={t("Välj_Anläggning")}
                                    helperText={errors.facilities.value ? t(errors.facilities.message): ''}
                                    />
                                )}
                                sx={{
                                    background: "#FFF",
                                    borderRadius: "4px",
                                }}
                            />
                            }
                            {currentCustomer.facilities.length === 1 &&
                            <Typography variant="subtitle1">{currentCustomer.facilities[0].name}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comment"
                                name={t("Kommentar")}
                                label={t("Kommentar")}
                                size="small"
                                value={formData.comment}
                                onChange={handleInputChange}
                                fullWidth
                                error={errors.comment.value}
                                helperText={commentCharCount + '/' + maxCommentChars + ' ' + (errors.comment.value ? t(errors.comment.message) : '')}
                                autoComplete="off"
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <Grid item xs={6}>
                                <DatePicker
                                    label={t("Startdatum")}
                                    onChange={(handleFromDateChange)}
                                    onError={(error) => setFromDateError(error)}
                                    minDate={tomorrow}
                                    value={fromDate}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            helperText: fromDateErrorString
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    label={t("Slutdatum")}
                                    value={toDate}
                                    format="YYYY-MM-DD"
                                    onChange={handleToDateChange}
                                    onError={(error) => setToDateError(error)}
                                    minDate={fromDate}
                                    slotProps={{
                                        textField: {
                                            helperText: toDateError? toDateErrorString : t("Lämna_tom_för_tillsvidare")
                                        },
                                        field: {
                                            clearable: true
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <Typography>{t("Bakåtdatering_Extra_Fordon_Förklaring")}</Typography>
                        </Grid>
                   </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" type="submit" disabled={checkDisabled()}>{t("Lägg till")}</Button>
                    <Button variant="outlined" color="error" onClick={()=>{clearFormData(); handleClose();}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara Extra Fordon")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default AddAdditionalVehicleModal;