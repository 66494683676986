import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import useTheme from '@mui/system/useTheme';
import {PricePeriod, rowToPricePeriod} from '../../types/PricePeriod';
import {useTranslation} from 'react-i18next';
import {DataGrid, GridColDef, GridValueGetterParams, useGridApiRef} from '@mui/x-data-grid';
import {updatePricePeriod} from '../../api/PricePeriodAPI';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import UpdatePricePeriodModal from "./Modals/UpdatePricePeriodModal";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import { formatSEK } from '../../helpers/PayableAmountFormatting';
import { boxStyle } from '../Shared/Tables/listStyles';

interface PricePeriodTableProps {
    pricePeriodItems: PricePeriod[];
    totalElements: number;
    page: number;
    onPageChange: (newPage: number) => void;
}

const PricePeriodTable = (({pricePeriodItems}: PricePeriodTableProps) => {
    const { t } = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const gridApiRef = useGridApiRef();

    const [selectedPricePeriod, setSelectedPricePeriod] = useState<PricePeriod>(rowToPricePeriod({
        id: 0,
        facilityName: {id: 0, name: ''},
        previousPrice: 0,
        currentPrice: 0,
        nextPrice: 0,
        previousFromMonth: null,
        currentFromMonth: null,
        nextFromMonth: null
    }));
    const [updateBulk, setUpdateBulk] = useState(false);
    const [updateSingle, setUpdateSingle] = useState(false);

    const formatFacilityColumnValue = (params:GridValueGetterParams<any>) => {
        let value = params.value;
        if (value == null){ 
            return '';
        }
        else { 
            return `${value.name}`;
        }
    }

    const formatPreviousPriceColumnHeader = () => {
        let filteredItems = pricePeriodItems.filter(pp => pp.previousFromMonth !== null);
        if(filteredItems.length < 1) {
            return '';
        }
        else {
            return t("Tidigare") + ' ' + t("avgift") + ' ' + t("från") + ' ' + filteredItems[0].previousFromMonth ;
        }
    }

    const formatCurrentPriceColumnHeader = () => {
        let filteredItems = pricePeriodItems.filter(pp => pp.currentFromMonth !== null);
        if(filteredItems.length < 1) {
            return '';
        }
        else {
            return t("Aktuell") + ' ' + t("avgift") + ' ' + t("från") + ' ' + filteredItems[0].currentFromMonth ;
        }
    }
    const getNextFromMonth = () => {
        let filteredItems = pricePeriodItems.filter(pp => pp.nextFromMonth !== null);
        if(filteredItems.length < 1) {
            return '';
        }
        else {
            return filteredItems[0].nextFromMonth;
        }
    }
    
    const hasNextPeriodSet = () => {
        return pricePeriodItems.filter(pp => pp.nextFromMonth !== null).length > 0;
    }

    const hasCurrentPeriodSet = () => {
        return pricePeriodItems.filter(pp => pp.currentFromMonth !== null).length > 0;
    }

    const hasPreviousPeriodSet = () => {
        return pricePeriodItems.filter(pp => pp.previousFromMonth !== null).length > 0;
    }

    const formatNextPriceColumnHeader = () => {
        let filteredItems = pricePeriodItems.filter(pp => pp.nextFromMonth !== null);
        if(filteredItems.length < 1) {
            return '';
        }
        else {
            return t("Kommande") + ' ' + t("avgift") + ' ' + t("från") + ' ' + filteredItems[0].nextFromMonth;
        }
    }

    const formatFeeColumnValue = (params: GridValueGetterParams<any>, periodSet:boolean) => {
        if(periodSet && params.value && params.value > 0){
            return formatSEK(params.value) + ' ' + t("exkl_moms");
        }
        else {
            return '';
        }
    }

    const columns: GridColDef[] = [
        {field: 'facilityName', headerName: t("Anläggning"), valueGetter: formatFacilityColumnValue, minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu: true},
        {field: 'previousPrice', headerName: formatPreviousPriceColumnHeader(), valueGetter: (params) => formatFeeColumnValue(params, hasPreviousPeriodSet()), minWidth: 50, flex: 1, disableColumnMenu: true},
        {field: 'currentPrice', headerName: formatCurrentPriceColumnHeader(), valueGetter: (params) => formatFeeColumnValue(params, hasCurrentPeriodSet()), minWidth: 50, flex: 1, disableColumnMenu: true},
        {field: 'nextPrice', headerName: formatNextPriceColumnHeader(), valueGetter: (params) => formatFeeColumnValue(params, hasNextPeriodSet()), minWidth: 50, flex: 1, disableColumnMenu: true},
    ];

    const generateRows = (pricePeriodItem: PricePeriod): any[] => {
        const rows: any[] = [{
            id: pricePeriodItem.id,
            previousPrice: pricePeriodItem.previousPrice,
            currentPrice: pricePeriodItem.currentPrice,
            nextPrice: pricePeriodItem.nextPrice,
            previousFromMonth: pricePeriodItem.previousFromMonth,
            currentFromMonth: pricePeriodItem.currentFromMonth,
            nextFromMonth: pricePeriodItem.nextFromMonth,
            facilityName: pricePeriodItem.facilityName
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };


    const handleConfirmClose = () => {
        toggleHidden();
        setUpdateBulk(false);
    };

    const handleUpdateBulk = () => {

    };

    const handleConfirmChanges = async(updatedItem: PricePeriod) => {
        const {data, errors} = await updatePricePeriod(selectedPricePeriod.id.toString(), updatedItem);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    const handleClose = () => {
        setUpdateSingle(false);
    };

    const [rows] = useState(() => pricePeriodItems.map(generateRows));
    const maxHeight = rows.length < 10 ? 'auto' : '65 vh';

    const handleCellClick = (params:any) => {
        if(hasNextPeriodSet() && params.row.nextPrice > 0){
            handleRowClick(params);
        }
    }

    const handleRowClick = (params: any) => {
        setUpdateSingle(true);
        setSelectedPricePeriod(rowToPricePeriod(params.row));
    };

    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{maxHeight : maxHeight, width: '100%'}}
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    initialState={{
                        sorting: {sortModel: [{field: 'facilityName', sort: 'asc' }]},
                        pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    pageSizeOptions={[10]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    hideFooter={false}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdatePricePeriodModal open={updateSingle} handleClose={handleClose} handleConfirmChanges={handleConfirmChanges} data={selectedPricePeriod} nextFromMonth={getNextFromMonth()}/>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={updateBulk}
                handleAgree={() => confirmationDialog.handleAgree(handleUpdateBulk)}
                handleClose={() => confirmationDialog.handleClose(handleConfirmClose)}
                title={t("Uppdatera_Kommande_Avgifter")}
                subtitle={t("Följande uppgifter kommer att ändras")}
                content={{}}
                errors={null}
                list={[]}
                active={false}
            />
        </Box>
);
});
export default PricePeriodTable;