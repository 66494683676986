import Box from "@mui/material/Box";
import { PricePeriod } from "../../types/PricePeriod";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useState } from "react";
import {
    useTheme,
    Typography,
} from "@mui/material";
import { updateNextPricePeriodByFacility, findPricePeriodByCustomerId } from "../../api/PricePeriodAPI";
import { useAsync } from "react-use";
import PricePeriodTable from "./PricePeriodTable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ModifyPricePeriodModal from "./Modals/ModifyPricePeriodModal";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { Payment } from "@mui/icons-material";

export default function PricePeriods() {
    const {currentCustomer} = useCurrentCustomer();
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [pricePeriodItems, setPricePeriodItems] = useState<PricePeriod[]>([]);

    const [adding, setAdding] = useState(false);
    const [loadData, setLoadData] = useState(false);

    const { t } = useTranslation();



    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const addPricePeriod = async (newItems) => {
        for(const facilityId of newItems.facilities) {
            let {data, errors} = await updateNextPricePeriodByFacility(facilityId, newItems.nextPrice, newItems.nextFromMonth);
            if(!errors || errors.length !== 0) {
                //TODO: Handle other response codes
            }
        }
        for(const pricePeriodItem of newItems.itemsWithNextPeriodSet.filter(item => !newItems.facilities.includes(item.facilityId))) {
            let {data, errors} = await updateNextPricePeriodByFacility(pricePeriodItem.facilityId, pricePeriodItem.nextPrice, newItems.nextFromMonth);
            if(!errors || errors.length !== 0) {
                //TODO: Handle other response codes
            }
        }
        setLoadData(true);
        handleClose();
};

    const handleOpen = () => {
        setAdding(true);
    };

    const handleClose = () => {
        setAdding(false);
    }

    const {
        loading,
        error,
        value: totalElements,
    } = useAsync(async () => {
        let result;
        if (currentCustomer){
            result = await findPricePeriodByCustomerId(currentCustomer.id.toString(), page);
        }
        const {data, errors, page :{totalElements, number}} = result;
        setPage(number);

        setPricePeriodItems([...data]);
        setLoadData(false);
        return totalElements;
    }, [page, loadData]);

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <Payment/>
                        <h2>{t("Avgifter")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Avgifter_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                    </Grid>
                </Box>
                <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                            <Button variant="outlined" color="inherit" onClick={handleOpen}>{t("Redigera_kommande_avgift")}</Button>
                            <ModifyPricePeriodModal open={adding} pricePeriodItems={pricePeriodItems} handleClose={handleClose} handleConfirmChanges={addPricePeriod}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{maxHeight: '70%'}}>
                {loading && <div>{t("Laddar")}</div>}
                {error && <div>{t("Något gick fel", { error })}</div>}
                {!loading && !error && (
                    <PricePeriodTable
                        pricePeriodItems={pricePeriodItems}
                        totalElements={totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                )}
            </Box>
        </Box>
    );
}