import {ExemptVehicle} from "../types/ExemptVehicle";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import { ExistsInfo } from "../types/ExistsInfo";
import { BillableInfo } from "../types/BillableInfo";
import { GridSortModel } from "@mui/x-data-grid";


export const fetchExemptVehicle = async (id: string): Promise<ApiResponseSingle<ExemptVehicle>> => {
    const apiUrl = `/api/exemptVechicles/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<ExemptVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExemptVehicle>>;
}
  
export const fetchExemptVehicles  = async (
  page: number, 
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<ExemptVehicle>> => {
    const params = [
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/exemptVehicles?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<ExemptVehicle[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<ExemptVehicle>(responseData, 'exemptVehicles')
    );
    return response as Promise<ApiResponse<ExemptVehicle>>;
}
  
export const findExemptVehicleByCustomerId = async (
    customerid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<ExemptVehicle>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/exemptVehicles/search/findByFacilityCustomerId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<ExemptVehicle[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<ExemptVehicle>(responseData, 'exemptVehicles')
    );
    return response as Promise<ApiResponse<ExemptVehicle>>;
}

export const findExemptVehicleByFacilityIdInAndRegnumberClearLike = async (
    facilityids: string[],
    searchQuery: string, 
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<ExemptVehicle>> => {
  const params = [
    `facilityids=${facilityids}`,
    `regnumber=${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl =`/api/exemptVehicles/search/findByFacilityIdInAndRegnumberClearLike?${params.join('&')}`;

   const options = {
       headers: getIdTokenHeader(),
       method: "GET"
   };
      
  const response = fetchData<ExemptVehicle[]> (
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<ExemptVehicle>(responseData, 'exemptVehicles')
  );
  return response as Promise<ApiResponse<ExemptVehicle>>;
}
  
export const findExemptVehicleByFacilityIdIn = async (
    facilityids: string[],
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
  ) : Promise<ApiResponse<ExemptVehicle>> => {
    const params = [
      `facilityids=${facilityids}`,
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/exemptVehicles/search/findByFacilityIdIn?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<ExemptVehicle[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<ExemptVehicle>(responseData, 'exemptVehicles')
    );
    return response as Promise<ApiResponse<ExemptVehicle>>;
  }
  
  export const updateExemptVehicle = async (id: string, vehicle: ExemptVehicle): Promise<ApiResponseSingle<ExemptVehicle>> => {
    const apiUrl =`/api/exemptVehicles/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: 'PATCH',
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<ExemptVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExemptVehicle>>;
  }
  
  export const createExemptVehicle = async(vehicle: ExemptVehicle): Promise<ApiResponseSingle<ExemptVehicle>> => {
    const apiUrl = `/api/exemptVehicles`;
    const options = {
      headers: getIdTokenHeader(),
      method: "POST",
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<ExemptVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExemptVehicle>>;
  }
  
  export const isVehicleBillable = async (regnumber: string): Promise<ApiResponseSingle<BillableInfo>> => {
    const apiUrl = `/api/exemptVehicles/search/isBillable?regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<BillableInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableInfo>>;
  }
  
  export const exemptVehicleExists = async (
    regnumber: string, 
    facilityids: number[],
    fromDate: string,
    toDate: string | null
  ) : Promise<ApiResponseSingle<ExistsInfo>> => {
    const params = [
      `regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`,
      `facilityids=${facilityids}`,
      `fromDate=${fromDate}`,
      `toDate=${toDate}`
    ];
    const apiUrl = `/api/exemptVehicles/search/existsByFacilityRegnumberValidityPeriod?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<ExistsInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExistsInfo>>;
  }
