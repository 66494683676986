export interface MarkedOrganization {
    id: string,
    title: string,
    text: string,
    orgnumber: string,
    parentOrgnumber: string,
    name: string,
    customerId: string,
    facilityNames: {id:number, name: string}[],
    comment: string,
    validFrom: any,
    validUntil: any,
    createdBy: string,
    updatedBy: string,
    created: any,
    updated: any,
    clientRef: string
}

export const rowToMarkedOrganization = (data:any) => {
    const {
        id,
        title,
        text,
        orgnumber,
        name,
        parentOrgnumber,
        facilityNames,
        validFrom,
        validUntil
    } = data;
    return {
        id,
        title,
        text,
        orgnumber,
        name,
        parentOrgnumber,
        facilityNames,
        validFrom,
        validUntil
    } as MarkedOrganization;
}
