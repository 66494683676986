import React, {ChangeEvent, useState} from "react";
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";
import {createCustomer} from "../../../api/CustomerAPI";
import {ErrorResponse} from "../../../api/apiUtils";
import {useNavigate} from "react-router-dom";

const initialFormData = {
    name: '',
    fullName: '',
    orgnumber: '',
};

const initialValidationErrors = {
    name: false,
    fullName: false,
    orgnumber: false,
};

const AddCustomerModal = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const confirmationDialog = useConfirmationDialog();
    const [open, setOpen] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [validationErrors, setValidationErrors] = useState(initialValidationErrors);

    const handleOpen = () => {
        setHidden(false);
        setOpen(true);
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setValidationErrors(initialValidationErrors);
        setErrors([]);
    };
    const handleClose = () => {
        resetForm();
        setOpen(false);
    };

    const handleHidden = () => {
        setHidden(!hidden)
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [e.target.id]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleHidden();
            setErrors([]);
            confirmationDialog.handleOpen();
        }
    };

    const handleConfirmChanges = async () => {
        const newCustomer = {...formData}
        await addCustomer(newCustomer);
    };

    const validateForm = () => {
        const nameValid = formData.name.trim() !== '';
        const fullNameValid = formData.fullName.trim() !== '';
        const orgnumberValid = /^\d{6}-\d{4}$/.test(formData.orgnumber);

        setValidationErrors({
            name: !nameValid,
            fullName: !fullNameValid,
            orgnumber: !orgnumberValid,
        });

        return nameValid && fullNameValid && orgnumberValid;
    };

    const addCustomer = async (newCustomer: any) => {
        const {data, errors} = await createCustomer(newCustomer);
        if (isEmptyArray(errors)) {
            handleClose();
            navigate(`/customers/${data.id}`);
        } else {
            setErrors(errors)
        }
    };

    return (
        <>
            <Button variant="outlined" color="inherit" onClick={handleOpen}>{t("Lägg till ny kund")}</Button>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <Grid item xs={12}>
                    <DialogTitle variant="h4">{t("Ny Kund")}</DialogTitle>
                </Grid>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="name"
                                name={t("Kort Namn")}
                                label={t("Kort Namn")}
                                size="small"
                                value={formData.name}
                                onChange={handleInputChange}
                                error={validationErrors.name}
                                helperText={validationErrors.name ? t('Namn krävs') : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="fullName"
                                name={t("Fullständigt Namn")}
                                label={t("Fullständigt Namn")}
                                size="small"
                                value={formData.fullName}
                                onChange={handleInputChange}
                                error={validationErrors.fullName}
                                helperText={validationErrors.fullName ? t('Fullständigt namn krävs') : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="orgnumber"
                                name={t("Organisationsnummer")}
                                label={t("Organisationsnummer")}
                                size="small"
                                value={formData.orgnumber}
                                onChange={handleInputChange}
                                error={validationErrors.orgnumber}
                                helperText={validationErrors.orgnumber ? t('Orgnr krävs') : ''}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" type="submit">{t("Lägg till")}</Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(handleHidden)}
                handleAgree={handleConfirmChanges}
                title={t("Spara kunduppgifter")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={errors}
                list={[]}
                active={false}
            />
        </>
    );
};
export default AddCustomerModal;