export interface PricePeriod {
	id: number,
	currentPrice: number,
	nextPrice: number,
	previousPrice: number,
	currentFromMonth: string,
	previousFromMonth: string,
	nextFromMonth: string,
	facilityId: number,
	facilityName: {id: number, name:string}
}

export const rowToPricePeriod = (data:any) => {
    const {
        id,
		currentPrice,
		nextPrice,
		previousPrice,
		currentFromMonth,
		previousFromMonth,
		nextFromMonth,
		facilityId,
        facilityName
    } = data;
    return {
    	id,
		currentPrice,
		nextPrice,
		previousPrice,
		currentFromMonth,
		previousFromMonth,
		nextFromMonth,
		facilityId,
		facilityName
    } as PricePeriod;
}
