import Box from "@mui/material/Box";
import { MarkedVehicle } from "../../types/MarkedVehicle";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useState } from "react";
import {
    useTheme,
    Typography,
} from "@mui/material";
import { createMarkedVehicle, findMarkedVehicleByCustomerId, findMarkedVehicleByFacilityIdIn, findMarkedVehicleByFacilityIdInAndRegnumberClearLike } from "../../api/MarkedVehicleAPI";
import { useAsync } from "react-use";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import MarkedVehicleTable from "./MarkedVehicleTable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddMarkedVehicleModal from "./Modals/AddMarkedVehicleModal";
import FacilityAutocomplete from "../Shared/FacilityAutocomplete";
import {Facility} from "../../types/Facility";
import SearchBar from "../Shared/SearchBar";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {TableSettings, TableStorage} from "../../types/settings/TableSettings";

export default function MarkedVehicles() {
    const {currentCustomer} = useCurrentCustomer();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'validFrom', sort: 'desc'}]);
    const [markedVehicleItems, setMarkedVehicleItems] = useState<MarkedVehicle[]>([]);

    const [adding, setAdding] = useState(false);
    const [loadData, setLoadData] = useState(false);

    const { t } = useTranslation();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);

    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setPaginationModel({...paginationModel, page: 0});
        setMarkedVehicleItems([]);
        setSearchQuery(search.toUpperCase());
    };

    const addMarkedVehicle = async (newMarkedVehicle) => {
        try {
            const {data, errors} = await createMarkedVehicle(newMarkedVehicle);
            if(!errors || errors.length === 0) {
                setLoadData(true);
                handleClose();
            }
            else {
                //TODO: Handle other response codes
            }
        } catch (error) {
            console.error('Error when creating markedVehicle', error);
        }
    };

    const handleOpen = () => {
        setAdding(true);
    };

    const handleClose = () => {
        setAdding(false);
    }

    const {
        loading,
        error,
        value: totalElements,
    } = useAsync(async () => {
        let result;
        if(searchQuery && facilitiesFilterValue && facilitiesFilterValue.length > 0) {
            result = await findMarkedVehicleByFacilityIdInAndRegnumberClearLike(
                facilitiesFilterValue.map((f) => f.id.toString()),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        } else if (facilitiesFilterValue && facilitiesFilterValue.length > 0){
            result = await findMarkedVehicleByFacilityIdIn(
                facilitiesFilterValue.map((f) => f.id.toString()), 
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        else if (searchQuery) {
            result = await findMarkedVehicleByFacilityIdInAndRegnumberClearLike(
                currentCustomer.facilities.map((f) => f.id.toString()),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        else if (currentCustomer){
            result = await findMarkedVehicleByCustomerId(
                currentCustomer.id.toString(), 
                paginationModel.page,
                paginationModel.pageSize,
                sortModel
            );
        }
        const {data, errors, page :{totalElements}} = result;

        setMarkedVehicleItems([...data]);
        setLoadData(false);
        return totalElements;
    }, [searchQuery, paginationModel, sortModel, facilitiesFilterValue, loadData]);

    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        setFacilitiesFilterValue(facilities)
    };

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <CarCrashIcon></CarCrashIcon>
                        <h2>{t("Markerade_Fordon")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Markerade_Fordon_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <SearchBar
                                label={t("Sök_Fordon")}
                                onChange={handleSearchQueryChange}
                                searchQuery={searchQuery}
                                size={"medium"}
                            />
                        </Grid>
                        <Grid item sx={{ marginLeft: { xs: 0, md: "auto"}, pt:1}}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggningar")}
                                helperText={t("Alla_Anläggningar")}
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                onFacilitiesFilterChange={handleFilterFacilitiesChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                            <Button variant="outlined" color="inherit" onClick={handleOpen}>{t("Lägg_till_nytt_markerat_fordon")}</Button>
                            <AddMarkedVehicleModal open={adding} handleClose={handleClose} handleConfirmChanges={addMarkedVehicle}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{maxHeight: '70%'}}>
                {error && <div>{t("Något gick fel", { error })}</div>}
                {!error && (
                    <MarkedVehicleTable
                        markedVehicleItems={markedVehicleItems}
                        loading={loading}
                        totalElements={totalElements}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => {TableStorage.saveLocalStorage(newModel.pageSize);setPaginationModel(newModel)}}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                    />
                )}
            </Box>
        </Box>
    );
}