import { createContext, useContext, useEffect, useState } from "react";
import {ServiceType} from "../types/enums/ServiceType";
import { UserGroup } from "../types/CurrentUserGroups";

const initCustomer: {
    currentCustomer: {
        id:number,
        name:string, 
        facilities:{id:number, name:string}[], 
        services:{type: ServiceType, active: boolean}[], 
        productNames: string[],
        customerGroups: UserGroup[]},
    setCurrentCustomer: (data:any) => void
} = {
    currentCustomer: {id: -1, name: '', facilities: [], services: [], productNames: [], customerGroups: []},
    setCurrentCustomer: (data) => {}
};

export const CurrentCustomerContext = createContext(initCustomer);

const getInitialState = () => {
    const currentCustomer = localStorage.getItem("currentCustomer");
    return currentCustomer ? JSON.parse(currentCustomer) : initCustomer;
}

const CurrentCustomerContextProvider = (props) => {
    const [currentCustomer, setCurrentCustomer] = useState(getInitialState);

    useEffect(() => {
        localStorage.setItem("currentCustomer", JSON.stringify(currentCustomer));
    },[currentCustomer]);

    return (
        <CurrentCustomerContext.Provider value={{currentCustomer, setCurrentCustomer}}>
            {props.children}
        </CurrentCustomerContext.Provider>
    );
};

export const useCurrentCustomer = () => useContext(CurrentCustomerContext);

export default CurrentCustomerContextProvider;