import React, {useState} from "react";
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import { Typography } from "@mui/material";
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";
import FacilityAutocomplete from "../../Shared/FacilityAutocomplete";
import { Facility } from "../../../types/Facility";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";

const ModifyPricePeriodModal = ({open, pricePeriodItems, handleClose, handleConfirmChanges}) => {
    const {currentCustomer} = useCurrentCustomer();
    const {t, i18n} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)

    const firstDayInNextMonth = dayjs().add(1, 'months').startOf("month");
    const [nextFromMonth, setNextFromMonth] = useState(firstDayInNextMonth);
    const [nextPrice, setNextPrice] = useState(0);
    const [infoData, setInfoData] = useState({
        [t("Anläggning")]: currentCustomer.facilities.map(f => f.name).toString(),
        [t("Från_månad")]: firstDayInNextMonth.format('YYYY-MM'),
        [t("Kommande_avgift")]: nextPrice.toString()
    });
    const [formData, setFormData] = useState({
        facilities: currentCustomer.facilities.map(f => `${f.id}`),
        nextFromMonth: firstDayInNextMonth.format('YYYY-MM'),
        nextPrice: nextPrice,
        itemsWithNextPeriodSet: pricePeriodItems.filter(pp => pp.nextFromMonth !== null)
    });

    const [errors, setErrors] = useState({
        facilities: {value: false, message: ''},
        nextFromMonth: {value: false, message: ''},
        nextPrice: {value: false, message: ''}
    });


    const validateForm = () => {
        const nextFromMonthValid = formData.nextFromMonth && dayjs(formData.nextFromMonth, "YYYY-MM", true).isValid();
        const nextPriceValid = formData.nextPrice && formData.nextPrice >= 0;
        const facilitiesValid = formData.facilities.length > 0;

        setErrors({
            facilities: {value: !facilitiesValid, message: facilitiesValid? '' : "Anläggning_krävs"},
            nextFromMonth: {value: !nextFromMonthValid, message: nextFromMonthValid ? '' : 'Ogiltigt_datum'},
            nextPrice: {value: !nextPriceValid, message: nextPriceValid ? '' : "Ogiltig_avgift"}
        });

        return nextFromMonthValid && nextPriceValid && facilitiesValid;
    };

    const handleNextFromMonthChange = (newValue) => {
        setNextFromMonth(newValue);
        setInfoData({...infoData, [t("Från_månad")]: newValue && newValue.isValid() ? newValue.format('YYYY-MM') : ''});
        setFormData({...formData, nextFromMonth: newValue ? (newValue.isValid() ? newValue.format('YYYY-MM') : 'invalid') : null});
    }

    const handleNextPriceChange = (e:any) => {
        if(e.target.id === 'nextPrice'){
            let newValue = (isNaN(e.target.value) || e.target.value < 0) ? 0: Math.floor(e.target.value);
            setNextPrice(newValue);
            setInfoData({...infoData, [t("Kommande_avgift")]: newValue.toString() ?? ''});
            setFormData({...formData, nextPrice: Math.floor(newValue)})
        }
    }

    const handleFacilitySelectChange = (newValues:Facility[]) => {
        if(newValues){
            setInfoData({...infoData, [t("Anläggning")]: newValues.map(v => v.name).toString()})
        }
        setFormData({...formData, facilities: newValues ? newValues.map(v => v.id) : []});
        setErrors({...errors,
            facilities: {value: formData.facilities.length < 1, message: formData.facilities.length < 1 ? '' : "Anläggning_krävs"}
        })
    }

    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();    
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        clearFormData();
        toggleHidden();
    };

    const clearFormData = () => {
        setFormData({
            facilities: currentCustomer.facilities.map(f => `${f.id}`),
            nextFromMonth: firstDayInNextMonth.format('YYYY-MM'),
            nextPrice: 0,
            itemsWithNextPeriodSet: pricePeriodItems.filter(pp => pp.nextFromMonth !== null)
        });

        setNextFromMonth(firstDayInNextMonth);
        setNextPrice(0);
        setInfoData({
            [t("Anläggning")]: currentCustomer.facilities.map(f => f.name).toString(),
            [t("Från_månad")]: firstDayInNextMonth.format('YYYY-MM'),
            [t("Kommande_avgift")]: nextPrice.toString()
        });

        setErrors({
            facilities: {value: false, message: ''},
            nextFromMonth: {value: false, message: ''},
            nextPrice: {value: false, message: ''}
        });
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                maxWidth='md'
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle sx={{xs:12}} variant="h5">{t("Redigera_Kommande_Avgift")}</DialogTitle>
                <DialogContent sx={{minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggning")}
                                helperText={errors.facilities.value ? t(errors.facilities.message): ''}
                                size="small"
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                onFacilitiesFilterChange={handleFacilitySelectChange}
                                valueInitialized={true}
                            />
                            {currentCustomer.facilities.length === 1 &&
                            <Typography variant="subtitle1">{currentCustomer.facilities[0].name}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="nextPrice"
                                name={t("Kommande_avgift")}
                                label={t("Kommande_avgift")}
                                size="small"
                                value={nextPrice}
                                onChange={handleNextPriceChange}
                                fullWidth
                                error={errors.nextPrice.value}
                                helperText={errors.nextPrice.value ? t(errors.nextPrice.message) : 'SEK ' + t("exkl_moms")}
                                autoComplete="off"
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <Grid item xs={6}>
                                <DatePicker
                                    label={t("Från_månad")}
                                    onChange={(handleNextFromMonthChange)}
                                    minDate={firstDayInNextMonth}
                                    value={nextFromMonth}
                                    format="YYYY-MM"
                                    views={['year', 'month']}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            helperText: errors.nextFromMonth.value ? t(errors.nextFromMonth.message) : ''
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <Typography>{t("Kommande_Avgift_Förklaring")}</Typography>
                        </Grid>
                   </Grid>
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button variant="outlined" color="inherit" type="submit">{t("Lägg till")}</Button>
                    <Button variant="outlined" color="error" onClick={()=>{clearFormData(); handleClose();}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Redigera_Kommande_Avgift")}
                subtitle={t("Följande uppgifter kommer att sparas")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default ModifyPricePeriodModal;