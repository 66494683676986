export function formatPayableAmount(payableAmount: number): string {
    return payableAmount !== 0 ? formatSEK(payableAmount): " "
}

export const formatNumberOfVisits = (amount: number):string => {
    if(amount) {
        return new Intl.NumberFormat('sv-SE').format(amount);
    }
    else {
        return '0';
    }
}

export const formatSEK = (amount:number):string => {
    if(amount) {
        return new Intl.NumberFormat('sv-SE', {style: 'currency', currencyDisplay: "code", maximumFractionDigits: 0, currency: "SEK"}).format(amount);
    }
    else {
        return '0';
    }
}

