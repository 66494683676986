import { Navigate } from "react-router-dom";
import PricePeriods from "../components/PricePeriods/PricePeriods";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { AuthenticatedPage } from "./AuthenticatedPage";

export function PricePeriodPage() {
    const {currentUser} = useCurrentUser();
    return (
        <AuthenticatedPage>
            {currentUser.isAdmin && 
                <PricePeriods />
            }
            {currentUser.isAuthorized && !currentUser.isAdmin &&
                <Navigate to='/'/>
            }
        </AuthenticatedPage>
      )
}