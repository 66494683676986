import {AdditionalVehicle} from "../types/AdditionalVehicle";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import { ExistsInfo } from "../types/ExistsInfo";
import { BillableInfo } from "../types/BillableInfo";
import { GridSortModel } from "@mui/x-data-grid";


export const fetchAdditionalVehicle = async (id: string): Promise<ApiResponseSingle<AdditionalVehicle>> => {
    const apiUrl = `/api/additionalVechicles/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<AdditionalVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<AdditionalVehicle>>;
}
  
export const fetchAdditionalVehicles  = async (
  page: number, 
  rowsPerPage: number = 100, 
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<AdditionalVehicle>> => {
  const params = [
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/additionalVehicles?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<AdditionalVehicle[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<AdditionalVehicle>(responseData, 'additionalVehicles')
    );
    return response as Promise<ApiResponse<AdditionalVehicle>>;
}
  
export const findAdditionalVehicleByCustomerId = async (
    customerid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<AdditionalVehicle>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/additionalVehicles/search/findByFacilityCustomerId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<AdditionalVehicle[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<AdditionalVehicle>(responseData, 'additionalVehicles')
    );
    return response as Promise<ApiResponse<AdditionalVehicle>>;
}

export const findAdditionalVehicleByFacilityIdInAndRegnumberClearLike = async (
    facilityids: string[],
    searchQuery: string, 
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<AdditionalVehicle>> => {
  const params = [
    `facilityids=${facilityids}`,
    `regnumber=${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl =`/api/additionalVehicles/search/findByFacilityIdInAndRegnumberClearLike?${params.join('&')}`;

   const options = {
       headers: getIdTokenHeader(),
       method: "GET"
   };
      
  const response = fetchData<AdditionalVehicle[]> (
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<AdditionalVehicle>(responseData, 'additionalVehicles')
  );
  return response as Promise<ApiResponse<AdditionalVehicle>>;
}
  
export const findAdditionalVehicleByFacilityIdIn = async (
    facilityids: string[],
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
  ) : Promise<ApiResponse<AdditionalVehicle>> => {
    const params = [
      `facilityids=${facilityids}`,
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/additionalVehicles/search/findByFacilityIdIn?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<AdditionalVehicle[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<AdditionalVehicle>(responseData, 'additionalVehicles')
    );
    return response as Promise<ApiResponse<AdditionalVehicle>>;
  }
  
  export const updateAdditionalVehicle = async (id: string, vehicle: AdditionalVehicle): Promise<ApiResponseSingle<AdditionalVehicle>> => {
    const apiUrl =`/api/additionalVehicles/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: 'PATCH',
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<AdditionalVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<AdditionalVehicle>>;
  }
  
  export const createAdditionalVehicle = async(vehicle: AdditionalVehicle): Promise<ApiResponseSingle<AdditionalVehicle>> => {
    const apiUrl = `/api/additionalVehicles`;
    const options = {
      headers: getIdTokenHeader(),
      method: "POST",
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<AdditionalVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<AdditionalVehicle>>;
  }
  
  export const isVehicleBillable = async (regnumber: string): Promise<ApiResponseSingle<BillableInfo>> => {
    const apiUrl = `/api/additionalVehicles/search/isBillable?regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<BillableInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableInfo>>;
  }
  
  export const additionalVehicleExists = async (
    regnumber: string, 
    facilityids: number[],
    fromDate: string,
    toDate: string | null
  ) : Promise<ApiResponseSingle<ExistsInfo>> => {
    const params = [
      `regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`,
      `facilityids=${facilityids}`,
      `fromDate=${fromDate}`,
      `toDate=${toDate}`
    ];
    const apiUrl = `/api/additionalVehicles/search/existsByFacilityRegnumberValidityPeriod?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<ExistsInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExistsInfo>>;
  }
  