import { GridValueGetterParams } from "@mui/x-data-grid/models/params/gridCellParams";
import dayjs from "dayjs";
import {ExemptionType} from "../types/enums/ExemptionType";

export const formatMultiFacilityColumn = (params:GridValueGetterParams) => {
    let value = params.value.sort((a, b) => a.name.localeCompare(b.name));
    if (value == null){ 
        return '';
    }
    else if (value.length > 1) {
        return `${value[0].name} + ${value.length -1}`;
    }
    else { 
        return `${value[0].name}`;
    }
}


export const formatUpdatedColumn = (params: GridValueGetterParams<any>) => {
    let value = params.value;
    if (value == null) {
        return '';
    } else {
        return dayjs(value + 'Z').format('YYYY-MM-DD HH:mm');
    }
}

export const formatExemptionIdColumn = (params: GridValueGetterParams<any>) => {
    let value = params.value;
    if (value == null || params.row.reason === ExemptionType.CANCELLED) {
        return '';
    } else return value;
}

