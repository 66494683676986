import {MarkedVehicle} from "../types/MarkedVehicle";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "../api/apiUtils";
import { ExistsInfo } from "../types/ExistsInfo";
import { BillableInfo } from "../types/BillableInfo";
import { GridSortModel } from "@mui/x-data-grid";


export const fetchMarkedVehicle = async (id: string): Promise<ApiResponseSingle<MarkedVehicle>> => {
    const apiUrl = `/api/markedVechicles/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<MarkedVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<MarkedVehicle>>;
}
  
export const fetchMarkedVehicles  = async (
  page: number, 
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<MarkedVehicle>> => {
    const params = [
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/markedVehicles?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<MarkedVehicle[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<MarkedVehicle>(responseData, 'markedVehicles')
    );
    return response as Promise<ApiResponse<MarkedVehicle>>;
}
  
export const findMarkedVehicleByCustomerId = async (
    customerid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<MarkedVehicle>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/markedVehicles/search/findByFacilityCustomerId?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };
    const response = fetchData<MarkedVehicle[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<MarkedVehicle>(responseData, 'markedVehicles')
    );
    return response as Promise<ApiResponse<MarkedVehicle>>;
}

export const findMarkedVehicleByFacilityIdInAndRegnumberClearLike = async (
    facilityids: string[],
    searchQuery: string, 
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
) : Promise<ApiResponse<MarkedVehicle>> => {
  const params = [
    `facilityids=${facilityids}`,
    `regnumber=${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl =`/api/markedVehicles/search/findByFacilityIdInAndRegnumberClearLike?${params.join('&')}`;

   const options = {
       headers: getIdTokenHeader(),
       method: "GET"
   };
      
  const response = fetchData<MarkedVehicle[]> (
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<MarkedVehicle>(responseData, 'markedVehicles')
  );
  return response as Promise<ApiResponse<MarkedVehicle>>;
}
  
export const findMarkedVehicleByFacilityIdIn = async (
    facilityids: string[],
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'validFrom', sort: 'desc'}]
  ) : Promise<ApiResponse<MarkedVehicle>> => {
    const params = [
      `facilityids=${facilityids}`,
      `page=${page}`,
      `size=${rowsPerPage}`,
      sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/markedVehicles/search/findByFacilityIdIn?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<MarkedVehicle[]> (
      apiUrl,
      options,
      (responseData) => extractEmbeddedResource<MarkedVehicle>(responseData, 'markedVehicles')
    );
    return response as Promise<ApiResponse<MarkedVehicle>>;
  }
  
  export const updateMarkedVehicle = async (id: string, vehicle: MarkedVehicle): Promise<ApiResponseSingle<MarkedVehicle>> => {
    const apiUrl =`/api/markedVehicles/${id}`;
    const options = {
      headers: getIdTokenHeader(),
      method: 'PATCH',
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<MarkedVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<MarkedVehicle>>;
  }
  
  export const createMarkedVehicle = async(vehicle: MarkedVehicle): Promise<ApiResponseSingle<MarkedVehicle>> => {
    const apiUrl = `/api/markedVehicles`;
    const options = {
      headers: getIdTokenHeader(),
      method: "POST",
      body: JSON.stringify(vehicle)
    };
    const response = fetchData<MarkedVehicle>(apiUrl, options);
    return response as Promise<ApiResponseSingle<MarkedVehicle>>;
  }
  
  export const isVehicleBillable = async (regnumber: string): Promise<ApiResponseSingle<BillableInfo>> => {
    const apiUrl = `/api/markedVehicles/search/isBillable?regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<BillableInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableInfo>>;
  }
  
  export const markedVehicleExists = async (
    regnumber: string, 
    facilityids: number[],
    fromDate: string,
    toDate: string | null
  ) : Promise<ApiResponseSingle<ExistsInfo>> => {
    const params = [
      `regnumber=${regnumber.replaceAll(" ", "").toUpperCase()}`,
      `facilityids=${facilityids}`,
      `fromDate=${fromDate}`,
      `toDate=${toDate}`
    ];
    const apiUrl = `/api/markedVehicles/search/existsByFacilityRegnumberValidityPeriod?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
    const response = fetchData<ExistsInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<ExistsInfo>>;
  }
  