import Box from "@mui/material/Box";
import React from "react";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export function CloseComponent({title,subtitle,hidden, close}) {
    const { t } = useTranslation();

    return (
        <>
            {!hidden &&
                <Box>
                    <h4>
                        {t(title)}
                        <Button color="inherit"  variant="outlined" onClick={() => close()} style={{float: 'right'}}>{t("Stäng")}</Button>
                    </h4>
                    <Typography variant="caption">
                        {t(subtitle)}
                    </Typography>
                </Box>
            }
        </>

    )
}