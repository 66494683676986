import { GridSortModel } from "@mui/x-data-grid";
import {ExemptOrganization} from "../types/ExemptOrganization";
import { OwnerInfo } from "../types/OwnerInfo";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";

export const updateExemptOrganization = async(id: string, organization: ExemptOrganization) : Promise<ApiResponseSingle<ExemptOrganization>> => {
  const apiUrl = `/api/exemptOrganizations/${id}`;
  const options = {
    headers: getIdTokenHeader(),
    method: 'PATCH',
    body: JSON.stringify(organization)
  };
  const response =  fetchData<ExemptOrganization>(apiUrl, options);
  return response as Promise<ApiResponseSingle<ExemptOrganization>>;
}

export const createExemptOrganization = async(organization: ExemptOrganization) : Promise<ApiResponseSingle<ExemptOrganization>> => {
  const apiUrl = `/api/exemptOrganizations`;
  const options = {
    headers: getIdTokenHeader(),
    method: "POST",
    body: JSON.stringify(organization)
  };
  const response =  fetchData<ExemptOrganization>(apiUrl, options);
  return response as Promise<ApiResponseSingle<ExemptOrganization>>;
}
  
export const findExemptOrganizationByCustomerId = async(
  customerid: string,
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: "validFrom", sort: 'desc'}]
) : Promise<ApiResponse<ExemptOrganization>> => {
  const params = [
    `customerid=${customerid}`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/exemptOrganizations/search/findByFacilityCustomerId?${params.join('&')}`;
  const options = {
    headers: getIdTokenHeader(),
    method: "GET"
  };
  const response = fetchData<ExemptOrganization>(
    apiUrl, 
    options,
    (responseData) => extractEmbeddedResource<ExemptOrganization>(responseData, 'exemptOrganizations')
);
  return response as Promise<ApiResponse<ExemptOrganization>>;
}
  
  
export const findExemptOrganizationByFacilityIdInAndOrgnumberLikeOrNameLike = async(
  facilityids: string[],
  searchQuery: string,
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: "validFrom", sort: 'desc'}]
) : Promise<ApiResponse<ExemptOrganization>> => {
  const params = [
    `facilityids=${facilityids}`,
    `orgnumber=${searchQuery}%25`,
    `name=%25${searchQuery}%25`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/exemptOrganizations/search/findByFacilityIdInAndOrgnumberLikeOrNameLike?${params.join('&')}`;
  const options = {
    headers: getIdTokenHeader(),
    method: "GET"
  };
      
  const response = fetchData<ExemptOrganization>(
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<ExemptOrganization>(responseData, 'exemptOrganizations')
  );
  return response as Promise<ApiResponse<ExemptOrganization>>;
}

export const findExemptOrganizationByFacilityIdIn = async(
  facilityids: string[],
  page: number,
  rowsPerPage: number = 100,
  sortModel: GridSortModel = [{field: "validFrom", sort: 'desc'}]
) : Promise<ApiResponse<ExemptOrganization>> => {
  const params = [
    `facilityids=${facilityids}`,
    `page=${page}`,
    `size=${rowsPerPage}`,
    sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
  ];
  const apiUrl = `/api/exemptOrganizations/search/findByFacilityIdIn?${params.join('&')}`;
  const options = {
    headers: getIdTokenHeader(),
    method: "GET"
  };
  
  const response = fetchData(
    apiUrl,
    options,
    (responseData) => extractEmbeddedResource<ExemptOrganization>(responseData, 'exemptOrganizations')
  );
  return response as Promise<ApiResponse<ExemptOrganization>>;
}

export const exemptOrganizationExists = async(
  orgnumber:string, 
  facilityids: number[],
  fromDate: string,
  toDate: string | null
) : Promise<ApiResponseSingle<Boolean>> => {
    const params = [
      `orgnumber=${orgnumber.replaceAll(" ", "")}`,
      `facilityids=${facilityids}`,
      `fromDate=${fromDate}`,
      `toDate=${toDate}`
    ];
    const apiUrl = `/api/exemptOrganizations/search/existsByFacilityOrgnumberValidityPeriod?${params.join('&')}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };
  
    const response = fetchData<Boolean>(apiUrl, options);
    return response as Promise<ApiResponseSingle<boolean>>;
  }

  export const getOwnerInfo = async(orgnumber: string) : Promise<ApiResponseSingle<OwnerInfo>> => {
    const apiUrl = `/api/exemptOrganizations/search/getOwnerInfo?orgnumber=${orgnumber}`;
    const options = {
      headers: getIdTokenHeader(),
      method: "GET"
    };

    const response = fetchData<OwnerInfo>(apiUrl, options);
    return response as Promise<ApiResponseSingle<OwnerInfo>>;
  }
