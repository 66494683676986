import {SettingsData} from "../MobileNotifications";
import {PostMessageHelper} from "../../helpers/PostMessageHelper";

export enum TableSettings {
    PAGE_SIZE = 50,
}

export class TableStorage {
    static saveLocalStorage(changeData:number){
        let serializedData = JSON.stringify(changeData);
        window.localStorage.setItem("TABLE_SIZE", serializedData);
        const settingsData = new SettingsData("", changeData)
        PostMessageHelper(JSON.stringify(settingsData));
    }

    static loadLocalStorage(){
        let data = window.localStorage.getItem("TABLE_SIZE");
        if(data){
            return Number(data);
        }else{
            return TableSettings.PAGE_SIZE;
        }
    }
}